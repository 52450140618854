<style *ngIf="configs.title != 'Kubendran'">
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        overflow-x: hidden;
        transition: 0.1s;
        padding-top: 60px;
    }

    .sidenav a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;
    }

    .sidenav a:hover {
        color: #f1f1f1;
    }

    .sidenav .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
    }

    /* .container-fluid-header-sticky-header
     {
         background-color:#2a7d2e;  
     } */
    /* @media (max-width:1100px)  {
         .container-fluid-header-sticky-header
     {
         background-color:#FBCB0C;  
     }
     } */
    @media screen and (min-width:0px) {
        p {
            font-size: 4vw;
        }

        .container-fluid-header-sticky-header {
            display: none;

        }

        .container-fluid-header-sticky-header1 {
            display: block;
        }

    }

    @media screen and (min-width:1100px) {
        p {
            font-size: 1vw;
        }

        .container-fluid-header-sticky-header {
            display: block;
            background-color: white;

        }

        .container-fluid-header-sticky-header1 {
            display: none;
        }
    }
</style>
<style *ngIf="configs.title === 'Kubendran'">
    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #2a7d2e;
        overflow-x: hidden;
        transition: 0.1s;
        padding-top: 60px;
    }

    .sidenav a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;
    }

    .sidenav a:hover {
        color: #f1f1f1;
    }

    .sidenav .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
    }

    /* .container-fluid-header-sticky-header
         {
             background-color:#2a7d2e;  
         } */
    /* @media (max-width:1100px)  {
             .container-fluid-header-sticky-header
         {
             background-color:#FBCB0C;  
         }
         } */
    @media screen and (min-width:0px) {
        p {
            font-size: 4vw;
        }

        .container-fluid-header-sticky-header {
            display: none;
        }

        .container-fluid-header-sticky-header1 {
            display: block;
        }

    }

    @media screen and (min-width:1100px) {
        p {
            font-size: 1vw;
        }

        .container-fluid-header-sticky-header {
            display: block;
            background-color: #2a7d2e;


        }

        .container-fluid-header-sticky-header1 {
            display: none;
        }
    }

    @media (max-width:375px) {
        p {
            font-size: 4.5vw;
        }

        .container-fluid-header-sticky-header {
            display: none;
            background-color: #2a7d2e;


        }

        .container-fluid-header-sticky-header1 {
            display: flex;
        }
    }
</style>
<!-- <h1 style="display: none;" *ngIf="configs.title === 'Schift Cosmetics'">Best Skin care products for men & women | Schift</h1> -->
<h1 style="display: none;" *ngIf="configs.title === 'Cle'">Top leather exporters in India | Council of leather exports
</h1>
<h1 style="display: none;" *ngIf="configs.title === 'Babys Pride'">Kids Wear - Buy Kids Clothes & Dresses for Girls,
    Boys | Babyspride</h1>
<h1 style="display: none;" *ngIf="configs.title === 'BombayHardware'">Leading Steel Pipes/Tubes Manufacturers &
    Suppliers | Bombay Hardware</h1>
<h1 style="display: none;" *ngIf="configs.title === 'LushNGreen'">Leading Steel Pipes/Tubes Manufacturers & Suppliers |
    LushNGreen</h1>
<h1 style="display: none;" *ngIf="configs.title === 'Taazah'">Departmental Store | Taazah</h1>
<h1 style="display: none;" *ngIf="configs.title === 'SriSenbaga'">Leading Book Publishers | Sri Senbaga Pathipagam</h1>
<h1 style="display: none;" *ngIf="configs.title === 'HOB'">Leading Clothing Company | House Of Talent</h1>
<h1 style="display: none;" *ngIf="configs.title === 'PaulCaroline'">Leading Clothing Company | Paul Caroline</h1>
<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'Kubendran'">
    <header>
        <div class="row">
            <div class="col-1">
                <img [src]="logoImage" alt="Logo Image" style="padding:18px" class="logo-image custom-pointer"
                    height="80" width="80" alt="Logo" [routerLink]="['/']">
            </div>
            <div class="col-4" style="margin-left:25vw">
                <div class="inq-menu-st row justify-content-center">
                    <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation" class="navbar-toggler"><span
                                class="navbar-toggler-icon"></span><span>Menu</span></button>
                        <div id="navbarNav" class="collapse navbar-collapse">
                            <ul class="navbar-nav" style="padding:20px;">
                                <li class="nav-item" id="home" style="margin-left: 0.1rem !important"><a
                                        class="nav-link menu-text" ng-reflect-router-link="/" href="#/">
                                        <p>HOME</p>
                                    </a></li>
                                <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                    <a href="javascript:;" class="nav-link menu-text"
                                        (click)="productsListkubendran('FRUITS')">
                                        <p>FRUITS</p>
                                    </a>
                                    <app-new-navbarkub [category]="fruits"></app-new-navbarkub>
                                </li>
                                <li class="header-cart account-popup1" id="shop" style="margin-left: 2rem !important">
                                    <a href="javascript:;" class="nav-link menu-text"
                                        (click)="productsListkubendran('VEGETABLES')">
                                        <p>VEGETABLES</p>
                                    </a>
                                    <app-new-navbarkub [category]="vegetables"></app-new-navbarkub>
                                </li>
                                <!-- <li class="header-cart account-popup1" id="shop">
                                            <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                             <app-new-navbar></app-new-navbar>
                                            </li> -->
                                <!-- <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                <a href="javascript:;" class="nav-link menu-text" (click)="productsListkubendran('OTHERS')"><p>OTHER DEPARTMENTS</p></a>
                                                 <app-new-navbarkub [category]="others"></app-new-navbarkub>
                                                </li> -->
                                <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                    <a href="#/about-us" class="nav-link menu-text">
                                        <p>PAGES</p>
                                    </a>
                                    <app-new-navbarkub [category]="pages"></app-new-navbarkub>
                                </li>


                                <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                href="#/contact-us">CONTACT US</a></li> -->

                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="col-3" style="margin-top: 2%">

                <ul class="d-flex">
                    <li class="d-flex header-cart account-popup1" style="margin-right: 18%;
                                margin-left: 20%;">
                        <a href="javascript:;" style="text-decoration: none !important;color: white !important;"
                            (click)="search()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </a>
                        <div class="min-cart-product-wrapper popup-content2"
                            style="height:auto;overflow-y: visible !important;">
                            <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="dropdown-menu autocomplete-items"
                                                style="width: 100%! important;">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"
                                                        style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="text" placeholder="Search here."
                                            class="form-control search-form-input" formControlName="search_term"
                                            (keyup)="getPreList()">
                                        <div *ngIf="preList.length != 0">
                                            <div class="autocomplete-items2">
                                                <div *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
            </div>
        </div>
</div>
<div class="input-group-btn">
    <button class="btn btn-solid">
        <i class="fa fa-search" (click)="savePreList1()"></i>
    </button>
</div>
</div>
</form>

</div>


</div>
</li>

<!-- <li>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search">
                                    <div class="input-group-append">
                                      <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                        <i class="fa fa-search"></i>
                                      </button>
                                    </div>
                                  </div>
                                </li> -->

<li class="d-flex" style="margin-right: 12%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
    <a href="javascript:;" style="text-decoration: none !important;color: white !important;">
        <span class="fa fa-user" style="font-size:25px">
        </span> Login
    </a>
</li>

<li class="d-flex" style="margin-right: 3%;" *ngIf="appService.userLoggedIn">
    <div class="dropdown">
        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style="text-decoration: none !important;color: white !important;"><span class="lnr lnr-user">
                {{ appService.userName.substring(0,6) }}...
            </span>
            <!-- /style="white-space: break-spaces;"                              -->
        </a>
        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;"
            aria-labelledby="dropdownMenuButton">
            <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #2a7d2e !important;">My
                Account</a>
            <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #2a7d2e !important;">My Order</a>

            <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #2a7d2e !important;">Logout</a>
        </div>
    </div>
</li>

<li class="header-cart account-popup d-flex" style="position: relative;">
    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: white !important;">
        <span class="fa fa-shopping-basket" style="font-size:25px">
            <span style="position: absolute;
                                            background: #FBCB0C;
                                            width: 18px;
                                            height: 18px;
                                            border-radius: 9px;
                                            top: -10px;
                                            right: 9%;">

                <span style="  color: #fff;
                                                position: absolute;
                                                left: 0.2rem;
                                                font-size: 14px;
                                                font-weight: bold;">{{appService.totalCartItems}}</span>
            </span>
        </span>
        <!-- <span class="menu-title">
                                            Cart
                                        </span> -->
    </a>
    <app-mini-cart></app-mini-cart>
</li>
</ul>

</div>
</div>



</header>
</div>
<!-- </div> -->
<div class="container-fluid-header-sticky-header1" style="background-color:#2a7d2e;height: 12vw;
            padding: 2vw;" *ngIf="configs.title === 'Kubendran'">
    <header>
        <div>
            <ul style="display:flex;gap: 10px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:white"
                        (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <img [src]="logoImage" alt="Logo Image" style="width: 20vw;
                height: 10vw;
                padding: 1vw;margin-left: 2vw;margin-right:25vw;margin-top: 1px;" class="logo-imagekub custom-pointer"
                        alt="Logo" [routerLink]="['/']">
                </li>
                <li class="d-flex header-cart account-popup1">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: white !important;    font-size: 5vw;
                                        margin-left: 1vw;
                                        margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2"
                        style="height:auto;overflow-y: visible !important;">
                        <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>

                        </div>


                    </div>
                </li>
                <li class="d-flex" style="    margin-left: 2vw;
                                margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn"
                    (click)="logIn()">
                    <a href="javascript:;" style="text-decoration: none !important;color: white !important;">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: white !important;font-size:3vw;margin-top:1vw"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4) }}...
                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                        </a>
                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;"
                            aria-labelledby="dropdownMenuButton">
                            <a class="logout-cutom dropdown-item" routerLink="my-account"
                                style="color: #2a7d2e !important;">My Account</a>
                            <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                style="color: #2a7d2e !important;">My Order</a>

                            <a class="logout-cutom dropdown-item" (click)="logOut()"
                                style="color: #2a7d2e !important;">Logout</a>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: white !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                        margin-top: 2vw;
                                        margin-left: 1vw;">
                            <span style="position: absolute;
                                            background: #FBCB0C;
                                            width: 3vw;
                                            height: 2.5vw;
                                            border-radius: 1vw;">

                                <span style="color: #fff;
                                                position: absolute;
                                                left: 0.3rem;
                                                font-size: 2vw;
                                                font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                        <!-- <span class="menu-title">
                                            Cart
                                        </span> -->
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closeNavkub11()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myfruits1','FRUITS')">
                <p>FRUITS &#8594;</p>
            </a>

            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p>VEGETABLES &#8594;</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myothers1','OTHERS')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;FRUITS </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;VEGETABLES </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <!-- <div id="myothers1" class="sidenav">
                        <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;OTHER DEPARTMENTS </span>
                        
                        <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                    
                      </div> -->
        <div id="searchnav" class="sidenav">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:white;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>

            <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                            <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                    <div *ngFor="let category of allCategories">
                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                    </div>                                      
                                                </div>
                                              </div>
                                            <input type="text" placeholder="Search here."
                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                            <div *ngIf="preList.length != 0">
                                                <div class="autocomplete-items2">
                                                    <div *ngFor="let preItem of preList">
                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-btn">
                                                <button class="btn btn-solid" >
                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                </button>
                                            </div>
                                        </div>
                                </form>
                                
                            </div>
                            
                    
                        </div> -->

        </div>
        <div id="mySidenav" class="sidenav">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closeNavkub1()"><i class="fa fa-arrow-circle-left"
                    style='font-size:35px;position: relative;bottom: 40px;left: 7px;'></i></a>
            <a class="nav-link menu-text" ng-reflect-router-link="/" href="#/" (click)="closeNavkub1()">
                <p>HOME</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')">
                <p>FRUITS <span style='font-size:16px;'>&#8594;</span></p>
            </a>

            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myvegetables')">
                <p>VEGETABLES <span style='font-size:16px;'>&#8594;</span></p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myothers')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->


        </div>
        <div id="myfruits" class="sidenav">
            <span style="font-size: 6vw;cursor:pointer;color:white;position: relative;left: 1px;"
                (click)="openNavkub()">FRUITS<i class="fa fa-arrow-circle-left"
                    style='font-size:35px; position: absolute;left: 150px;top:1px;'></i> </span>



            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub()">VEGETABLES<i
                    class="fa fa-arrow-circle-left"
                    style='font-size:35px; position: absolute;left: 150px;top: 63px;'></i></span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <!-- <div id="myothers" class="sidenav">
                        <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub()">&#8592;OTHER DEPARTMENTS </span>
                        
                        <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                    
                      </div> -->



    </header>
</div>

<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'AvonSeals'"
    style="background-color: white !important;">
    <!-- <header>
                    <div class="row" >
                        <div class="col-1" style="margin-left: 450px;transform: translate(80px, 0px);">
                           
                            <img src="../../assets/images/avonseals/Avon Seals.png" alt="Logo Image" style="padding:8px"class="logo-image custom-pointer" height="130" width="130" alt="Logo"
                            [routerLink]="['/']"> 
                        </div>
                        
                        <div class="col-3"  style="margin-top: 2%; margin-left: 250px; transform: translate(160px, 10px);">
                            
                                <ul class="d-flex">
                                    <li class="d-flex header-cart account-popup1" style="margin-right: 18%;
                                    margin-left: 20%;">
                                        <a href="javascript:;"  style="text-decoration: none !important;color: black !important;" (click)="search()">
                                            <i class="fa fa-search" aria-hidden="true"></i>
                                        </a>
                                        <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                            <div class="row" style="border-bottom : 2px dashed black;margin: 5px;">
                                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                    <div *ngFor="let category of allCategories">
                                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                    </div>                                      
                                                                </div>
                                                              </div>
                                                            <input type="text" placeholder="Search here."
                                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                            <div *ngIf="preList.length != 0">
                                                                <div class="autocomplete-items2">
                                                                    <div *ngFor="let preItem of preList">
                                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="input-group-btn">
                                                                <button class="btn btn-solid" >
                                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                </form>
                                                
                                            </div>
                                            
                                    
                                        </div>
                                    </li>
                                    
                                
                                    
                                    <li class="d-flex" style="margin-right: 12%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                        <a href="javascript:;"  style="text-decoration: none !important;color: black !important;">
                                            <span class="fa fa-user" style="font-size:25px">
                                            </span> Login
                                        </a>
                                    </li>
            
                                    <li class="d-flex" style="margin-right: 3%;" *ngIf="appService.userLoggedIn">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: white !important;"><span
                                                    class="lnr lnr-user">
                                                    {{ appService.userName.substring(0,18) }}...
                                                </span>
             
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" style="background-color: black!important;" aria-labelledby="dropdownMenuButton">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: black !important;">My Account</a>
                                                <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: black!important;">My Order</a>
                                                
                                                <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: black !important;">Logout</a>
                                            </div>
                                        </div>
                                    </li>
            
                                    <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
                                            <span class="fa fa-shopping-basket" style="font-size:25px">
                                                <span style="position: absolute;
                                                background: #FBCB0C;
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;" >
                                               
                                                    <span style="  color: #fff;
                                                    position: absolute;
                                                    left: 0.2rem;
                                                    font-size: 14px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                                </span>
                                            </span>  
                                          
                                        </a>
                                        <app-mini-cart></app-mini-cart>
                                    </li>
                                </ul>
                        
                        </div>
                    </div>
                    
                        
            
                </header> -->
    <header>
        <div class="row" style="display: inline-flex; justify-content: normal; width: 100%;">
            <div class="col-1" *ngIf="configs.title === 'AvonSeals'" style="margin-left: 44%;margin-right: 23%;">

                <img src="../../assets/images/avonseals/Avon Seals.png" alt="Logo Image"
                    style="padding:8px; object-fit: contain;" class="logo-image custom-pointer" height="140" width="185"
                    alt="Logo" [routerLink]="['/']">
            </div>


            <div class="col-3" style="margin-top: 5%;margin-right:inherit;">

                <ul class="d-flex">
                    <li class="d-flex header-cart account-popup1" style="margin-right: 20%;
                                    margin-left: 20%;">
                        <a href="javascript:;" style="text-decoration: none !important;color: black !important;"
                            (click)="search()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </a>
                        <div class="min-cart-product-wrapper popup-content2"
                            style="height:auto;overflow-y: visible !important;margin-top: -30px;">
                            <div class="row" style="border-bottom : 2px;margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="dropdown-menu autocomplete-items"
                                                style="width: 100%! important;">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"
                                                        style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="text" placeholder="Search here."
                                            class="form-control search-form-input" formControlName="search_term"
                                            (keyup)="getPreList()">
                                        <div *ngIf="preList.length != 0">
                                            <div class="autocomplete-items2">
                                                <div *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
            </div>
        </div>
</div>
<div class="input-group-btn">
    <button class="btn btn-solid">
        <i class="fa fa-search" (click)="savePreList1()"></i>
    </button>
</div>
</div>
</form>

</div>


</div>
</li>

<!-- <li>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search">
                                        <div class="input-group-append">
                                          <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                            <i class="fa fa-search"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </li> -->

<li class="d-flex" style="margin-right: 25%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
    <a href="javascript:;"
        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">
        <span class="fa fa-user" style="font-size:25px">
        </span> Login
    </a>
</li>

<li class="d-flex" style="margin-right: 3%;" *ngIf="appService.userLoggedIn">
    <div class="dropdown">
        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style="text-decoration: none !important;color: #1d1b1a !important;"><span class="lnr lnr-user">
                {{ appService.userName.substring(0,6) }}
            </span>
            <!-- /style="white-space: break-spaces;"                              -->
        </a>
        <div id="dropdown" class="dropdown-menu" style="background-color: white!important;"
            aria-labelledby="dropdownMenuButton">
            <a class="logout-cutom dropdown-item" routerLink="my-account"
                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My
                Account</a>
            <a class="logout-cutom dropdown-item" routerLink="my-orders"
                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My
                Order</a>

            <a class="logout-cutom dropdown-item" (click)="logOut()"
                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">Logout</a>
        </div>
    </div>
</li>

<li class="header-cart account-popup d-flex" style="position: relative;">
    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
        <span class="fa fa-shopping-basket" style="font-size:25px">
            <span style="position: absolute;
                                                background: #FBCB0C;
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;">

                <span style="  color: #fff;
                                                    position: absolute;
                                                    left: 0.2rem;
                                                    font-size: 14px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
            </span>
        </span>
        <!-- <span class="menu-title">
                                                Cart
                                            </span> -->
    </a>
    <app-mini-cart></app-mini-cart>
</li>
</ul>

</div>
</div>



</header>
<hr style="width:700px ;border: 1px solid rgb(27, 26, 26); margin-left: auto; margin-right: auto; margin-top: -8px;">
<header>
    <div class="row2" style="height: 71px;">
        <div class="col-4" style="margin-left: 33vw; height: 50px; margin-top: -15px; margin-bottom: 15px;">
            <div class="inq-menu-st row justify-content-center">
                <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                        data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation" class="navbar-toggler"><span
                            class="navbar-toggler-icon"></span><span>Menu</span></button>
                    <div id="navbarNav" class="collapse navbar-collapse">
                        <!-- <ul class="navbar-nav" style="padding:20px;">
                                            <li class="nav-item" id="home" style="margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                    href="#/"><p style="color:black">HOME</p></a></li>
                                                    <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                        <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p style="color:black">PRODUCTS</p></a>
                                                      
                                                        </li>
                                                        <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                            <a href="javascript:;" class="nav-link menu-text" ><p style="color:black">ABOUTUS</p></a>
                                                           
                                                            </li>
                                           
                                                    <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                        <a href="#/about-us" class="nav-link menu-text"><p style="color:black">CONTACTUS</p></a>
                                                       
                                                        </li>
                                
                                                    
                                         
                                                    
                                        </ul> -->
                        <ul class="navbar-nav" style="padding:20px;">
                            <li class="nav-item" id="home"
                                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                <a class="nav-link menu-text" ng-reflect-router-link="/" href="#/">
                                    <p style="font-weight: bold;color:black">HOME</p>
                                </a>
                            </li>
                            <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">
                                    <p
                                        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important; font-weight: bold;color:black">
                                        PRODUCTS</p>
                                </a>
                                <!-- <app-new-navbarkub [category]="fruits"></app-new-navbarkub> -->
                            </li>
                            <li class="header-cart account-popup1" id="shop"
                                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                <a href="javascript:;" class="nav-link menu-text" href="#/about-us">
                                    <p style="font-weight: bold;color:black;width: 150%;">ABOUT US</p>
                                </a>
                                <!-- <app-new-navbarkub [category]="vegetables"></app-new-navbarkub> -->
                            </li>
                            <!-- <li class="header-cart account-popup1" id="shop">
                                                <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                                 <app-new-navbar></app-new-navbar>
                                                </li> -->
                            <!-- <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsListkubendran('OTHERS')"><p>OTHER DEPARTMENTS</p></a>
                                                     <app-new-navbarkub [category]="others"></app-new-navbarkub>
                                                    </li> -->
                            <li class="header-cart account-popup1" id="shop"
                                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                <a href="#/contact-us" class="nav-link menu-text">
                                    <p style="font-weight: bold;color:black;width: 150%;">CONTACT US</p>
                                </a>
                                <!-- <app-new-navbarkub [category]="pages"></app-new-navbarkub> -->
                            </li>


                            <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                    href="#/contact-us">CONTACT US</a></li> -->

                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>
</div>

<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;
                padding: 2vw;" *ngIf="configs.title === 'AvonSeals'">
    <header>
        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black"
                        (click)="openNavkub()">&#9776;</span>
                </li>
                <li *ngIf="configs.title === 'AvonSeals'">
                    <!-- <img [src]="logoImage" alt="Logo Image" style="width: 20vw;
                    height: 10vw;
                    padding: 1vw;margin-left: 2vw;margin-right:25vw;margin-top: 1px;"class="logo-imagekub custom-pointer" alt="Logo"
                            [routerLink]="['/']">  -->
                    <img src="../../assets/images/avonseals/Avon Seals.png" alt="Logo Image" style="width: 20vw;
                            height: 15vw;
                            ;margin-left: 25vw;margin-right:15vw;" class="logo-imagekub custom-pointer" alt="Logo"
                        [routerLink]="['/']">
                </li>

                <li class="d-flex header-cart account-popup1">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                            margin-left: 1vw;
                                            margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                            <div class="row" style="border-bottom : 2px;margin: 5px;">
                                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                    <div *ngFor="let category of allCategories">
                                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                    </div>                                      
                                                                </div>
                                                              </div>
                                                           
                                                        </div>
                                                </form>
                                                
                                            </div>
                                            
                                    
                                        </div> -->
                </li>
                <li class="d-flex" style="    margin-left: 2vw;
                                    margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn"
                    (click)="logIn()">
                    <a href="javascript:;" style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:1vw"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4) }}
                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                        </a>
                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;"
                            aria-labelledby="dropdownMenuButton">
                            <div id="dropdown" class="dropdown-menu show"
                                style=" transform: translate3d(-47px, -20px, 0px) !important;">
                                <a class="logout-cutom dropdown-item" routerLink="my-account"
                                    style="color: black !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: black !important;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: black !important;">Logout</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                            margin-top: 2vw;
                                            margin-left: 1vw;">
                            <span style="position: absolute;
                                                background: #FBCB0C;
                                                width: 5vw;
                                                height: 4.5vw;
                                                border-radius: 2vw;margin-left:-15px;top:-1px;">

                                <span style="color: #fff;
                                                    position: absolute;
                                                    left: 0.3rem;
                                                    font-size: 3vw;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                        <!-- <span class="menu-title">
                                                Cart
                                            </span> -->
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text"
                (click)="closeNavkub11()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myfruits1','FRUITS')">
                <p style="color:black">HOME&#8594;</p>
            </a>

            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p style="color:black">PRODUCTS &#8594;</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p style="color:black">ABOUT US &#8594;</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p style="color:black">CONTACT US &#8594;</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myothers1','OTHERS')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;PRODUCTS </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <!-- <div id="myothers1" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;OTHER DEPARTMENTS </span>
                            
                            <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                        
                          </div> -->
        <div id="searchnav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>

            <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                    <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                        <div *ngFor="let category of allCategories">
                                                            <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                        </div>                                      
                                                    </div>
                                                  </div>
                                                <input type="text" placeholder="Search here."
                                                    class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                <div *ngIf="preList.length != 0">
                                                    <div class="autocomplete-items2">
                                                        <div *ngFor="let preItem of preList">
                                                            <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="input-group-btn">
                                                    <button class="btn btn-solid" >
                                                        <i class="fa fa-search" (click)="savePreList1()"></i>
                                                    </button>
                                                </div>
                                            </div>
                                    </form>
                                    
                                </div>
                                
                        
                            </div> -->

        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closeNavkub1()">
                <p>&#8592;</p>
            </a>
            <a class="nav-link menu-text" ng-reflect-router-link="/" href="#/" (click)="closeNavkub1()">
                <p style="color:black">HOME</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')"><p>HOME &#8594;</p></a> -->

            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsList()">
                <p style="color:black">PRODUCTS</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" (click)="routeTo()">
                <p style="color:black">ABOUT US</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" (click)="routeTo()">
                <p style="color:black">CONTACT US</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myothers')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>

            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">PRODUCTS </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <!-- <div id="myothers" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub()">&#8592;OTHER DEPARTMENTS </span>
                            
                            <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                        
                          </div> -->



    </header>
</div>
<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'SpecialWires'"
    style="background-color: white !important;">
    <header>
        <div class="row" style="display: inline-flex; justify-content: normal; width: 100%;">
            <div class="col-1">

                <img src="../../../../../assets/images/specialwires/logo-new.png" alt="Logo Image"
                    style="padding:8px; object-fit: contain; margin-left: 35px;" class="logo-image custom-pointer"
                    height="120" width="300" alt="Logo" [routerLink]="['/']">
            </div>
            <div class="col-4" style="margin-left: 28vw; margin-top: 26px;">
                <div class="inq-menu-st row justify-content-center">
                    <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation" class="navbar-toggler"><span
                                class="navbar-toggler-icon"></span><span>Menu</span></button>
                        <div id="navbarNav" class="collapse navbar-collapse">
                            <ul class="navbar-nav" style="padding:15px;">
                                <li class="nav-item" id="home"
                                    style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                    <a class="nav-link menu-text" ng-reflect-router-link="/" href="#/">
                                        <p style="font-weight: bold;color:black">HOME</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">
                                        <p
                                            style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important; font-weight: bold;color:black">
                                            PRODUCTS</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                    <a href="javascript:;" class="nav-link menu-text" href="#/about-us">
                                        <p style="font-weight: bold;color:black;white-space: nowrap;">ABOUT US</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                    <a href="#/contact-us" class="nav-link menu-text">
                                        <p style="font-weight: bold;color:black;white-space: nowrap;">CONTACT US</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="col-3 header-special" style="margin-top: 3.4%;margin-right:inherit;">

                <ul class="d-flex">
                    <li class="d-flex header-cart account-popup1" style="margin-right: 20%;
                                        margin-left: 20%;">
                        <a href="javascript:;" style="text-decoration: none !important;color: black !important;"
                            (click)="search()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </a>
                        <div class="min-cart-product-wrapper popup-content2 "
                            style="height:auto;overflow-y: visible !important;margin-top: -30px;">
                            <div class="row" style="margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="dropdown-menu autocomplete-items"
                                                style="width: 100%! important;">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"
                                                        style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="text" placeholder="Search here."
                                            class="form-control search-form-input" formControlName="search_term"
                                            (keyup)="getPreList()">
                                        <div *ngIf="preList.length != 0">
                                            <div class="autocomplete-items2">
                                                <div *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
            </div>
        </div>
</div>
<div class="input-group-btn">
    <button class="btn btn-solid">
        <i class="fa fa-search" (click)="savePreList1()"></i>
    </button>
</div>
</div>
</form>

</div>


</div>
</li>

<li class="d-flex" style="margin-right: 25%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
    <a href="javascript:;"
        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;display: contents;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">
        <span class="fa fa-user" style="font-size:25px;margin-right: 6px;">
        </span> Login
    </a>
</li>

<li class="d-flex" style="margin-right:12%;" *ngIf="appService.userLoggedIn">
    <div class="dropdown">
        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style="text-decoration: none !important;color: #1d1b1a !important;"><span class="lnr lnr-user">
                {{ appService.userName.substring(0,6) }}
            </span>
        </a>
        <div id="dropdown" class="dropdown-menu" style="background-color: white!important;"
            aria-labelledby="dropdownMenuButton">
            <a class="logout-cutom dropdown-item" routerLink="my-account"
                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My
                Account</a>
            <a class="logout-cutom dropdown-item" routerLink="my-orders"
                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My
                Order</a>

            <a class="logout-cutom dropdown-item" (click)="logOut()"
                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">Logout</a>
        </div>
    </div>
</li>

<li class="header-cart account-popup d-flex" style="position: relative;">
    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
        <span class="fa fa-shopping-basket" style="font-size:25px">
            <span style="position: absolute;
                                                    background: #FBCB0C;
                                                    width: 18px;
                                                    height: 18px;
                                                    border-radius: 9px;
                                                    top: -10px;
                                                    right: 9%;">

                <span style="  color: #fff;
                                                        position: absolute;
                                                        left: 0.2rem;
                                                        font-size: 14px;
                                                        font-weight: bold;">{{appService.totalCartItems}}</span>
            </span>
        </span>
        <!-- <span class="menu-title">
                                                    Cart
                                                </span> -->
    </a>
    <app-mini-cart></app-mini-cart>
</li>
</ul>

</div>
</div>



</header>
</div>

<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;
                    padding: 2vw;" *ngIf="configs.title === 'SpecialWires'">
    <header>
        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black"
                        (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <img src="../../../../../assets/images/specialwires/logo-new.png" alt="Logo Image" style="width: 34vw;
                                height: 17vw;
                                ;margin-left: 19vw;margin-right:6vw;object-fit: contain;"
                        class="logo-imagekub custom-pointer" alt="Logo" [routerLink]="['/']">
                </li>
                <li class="d-flex header-cart account-popup1">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                                margin-left: 1vw;
                                                margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2"
                        style="height:auto;overflow-y: visible !important;">
                        <div class="row" style="margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li class="d-flex" style="    margin-left: 2vw;
                                        margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn"
                    (click)="logIn()">
                    <a href="javascript:;" style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:1vw"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4) }}...
                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                        </a>
                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;"
                            aria-labelledby="dropdownMenuButton">
                            <div id="dropdown" class="dropdown-menu show"
                                style=" transform: translate3d(-47px, -20px, 0px) !important;">
                                <a class="logout-cutom dropdown-item" routerLink="my-account"
                                    style="color: black !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: black !important;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: black !important;">Logout</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                                margin-top: 2vw;
                                                margin-left: 1vw;">
                            <span style="position: absolute;
                                                    background: #FBCB0C;
                                                    width: 5vw;
                                                    height: 4.5vw;
                                                    top: 0vw;
                                                    border-radius: 2vw;">

                                <span style="color: #fff;
                                                        position: absolute;
                                                        margin-top: 2px;
                                                        left: 0.3rem;
                                                        font-size: 3vw;
                                                        font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                        <!-- <span class="menu-title">
                                                    Cart
                                                </span> -->
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text"
                (click)="closeNavkub11()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myfruits1','FRUITS')">
                <p style="color:black">HOME&#8594;</p>
            </a>

            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p style="color:black">PRODUCTS &#8594;</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p style="color:black">ABOUT US &#8594;</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p style="color:black">CONTACT US &#8594;</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myothers1','OTHERS')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;PRODUCTS </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <!-- <div id="myothers1" class="sidenav">
                                <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;OTHER DEPARTMENTS </span>
                                
                                <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                            
                              </div> -->
        <div id="searchnav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>

            <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                    <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                        <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                            <div *ngFor="let category of allCategories">
                                                                <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                            </div>                                      
                                                        </div>
                                                      </div>
                                                    <input type="text" placeholder="Search here."
                                                        class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                    <div *ngIf="preList.length != 0">
                                                        <div class="autocomplete-items2">
                                                            <div *ngFor="let preItem of preList">
                                                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="input-group-btn">
                                                        <button class="btn btn-solid" >
                                                            <i class="fa fa-search" (click)="savePreList1()"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                        </form>
                                        
                                    </div>
                                    
                            
                                </div> -->

        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closeNavkub1()">
                <p>&#8592;</p>
            </a>
            <a class="nav-link menu-text" ng-reflect-router-link="/" href="#/" (click)="closeNavkub1()">
                <p style="color:black">HOME</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')"><p>HOME &#8594;</p></a> -->

            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsList()">
                <p style="color:black;">PRODUCTS</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" (click)="routeTo()">
                <p style="color:black">ABOUT US</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" (click)="routeTo()">
                <p style="color:black">CONTACT US</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myothers')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>

            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">PRODUCTS </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
        </div>
    </header>
</div>
<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'Schift Cosmetics'"
    style="background-color: #fefefe !important;">
    <header>
        <div class="row" style="display: inline-flex; justify-content: normal; width: 100%;">
            <div class="col-1" style="margin-left: 1%;">
                <!--                             
                                    <img src="../../assets/images/schift/logo.png" alt="Logo Image" style="padding:8px; object-fit: contain;"class="logo-image custom-pointer" height="100"  alt="Logo"
                                    [routerLink]="['/']">  -->
                <img [src]="logoImage" alt="Logo Image" class="logo-image custom-pointer" height="100" width="150"
                    alt="Logo" style="padding:8px; object-fit: contain;" [routerLink]="['/']">
            </div>
            <div class="col-4" style="margin-left: 25vw; margin-top: 17px;">
                <div class="inq-menu-st row justify-content-center">
                    <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation" class="navbar-toggler"><span
                                class="navbar-toggler-icon"></span><span>Menu</span></button>
                        <div id="navbarNav" class="collapse navbar-collapse">
                            <ul class="navbar-nav" style="padding:15px;width: 70%;">
                                <!-- <li class="nav-item" id="home" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                                href="#/"><p style="font-weight: 580;color:#504d4d;font-size: 14px;">HOME</p></a></li> -->
                                <li class="header-cart account-popup1" id="shop"
                                    style="margin-left: 0.1rem !important;width: 20%;">
                                    <a href="javascript:;" class="nav-link menu-text"
                                        (click)="productsListSchift('Skin Care')"
                                        style="width: max-content;display: flex;">
                                        <p
                                            style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                                            SKIN CARE</p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="21"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-chevron-down" style="color: black;">
                                            <polyline points="6 9 12 15 18 9"></polyline>
                                        </svg>
                                    </a>

                                    <app-new-navbar [category]="skincare"></app-new-navbar>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="margin-left: 0.1rem !important;width: 20%;">
                                    <a href="javascript:;" class="nav-link menu-text"
                                        (click)="productsListSchift('UV Care')" style="width: max-content;">
                                        <p
                                            style="text-shadow: none;font-size: 13px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                                            UV CARE</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="margin-left: 0.1rem !important;width: 20%;">
                                    <a href="javascript:;" class="nav-link menu-text"
                                        (click)="productsListSchift('Anti Aging Care')" style="width: max-content;">
                                        <p
                                            style="text-shadow: none;font-size: 13px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                                            ANTI AGING CARE</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="margin-left: 0.1rem !important;width: 20%;">
                                    <a href="javascript:;" class="nav-link menu-text"
                                        (click)="productsListSchift('Sports Care')" style="width: max-content;">
                                        <p
                                            style="text-shadow: none;font-size: 13px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                                            SPORTS CARE</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="margin-left: 0.1rem !important;width: 20%;">
                                    <a href="javascript:;" class="nav-link menu-text"
                                        (click)="productsListSchift('Travel Care')" style="width: max-content;">
                                        <p
                                            style="text-shadow: none;font-size: 13px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                                            TRAVEL CARE</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                    <a href="javascript:;" class="nav-link menu-text" style="display: flex;">
                                        <p style="font-size: 13px; font-weight: 580; color: #504d4d;">INFORMATION</p>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="21"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-chevron-down" style="color: black;">
                                            <polyline points="6 9 12 15 18 9"></polyline>
                                        </svg>
                                    </a>
                                    <app-new-navbar [category]="fruits"></app-new-navbar>
                                </li>
                                <!-- <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                                                        <a href="javascript:;" class="nav-link menu-text"href="#/about-us" ><p style="font-weight: 580;color:#504d4d;font-size: 14px;white-space: nowrap;">ABOUT US</p></a>
                                                                        </li>
                                                                <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                                                    <a href="#/contact-us" class="nav-link menu-text"><p style="font-weight: 580;color:#504d4d;white-space: nowrap;font-size: 14px;">CONTACT US</p></a>
                                                                    </li>      -->
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="col-3 header-special"
                style="margin-left: 12%;margin-top: 2.7%;margin-right:inherit;max-width: 12%;">

                <ul class="d-flex">
                    <li class="d-flex header-cart account-popup1" style="margin-right: 16%;margin-left: 20%;">
                        <a href="javascript:;"
                            style="text-decoration: none !important;color: black !important;font-size: 15px;"
                            (click)="search()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </a>
                        <div class="min-cart-product-wrapper popup-content2 "
                            style="height:auto;overflow-y: visible !important;margin-top: -30px;">
                            <div class="row" style="margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="dropdown-menu autocomplete-items"
                                                style="width: 100%! important;">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"
                                                        style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="text" placeholder="Search here."
                                            class="form-control search-form-input" formControlName="search_term"
                                            (keyup)="getPreList()">
                                        <div *ngIf="preList.length != 0">
                                            <div class="autocomplete-items2">
                                                <div *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
            </div>
        </div>
</div>
<div class="input-group-btn" style="cursor:context-menu;">
    <div style="width: 135% ; display: flex;justify-content: center;padding-top: 64%;margin-left: 3px;">
        <i class="fa fa-search" style="cursor:context-menu;" (click)="savePreList1()"></i>
    </div>
</div>
</div>
</form>

</div>


</div>
</li>

<li class="d-flex" style="margin-right: 25%;margin-left: 25%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
    <a href="javascript:;"
        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 13px;font-weight: bold;">
        <span class="fa fa-user" style="font-size:20px;margin-right: 6px;color: #c49072;">
        </span>
    </a>
</li>

<li class="d-flex" style="margin-right:12%;" *ngIf="appService.userLoggedIn">
    <div class="dropdown">
        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style="text-decoration: none !important;color: #1d1b1a !important;"><span class="lnr lnr-user">
                {{ appService.userName.substring(0,13) }}
            </span>
        </a>
        <div id="dropdown" class="dropdown-menu" style="background-color: white!important;"
            aria-labelledby="dropdownMenuButton">
            <a class="logout-cutom dropdown-item" routerLink="my-account"
                style="text-shadow: none;font-family: 'Trebuchet MS','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My
                Account</a>
            <a class="logout-cutom dropdown-item" routerLink="my-orders"
                style="text-shadow: none;font-family: 'Trebuchet MS','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My
                Order</a>
            <a class="logout-cutom dropdown-item" (click)="logOut()"
                style="text-shadow: none;font-family: 'Trebuchet MS','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold; cursor: pointer;">Logout</a>
        </div>
    </div>
</li>
<li class="header-cart account-popup d-flex" style="position: relative;">
    <a id="openbagschift" href="javascript:;" data-toggle="modal" data-target="#bagModal"
        style="text-decoration: none !important;color: #0a0a0a!important;margin-left: 10px;">
        <span class="fa fa-shopping-cart" style="font-size:20px;color: #7e8427;">
            <span style="position: absolute;
                                                        background: #7e8427;
                                                        width: 16px;
                                                        height: 18px;
                                                        border-radius: 9px;
                                                        top: -10px;
                                                        left: 67%;">

                <span style="  color: #fff;
                                                            position: relative;
                                                            left: 0.2rem;
                                                            font-size: 13px;
                                                            font-weight: bold;">{{appService.totalCartItems}}</span>
            </span>
        </span>
    </a>
</li>
<!-- <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                                <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #7e8427 !important;">
                                                    <span class="fa fa-shopping-cart" style="font-size:20px">
                                                        <span style="position: absolute;
                                                        background: #7e8427;
                                                        width: 16px;
                                                        height: 18px;
                                                        border-radius: 9px;
                                                        top: -10px;
                                                        left: 67%;" >
                                                       
                                                            <span style="  color: #fff;
                                                            position: relative;
                                                            left: 0.2rem;
                                                            font-size: 13px;
                                                            font-weight: bold;">{{appService.totalCartItems}}</span>
                                                        </span>
                                                    </span>  
                                                </a>
                                                <app-mini-cart></app-mini-cart>
                                            </li> -->
</ul>

</div>
</div>



</header>
</div>

<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;
                        padding: 2vw;" *ngIf="configs.title === 'Schift Cosmetics'">
    <header>
        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black"
                        (click)="openNavkub()">&#9776;</span>
                </li>

                <li *ngIf="configs.title === 'Schift Cosmetics'">

                    <!-- <img src="../../assets/images/schift/logo.png" alt="Logo Image" style="width: 20vw;
                                height: 15vw;
                                ;margin-left: 25vw;margin-right:15vw;"class="logo-imagekub custom-pointer" alt="Logo"
                                        [routerLink]="['/']">  -->
                    <img [src]="logoImage" alt="Logo Image" class="logo-imagekub custom-pointer" alt="Logo" style="    width: 23vw;
                                        height: 8vw;margin-left: 28vw;margin-top: 5px;margin-right:15vw;"
                        [routerLink]="['/']">

                </li>

                <!-- <li class="d-flex" style="    margin-left: 2vw;
                                            margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                                <a href="javascript:;"  style="text-decoration: none !important;color: black !important;">
                                                    <span class="fa fa-user" style="font-size:6vw;color: #c49072;">
                                                    </span>
                                                </a>
                                            </li>
                    
                                            <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                                                <div class="dropdown">
                                                    <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:1vw"><span
                                                            class="lnr lnr-user">
                                                        </span>
                                                        {{ appService.userName.substring(0,4) }}...
                                                    </a>
                                                    <div id="dropdown" class="dropdown-menu" style="background-color: white !important;" aria-labelledby="dropdownMenuButton">
                                                        <div id="dropdown" class="dropdown-menu show" style=" transform: translate3d(-47px, -20px, 0px) !important;">
                                                        <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: black !important;">My Account</a>
                                                        <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: black !important;">My Order</a>
                                                        
                                                        <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: black !important;">Logout</a>
                                                    </div>
                                                    </div>
                                                </div>
                                            </li> -->
                <li class="header-cart account-popup d-flex" style="position: relative;margin-left: 35px;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: #7e8427 !important;">
                        <span class="fa fa-shopping-cart" style="font-size: 6vw;
                                                    margin-top: 2vw;
                                                    margin-left: 1vw;">
                            <span style="position: absolute;
                                                        background: #7e8427;
                                                        width: 5vw;
                                                        height: 4.5vw;
                                                        top: 0vw;
                                                        border-radius: 2vw;">

                                <span style="color: #fff;
                                                            position: absolute;
                                                            margin-top: 2px;
                                                            left: 0.3rem;
                                                            font-size: 3vw;
                                                            font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                        <!-- <span class="menu-title">
                                                        Cart
                                                    </span> -->
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#faf2f2">


            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text"
                (click)="closeNavkub11()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myfruits1','FRUITS')">
                <p style="color:black">HOME&#8594;</p>
            </a>

            <!-- <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">PRODUCTS &#8594;</p></a> -->
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('Skin Care')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    SKIN CARE</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('Cleanser')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Cleanser</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('Moisturizers')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Moisturizers</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('Mists and Toner')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Mists/Toner</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('Masks')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Masks</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('UV Care')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    UV CARE</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('Anti Aging Care')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    ANTI AGING CARE</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('Sports Care')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    SPORTS CARE</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('Travel Care')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    TRAVEL CARE</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    INFORMATION</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    ABOUT US &#8594;</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    CONTACT US &#8594;</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myothers1','OTHERS')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;PRODUCTS </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <!-- <div id="myothers1" class="sidenav">
                                    <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;OTHER DEPARTMENTS </span>
                                    
                                    <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                                
                                  </div> -->
        <div id="searchnav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>

            <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                        <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                <div *ngFor="let category of allCategories">
                                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                </div>                                      
                                                            </div>
                                                          </div>
                                                        <input type="text" placeholder="Search here."
                                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                        <div *ngIf="preList.length != 0">
                                                            <div class="autocomplete-items2">
                                                                <div *ngFor="let preItem of preList">
                                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="input-group-btn">
                                                            <button class="btn btn-solid" >
                                                                <i class="fa fa-search" (click)="savePreList1()"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                            </form>
                                            
                                        </div>
                                        
                                
                                    </div> -->

        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#faf2f2">

            <li class="d-flex" style="
                                    margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn"
                (click)="logIn()">
                <a href="javascript:;" style="text-decoration: none !important;color: black !important;">
                    <span style="color: black !important;">LOGIN
                    </span>
                </a>
            </li>

            <li class="d-flex" *ngIf="appService.userLoggedIn">
                <div class="dropdown">
                    <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="configs.title === 'Schift Cosmetics'" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false"
                        style="text-decoration: none !important;color: black !important;font-size:5vw;margin-top:10px"><span
                            class="lnr lnr-user">
                        </span>
                        {{ appService.userName.substring(0,13)}}
                        <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                    </a>
                    <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                        *ngIf="configs.title !== 'Schift Cosmetics'" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false"
                        style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:1vw"><span
                            class="lnr lnr-user">
                        </span>
                        {{ appService.userName.substring(0,4) }}...
                        <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                    </a>
                    <div id="dropdown" class="dropdown-menu schift-dropmenu" style="background-color: white !important;"
                        aria-labelledby="dropdownMenuButton">
                        <div id="dropdown" class="dropdown-menu show"
                            style=" transform: translate3d(0px, -20px, 0px) !important;">
                            <a class="logout-cutom dropdown-item" (click)="myAccount()"
                                style="color: black !important;">My Account</a>
                            <a class="logout-cutom dropdown-item" (click)="myOrder()"
                                style="color: black !important;">My Order</a>

                            <a class="logout-cutom dropdown-item" (click)="logOut()"
                                style="color: black !important;">Logout</a>
                        </div>
                    </div>
                </div>
            </li>
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closeNavkub1()">
                <p style="font-size: 30px;margin-top: -15px;">&#8592;</p>
            </a>
            <a class="nav-link menu-text" ng-reflect-router-link="/" href="#/" (click)="closeNavkub1()">
                <p style="color:black">HOME</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')"><p>HOME &#8594;</p></a> -->

            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsList()"><p style="color:black;">PRODUCTS</p></a> -->
            <a href="javascript:void(0);" class="nav-link menu-text" (click)="productsListSchift('Skin Care')"
                style="width: max-content;">
                <p style="color:black">SKIN CARE</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('Cleanser')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    &nbsp;&nbsp; - &nbsp; Cleanser</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('Moisturizers')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    &nbsp;&nbsp; - &nbsp;Moisturizers</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('Mists and Toner')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    &nbsp;&nbsp; - &nbsp;Mists/Toner</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListSchift('Masks')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    &nbsp;&nbsp; - &nbsp;Masks</p>
            </a>
            <a href="javascript:void(0);" class="nav-link menu-text" (click)="productsListSchift('UV Care')"
                style="width: max-content;">
                <p style="color:black">UV CARE</p>
            </a>
            <a href="javascript:void(0);" class="nav-link menu-text" (click)="productsListSchift('Anti Aging Care')"
                style="width: max-content;">
                <p style="color:black">ANTI AGING CARE</p>
            </a>
            <a href="javascript:void(0);" class="nav-link menu-text" (click)="productsListSchift('Sports Care')"
                style="width: max-content;">
                <p style="color:black">SPORTS CARE</p>
            </a>
            <a href="javascript:void(0);" class="nav-link menu-text" (click)="productsListSchift('Travel Care')"
                style="width: max-content;">
                <p style="color:black">TRAVEL CARE</p>
            </a>
            <a href="javascript:void(0);" class="nav-link menu-text" (click)="productsListSchift('Travel Care')"
                style="width: max-content;">
                <p style="color:black">INFORMATION</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" (click)="routeTo()">
                <p
                    style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    &nbsp;&nbsp; - &nbsp;ABOUT US</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" (click)="routeTo()">
                <p
                    style="text-shadow: none;font-size: 12px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    &nbsp;&nbsp; - &nbsp;CONTACT US</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myothers')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>

            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">PRODUCTS </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
        </div>
    </header>
</div>
<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'Babys Pride'"
    style="background-color: rgb(255, 255, 255) !important;">
    <header>
        <div
            style="margin-right:inherit;background-color: rgb(235, 233, 233); display: flex;justify-content: space-around;padding-top: 5px;">
            <div style="width: 50%; padding-top: 10px;">
                <p style="font-size: 11px;font-family: system-ui;">WELCOME TO <strong style="font-size: 11px;">BABY'S
                        PRIDE</strong> ONLINE SHOP</p>
            </div>
            <ul class="d-flex" style="justify-content: end;">


                <li class="d-flex" style="margin-right: 25%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                    <a href="javascript:;"
                        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;display: contents;color: #4f4f4f!important;text-align: center; font-size: 11px;font-weight: bold;">
                        <!-- <span class="fa fa-user" style="font-size:15px;margin-right: 6px;">
                                                </span>  -->
                        LOGIN/REGISTER
                    </a>
                </li>

                <li class="d-flex" style="margin-right:12%;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: #41403f !important;font-family: system-ui;"><span
                                class="lnr lnr-user" style="text-shadow: none;
                                                        font-family: 'proxima-regular','sans-serif'!important;
                                                        display: contents;
                                                        color: #4f4f4f!important;
                                                        text-align: center;
                                                        font-size: 11px;
                                                        font-weight: bold;">
                                {{ appService.userName.substring(0,6) }}
                            </span>
                        </a>
                        <div id="dropdown" class="dropdown-menu" style="background-color: white!important;"
                            aria-labelledby="dropdownMenuButton">
                            <a class="logout-cutom dropdown-item" routerLink="my-account"
                                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #41403f!important;text-align: center; font-size: 12px;font-weight: bold;">My
                                Account</a>
                            <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #41403f!important;text-align: center; font-size: 12px;font-weight: bold;">My
                                Order</a>

                            <a class="logout-cutom dropdown-item" (click)="logOut()"
                                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #41403f!important;text-align: center; font-size: 12px;font-weight: bold;">Logout</a>
                        </div>
                    </div>
                </li>
                <li class="d-flex header-cart account-popup1" style="margin-right: 20%;
                                        margin-left: 20%;">
                    <a href="javascript:;" style="text-decoration: none !important;color: #41403f !important;"
                        (click)="search()">
                        <i class="fa fa-search" style="font-size: 15px;" aria-hidden="true"></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2 "
                        style="height:auto;overflow-y: visible !important;margin-top: -30px;top: 13%; min-width: 30%;width: 30%;">
                        <div class="row" style="margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="text" placeholder="Search here." class="form-control search-form-input"
                                        formControlName="search_term" (keyup)="getPreList()">
                                    <div *ngIf="preList.length != 0">
                                        <div class="autocomplete-items2">
                                            <div *ngFor="let preItem of preList">
                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
        </div>
</div>
</div>
<div class="input-group-btn">
    <button class="btn btn-solid">
        <i class="fa fa-search" (click)="savePreList1()"></i>
    </button>
</div>
</div>
</form>

</div>


</div>
</li>
<li class="header-cart account-popup d-flex" style="position: relative;">
    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #41403f !important;">
        <span class="fa fa-shopping-basket" style="font-size:15px">
            <span style="position: absolute;
                                                    background: #80c340;
                                                    width: 17px;
                                                    height: 13px;
                                                    border-radius: 9px;
                                                    top: -2px;
                                                    left: 40%;">

                <span style=" width: 100%;
                                                        color: #fff;
                                                        position: absolute;
                                                        font-size: 10px;
                                                        font-weight: bold;
                                                        text-align: center;">{{appService.totalCartItems}}</span>
            </span>
        </span>
    </a>
    <app-mini-cart></app-mini-cart>
</li>
</ul>

</div>
<div class="row" style="display: inline-flex; justify-content: center; width: 100%;margin-right: 0px;
                                margin-left: 0px;
                                background-color: #f6f6f6;">

    <div class="col-4" style=" margin-top: 26px;">
        <div class="inq-menu-st row justify-content-center">
            <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                    data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                    aria-label="Toggle navigation" class="navbar-toggler"><span
                        class="navbar-toggler-icon"></span><span>Menu</span></button>
                <div id="navbarNav" class="collapse navbar-collapse">
                    <ul class="navbar-nav" style="padding:15px;">
                        <li class="nav-item" id="home"
                            style=" margin-top: 30px;text-shadow: none;font-family: system-ui;"><a
                                class="nav-link menu-text" ng-reflect-router-link="/" href="#/">
                                <p style="font-weight: bold;color:#6d6c6a ">HOME</p>
                            </a></li>
                        <li class="header-cart account-popup1" id="shop" style=" margin-top: 30px;">
                            <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">
                                <p style="text-shadow: none;font-family: system-ui; font-weight: bold;color:#6d6c6a ">
                                    SHOP</p>
                            </a>
                        </li>
                        <li style="margin-left: 0px;margin-right: 0px;">

                            <img src="../../../../../assets/images/babyspride/logobaby.png" alt="Logo Image"
                                style=" object-fit: contain;" class="logo-image custom-pointer" height="80" width="220"
                                alt="Logo" [routerLink]="['/']">
                        </li>
                        <li class="header-cart account-popup1" id="shop"
                            style="text-shadow: none;font-family: system-ui;margin-top: 30px;">
                            <a href="javascript:;" class="nav-link menu-text" href="#/about-us">
                                <p style="font-weight: bold;color:#6d6c6a ;white-space: nowrap;">ABOUT US</p>
                            </a>
                        </li>
                        <li class="header-cart account-popup1" id="shop"
                            style="text-shadow: none;font-family: system-ui;margin-top: 30px;">
                            <a href="#/contact-us" class="nav-link menu-text">
                                <p style="font-weight: bold;color:#6d6c6a ;white-space: nowrap;">CONTACT US</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>

</div>



</header>
</div>

<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;
                            padding: 2vw;" *ngIf="configs.title === 'Babys Pride'">
    <header>
        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black"
                        (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <img src="../../../../../assets/images/babyspride/logobaby.png" alt="Logo Image" style="width: 34vw;
                                        height: 17vw;
                                        ;margin-left: 19vw;margin-right:6vw;object-fit: contain;"
                        class="logo-imagekub custom-pointer" alt="Logo" [routerLink]="['/']">
                </li>
                <li class="d-flex header-cart account-popup1">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                                        margin-left: 1vw;
                                                        margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2"
                        style="height:auto;overflow-y: visible !important;">
                        <div class="row" style="margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li class="d-flex" style="    margin-left: 2vw;
                                                margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn"
                    (click)="logIn()">
                    <a href="javascript:;" style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:1vw"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4) }}...
                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                        </a>
                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;"
                            aria-labelledby="dropdownMenuButton">
                            <div id="dropdown" class="dropdown-menu show"
                                style=" transform: translate3d(-47px, -20px, 0px) !important;">
                                <a class="logout-cutom dropdown-item" routerLink="my-account"
                                    style="color: black !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: black !important;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: black !important;">Logout</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                                        margin-top: 2vw;
                                                        margin-left: 1vw;">
                            <span style="position: absolute;
                                                            background: #80c340;
                                                            width: 5vw;
                                                            height: 4.5vw;
                                                            top: 0vw;
                                                            border-radius: 2vw;">

                                <span
                                    style="color: #fff;
                                                                position: absolute;
                                                                margin-top: 2px;
                                                                left: 0.3rem;
                                                                font-size: 3vw;
                                                                font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text"
                (click)="closeNavkub11()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myfruits1','FRUITS')">
                <p style="color:black">HOME&#8594;</p>
            </a>

            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p style="color:black">SHOP &#8594;</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p style="color:black">ABOUT US &#8594;</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p style="color:black">CONTACT US &#8594;</p>
            </a>


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;SHOP </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="searchnav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>


        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closeNavkub1()">
                <p>&#8592;</p>
            </a>
            <a class="nav-link menu-text" ng-reflect-router-link="/" href="#/" (click)="closeNavkub1()">
                <p style="color:black">HOME</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')"><p>HOME &#8594;</p></a> -->

            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsList()">
                <p style="color:black;">SHOP</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" (click)="routeTo()">
                <p style="color:black">ABOUT US</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" (click)="routeTo()">
                <p style="color:black">CONTACT US</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myothers')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>

            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">SHOP </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
        </div>
    </header>
</div>
<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'LushNGreen'"
    style="background-color: rgb(255, 255, 255) !important;">
    <header>
        <div style="display: flex;margin-top: 20px;">
            <div style="width: 15%; margin-left: 2%;">
                <img src="../../../../../assets/images/lushngreen/lushngreenlogo.png" alt="" [routerLink]="['/']"
                    style="width: 80%;margin-left: 12%; cursor: pointer;margin-bottom: 20px;">
            </div>
            <div style="width: 83%;">
                <div
                    style=" margin-right: inherit;display: flex;justify-content: space-between;padding-top: 5px;width: 88%;margin-left: 5%;">
                    <div class="search-bar-group" style="margin-right: 10px !important; margin-top: 5px; width: 80%;">
                        <form [formGroup]="searchForm" class="search-bar">
                            <div class="d-flex justify-content-between">


                                <!-- <p>Browse all <br><span>Categories</span></p> -->
                                <div class="input-group">
                                    <div id='vimpro_cate' class="input-group-prepend desktop-only">
                                        <button class="btn search-drop dropdown-togglecat v_cate_top"
                                            style="min-height: 42px;" type="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" (click)="closePrelist()">ALL
                                            CATEGORIES</button>
                                        <div class="dropdown-menu autocomplete-items vim_dropdown"
                                            style="width: 15rem!important">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item vimpro_drop1" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name |
                                                    titlecase}}</a>

                                            </div>

                                        </div>
                                    </div>
                                    <input type="text" placeholder="Search our catalogue.."
                                        style="min-height: 42px; border: 1px solid #a9c70b; width: 60%;"
                                        class="form-control search-form-input" formControlName="search_term"
                                        (keyup)="getPreList()">
                                    <div *ngIf="preList.length != 0">
                                        <div class="autocomplete-items3">
                                            <div *ngFor="let preItem of preList">
                                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group-btn" style="background-color: #a9c70b;border-top-right-radius: 10%;
                                                        border-bottom-right-radius: 10%;">
                                        <button class="btn btn-solid vimpro-searchbtn">
                                            <i class="fa fa-search" (click)="savePreList1()" style="color: white;"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <ul class="d-flex" style="justify-content: end;width: 15%;margin-left: 5%;margin-top: 2%;">


                        <li class="d-flex" style="margin-right: 25%;" *ngIf="!appService.userLoggedIn"
                            (click)="logIn()">
                            <a href="javascript:;" style="text-shadow: none;color: #999999!important;">
                                <span class="fa fa-user-o" style="font-size:23px;margin-right: 6px;padding-top: 4px;">
                                </span>

                            </a>
                        </li>

                        <li class="d-flex" style="margin-right:12%;" *ngIf="appService.userLoggedIn">
                            <div class="dropdown">
                                <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    style="text-decoration: none !important;color: #41403f !important;font-family: system-ui;"><span
                                        class="lnr lnr-user" style="text-shadow: none;
                                                            font-family: 'proxima-regular','sans-serif'!important;
                                                            display: contents;
                                                            color: #4f4f4f!important;
                                                            text-align: center;
                                                            font-size: 13px;
                                                            font-weight: bold;">
                                        {{ appService.userName.substring(0,6) }}
                                    </span>
                                </a>
                                <div id="dropdown" class="dropdown-menu" style="background-color: white!important;"
                                    aria-labelledby="dropdownMenuButton">
                                    <a class="logout-cutom dropdown-item" routerLink="my-account"
                                        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #41403f!important;text-align: center; font-size: 12px;font-weight: bold;">My
                                        Account</a>
                                    <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #41403f!important;text-align: center; font-size: 12px;font-weight: bold;">My
                                        Order</a>

                                    <a class="logout-cutom dropdown-item" (click)="logOut()"
                                        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #41403f!important;text-align: center; font-size: 12px;font-weight: bold;">Logout</a>
                                </div>
                            </div>
                        </li>
                        <!-- <li class="d-flex header-cart account-popup1" style="margin-right: 20%;
                                            margin-left: 20%;">
                                                <a href="javascript:;"  style="text-decoration: none !important;color: #41403f !important;" (click)="search()">
                                                    <i class="fa fa-search" style="font-size: 15px;" aria-hidden="true"></i>
                                                </a>
                                                <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y: visible !important;margin-top: -30px;top: 13%; min-width: 30%;width: 30%;">
                                                    <div class="row" style="margin: 5px;">
                                                        <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                            <div *ngFor="let category of allCategories">
                                                                                <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                            </div>                                      
                                                                        </div>
                                                                      </div>
                                                                    <input type="text" placeholder="Search here."
                                                                        class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                                    <div *ngIf="preList.length != 0">
                                                                        <div class="autocomplete-items2">
                                                                            <div *ngFor="let preItem of preList">
                                                                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="input-group-btn">
                                                                        <button class="btn btn-solid" >
                                                                            <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                        </form>
                                                        
                                                    </div>
                                                    
                                            
                                                </div>
                                            </li> -->
                        <li class="header-cart account-popup d-flex" style="position: relative;">
                            <a href="javascript:;" routerLink="/cart"
                                style="text-decoration: none !important;color: #999999 !important;">
                                <span class="fa fa-shopping-bag" style="font-size:22px;margin-top: 5px;">
                                    <span style="position: absolute;
                                                        background: #83c33a;
                                                        width: 17px;
                                                        height: 15px;
                                                        border-radius: 9px;
                                                        top: -6px;
                                                        left: 40%;">

                                        <span style=" width: 100%;
                                                            color: #fff;
                                                            position: absolute;
                                                            font-size: 12px;
                                                            font-weight: bold;
                                                            text-align: center;">{{appService.totalCartItems}}</span>
                                    </span>
                                </span>
                            </a>
                            <app-mini-cart></app-mini-cart>
                        </li>
                    </ul>

                </div>
                <div style="margin-left: 5%;width: 72%;">
                    <div class="inq-menu-st row">
                        <nav class="navbar navbar-expand-lg navbar-light" style="width: 100%;"><button type="button"
                                data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
                                aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"><span
                                    class="navbar-toggler-icon"></span><span>Menu</span></button>
                            <div id="navbarNav" class="collapse navbar-collapse">
                                <ul class="navbar-nav" style="padding:15px;padding-left: 0px !important;width: 100%;">
                                    <!-- <li class="nav-item" id="home" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                            href="#/"><p style="font-weight: 580;color:#504d4d;font-size: 14px;">HOME</p></a></li> -->
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 0.1rem !important;width: 20%;">
                                        <a href="javascript:;" class="nav-link menu-text"
                                            (click)="productsListLush('Plants')"
                                            style="width: max-content;display: flex;">
                                            <p
                                                style="text-shadow: none;font-size: 14.5px;font-family: 'Roboto'!important; font-weight: 600;color:black">
                                                PLANTS</p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-chevron-down" style="color: black;">
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                            </svg>
                                        </a>
                                        <!-- <app-new-navbar [category]="plants"></app-new-navbar>    -->
                                        <app-new-navbar [category]="plants"></app-new-navbar>
                                    </li>
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 0.1rem !important;width: 20%;">
                                        <a href="javascript:;" class="nav-link menu-text"
                                            (click)="productsListLush('New Arrivals')" style="width: max-content;">
                                            <p
                                                style="text-shadow: none;font-size: 14.5px;font-family: 'Roboto'!important; font-weight: 600;color:black">
                                                NEW ARRIVAL</p>
                                        </a>
                                    </li>
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 2% !important;width: 20%;">
                                        <a href="javascript:;" class="nav-link menu-text"
                                            (click)="productsListLush('Planters')" style="width: max-content;">
                                            <p
                                                style="text-shadow: none;font-size: 14.5px;font-family: 'Roboto'!important; font-weight: 600;color:black">
                                                PLANTERS</p>
                                        </a>
                                    </li>
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 2% !important;width: 20%;">
                                        <a href="javascript:;" class="nav-link menu-text"
                                            (click)="productsListLush('Gardening')" style="width: max-content;">
                                            <p
                                                style="text-shadow: none;font-size: 14.5px;font-family: 'Roboto'!important; font-weight: 600;color:black">
                                                GARDENING</p>
                                        </a>
                                    </li>
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 2% !important;width: 20%;">
                                        <a href="javascript:;" class="nav-link menu-text"
                                            (click)="productsListLush('Manures')" style="width: max-content;">
                                            <p
                                                style="text-shadow: none;font-size: 14.5px;font-family: 'Roboto'!important; font-weight: 600;color:black">
                                                MANURES</p>
                                        </a>
                                    </li>
                                    <li class="header-cart account-popup1" id="shop"
                                        style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 2% !important">
                                        <a href="javascript:;" class="nav-link menu-text" style="display: flex;">
                                            <p style="font-size: 14.5px; font-weight: 600; color: black;">PAGES</p><svg
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-chevron-down" style="color: black;">
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                            </svg>
                                        </a>
                                        <app-new-navbar [category]="fruits"></app-new-navbar>
                                    </li>
                                    <!-- <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                                                    <a href="javascript:;" class="nav-link menu-text"href="#/about-us" ><p style="font-weight: 580;color:#504d4d;font-size: 14px;white-space: nowrap;">ABOUT US</p></a>
                                                                    </li>
                                                            <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                                                <a href="#/contact-us" class="nav-link menu-text"><p style="font-weight: 580;color:#504d4d;white-space: nowrap;font-size: 14px;">CONTACT US</p></a>
                                                                </li>      -->
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>


    </header>
</div>

<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;
                                padding: 2vw;" *ngIf="configs.title === 'LushNGreen'">
    <header>
        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black"
                        (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <img src="../../../../../assets/images/lushngreen/lushngreenlogo.png" alt="Logo Image" style="width: 34vw;
                                            height: 17vw;
                                            ;margin-left: 19vw;margin-right:6vw;object-fit: contain;cursor: pointer;"
                        class="logo-imagekub custom-pointer" alt="Logo" [routerLink]="['/']">
                </li>
                <li class="d-flex header-cart account-popup1" style="margin-top: 4px;">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                                            margin-left: 1vw;
                                                            margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2"
                        style="height:auto;overflow-y: visible !important;">
                        <div class="row" style="margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li class="d-flex" style="    margin-left: 2vw;
                                                    margin-top: 2.5vw;margin-right: 2vw;"
                    *ngIf="!appService.userLoggedIn" (click)="logIn()">
                    <a href="javascript:;" style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:2vw"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4) }}...
                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                        </a>
                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;"
                            aria-labelledby="dropdownMenuButton">
                            <div id="dropdown" class="dropdown-menu show"
                                style=" transform: translate3d(-47px, -20px, 0px) !important;">
                                <a class="logout-cutom dropdown-item" routerLink="my-account"
                                    style="color: black !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: black !important;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: black !important;">Logout</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative; margin-top:3px;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                                            margin-top: 2vw;
                                                            margin-left: 1vw;">
                            <span style="position: absolute;
                                                                background: #80c340;
                                                                width: 5vw;
                                                                height: 4.5vw;
                                                                top: 0vw;
                                                                border-radius: 2vw;">

                                <span
                                    style="color: #fff;
                                                                    position: absolute;
                                                                    margin-top: 2px;
                                                                    left: 0.3rem;
                                                                    font-size: 3vw;
                                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text"
                (click)="closeNavkub11()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="homelush()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    HOME</p>
            </a>

            <!-- <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">PRODUCTS &#8594;</p></a> -->
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Plants')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    PLANTS</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Indoor Plants')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    INDOOR PLANTS</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Outdoor Plants')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    OUTDOOR PLANTS</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Exotic Plants')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    EXOTIC PLANTS</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Miniatures')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    MINIATURES</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('New Arrivals')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    NEW ARRIVALS</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Planters')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    PLANTERS</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Gardening')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    GARDENING</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Manures')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    MANURES</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    ABOUT US</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    CONTACT US </p>
            </a>


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;SHOP </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="searchnav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>


        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text"
                (click)="closeNavkub1()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="homelush()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    HOME</p>
            </a>

            <!-- <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">PRODUCTS &#8594;</p></a> -->
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Plants')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    PLANTS</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Indoor Plants')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    INDOOR PLANTS</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Outdoor Plants')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    OUTDOOR PLANTS</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Exotic Plants')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    EXOTIC PLANTS</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Miniatures')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    MINIATURES</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('New Arrivals')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    NEW ARRIVALS</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Planters')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    PLANTERS</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Gardening')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    GARDENING</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Manures')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    MANURES</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    ABOUT US</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    CONTACT US </p>
            </a>


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>

            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">SHOP </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
        </div>
    </header>
</div>
<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'Taazah'"
    style="background-color: rgb(255, 255, 255) !important;">
    <header>
        <div style="display: flex;margin-top: 20px;">

            <div style="width: 100%;">
                <div
                    style=" margin-right: inherit;display: flex;justify-content: space-between;padding-top: 5px;width: 97%;">
                    <div style="width: 15%; margin-left: 2%;margin-right: 2%;">
                        <img src="../../../../../assets/images/taazah/logowhite.jpg" alt="" [routerLink]="['/']"
                            style="width: 90%;margin-left: 12%; cursor: pointer;margin-bottom: 20px;">
                    </div>
                    <div class="search-bar-group" style="margin-right: 10px !important; margin-top: 5px; width: 26%;">
                        <!-- <form [formGroup]="searchForm" class="search-bar">
                                                    <div class="d-flex justify-content-between">

                                                        <div class="input-group">
                                                            <input type="text" placeholder="Search our catalogue.." style="height: 32px;background-color: #f3f3f3!important; width: 60%;border: none;"
                                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                            <div *ngIf="preList.length != 0">
                                                                <div class="autocomplete-items3">
                                                                    <div *ngFor="let preItem of preList">
                                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="input-group-btn" style="background-color: #f3f3f3;border-top-right-radius: 10%;
                                                            border-bottom-right-radius: 10%;height: 32px;">
                                                                <button class="btn btn-solid vimpro-searchbtn" style="padding: 3px 10px;">
                                                                    <i class="fa fa-search" (click)="savePreList1()" style="color: rgb(134, 134, 134);"></i>
                                                                </button>
                                                            </div> 
                                                        </div>
                                                        
                                                    </div>
                                                </form>                           -->
                        <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                        <div *ngFor="let category of allCategories">
                                            <a class="dropdown-item" (click)="navigateToList(category)"
                                                style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                        </div>
                                    </div>
                                </div>
                                <input type="text" placeholder="Search for Products.."
                                    style="height: 32px;background-color: #e5e4e4!important; width: 60%;border: none;opacity: 0.6;"
                                    class="form-control search-form-input Taz_input_search"
                                    formControlName="search_term" (keyup)="getPreList()">
                                <div *ngIf="preList.length != 0">
                                    <div class="autocomplete-items2">
                                        <div *ngFor="let preItem of preList">
                                            <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group-btn" style="background-color: #f3f3f3;border-top-right-radius: 10%;
                                                        border-bottom-right-radius: 10%;height: 32px;">
                                    <button class="btn btn-solid vimpro-searchbtn" style="padding: 3px 10px;">
                                        <i class="fa fa-search" (click)="savePreList1()"
                                            style="color: rgb(134, 134, 134);"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <ul class="d-flex"
                        style="justify-content: space-evenly;width: 35%;margin-left: 13%;margin-top: 1%;">

                        <li><i class="fa-map-marker fa" style="color: #386eb6;font-size: 19px;"></i> <span
                                style="font-family: system-ui;font-size: 13px;font-weight: 700; margin-left: 5px;">600001</span>
                        </li>
                        <li><i class="fa-phone  fa"
                                style="color: #386eb6;font-size: 20px;margin-left: 10px;margin-right: 5px;transform: translate(0px, 2px);"></i>
                            <span style="font-size :13px;font-family: system-ui;">Order On Call :</span> <span
                                style="font-family: system-ui;font-size :13px;font-weight: 700;">+91 0987654321</span>
                        </li>
                        <li class="d-flex" style="margin-left: 10px;" *ngIf="!appService.userLoggedIn"
                            (click)="logIn()">
                            <a href="javascript:;" style="text-shadow: none;">
                                <span class="fa fa-user-o"
                                    style="font-size:19px;margin-right: 6px;padding-top: 4px;color: #386eb6;"><span
                                        style="font-weight: 600;color: black;font-size :13px;margin-left: 5px;font-family: system-ui;">Login/Signup</span>
                                </span>

                            </a>
                        </li>

                        <li class="d-flex" style="margin-right:5%;" *ngIf="appService.userLoggedIn">
                            <div class="dropdown" style="margin-left: 20%;">
                                <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    style="text-decoration: none !important;color: #41403f !important;font-family: system-ui;"><span
                                        class="lnr lnr-user" style="text-shadow: none;
                                                                font-family: 'proxima-regular','sans-serif'!important;
                                                                display: contents;
                                                                color: #4f4f4f!important;
                                                                text-align: center;
                                                                font-size: 13px;
                                                                font-weight: bold;">
                                        {{ appService.userName.substring(0,13) }}
                                    </span>
                                </a>
                                <div id="dropdown" class="dropdown-menu"
                                    style="background-color: white!important;min-width: 7rem;"
                                    aria-labelledby="dropdownMenuButton">
                                    <a class="logout-cutom dropdown-item" routerLink="my-account"
                                        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #41403f!important; font-size: 12px;font-weight: bold;padding: 3px 13%;">My
                                        Account</a>
                                    <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #41403f!important; font-size: 12px;font-weight: bold;padding: 3px 13%;">My
                                        Order</a>

                                    <a class="logout-cutom dropdown-item" (click)="logOut()"
                                        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #41403f!important; font-size: 12px;font-weight: bold;padding: 3px 13%;">Logout</a>
                                </div>
                            </div>
                        </li>
                        <!-- <li class="d-flex header-cart account-popup1" style="margin-right: 20%;
                                                margin-left: 20%;">
                                                    <a href="javascript:;"  style="text-decoration: none !important;color: #41403f !important;" (click)="search()">
                                                        <i class="fa fa-search" style="font-size: 15px;" aria-hidden="true"></i>
                                                    </a>
                                                    <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y: visible !important;margin-top: -30px;top: 13%; min-width: 30%;width: 30%;">
                                                        <div class="row" style="margin: 5px;">
                                                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                                <div *ngFor="let category of allCategories">
                                                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                                </div>                                      
                                                                            </div>
                                                                          </div>
                                                                        <input type="text" placeholder="Search here."
                                                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                                        <div *ngIf="preList.length != 0">
                                                                            <div class="autocomplete-items2">
                                                                                <div *ngFor="let preItem of preList">
                                                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="input-group-btn">
                                                                            <button class="btn btn-solid" >
                                                                                <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                            </form>
                                                            
                                                        </div>
                                                        
                                                
                                                    </div>
                                                </li> -->
                    </ul>

                </div>
                <div style=" border-top: 1px solid #d8f0fc; border-bottom: 1px solid #d8f0fc;width: 100%;">
                    <div class="inq-menu-st" style="width: 100%; display: flex;">
                        <nav class="navbar-expand-lg navbar-light" style="width: 93%;"><button type="button"
                                data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
                                aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"><span
                                    class="navbar-toggler-icon"></span><span>Menu</span></button>
                            <div id="navbarNav" class="collapse navbar-collapse" style="width: 100%;">
                                <ul class="navbar-nav"
                                    style="padding-right:0px;padding-left: 0px !important;width: 100%">
                                    <!-- <li class="nav-item" id="home" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                                href="#/"><p style="font-weight: 580;color:#504d4d;font-size: 14px;">HOME</p></a></li> -->
                                    <li class="header-cart account-popup1" id="shop"
                                        style="background-color: #ffffff;display: flex;padding-left: 5px; padding-top: 5px;padding-bottom: 5px;width: 10%;">
                                        <img src="../../../../../assets/images/taazah/box.png"
                                            style=" margin-top: 10%;height: 10px;width: 10px;">
                                        <a href="javascript:;" class="nav-link menu-text"
                                            style="width: max-content;padding-left: 3px !important;">
                                            <p
                                                style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;">
                                                Select Category</p>
                                        </a>
                                        <app-new-navbar [category]="allcategories"></app-new-navbar>
                                    </li>
                                    <ul class="navbar-nav"
                                        style="padding-right:0px;padding-left: 0px !important;width: 100%;justify-content: space-evenly;background-color: #d8f0fc; padding-top: 5px;padding-bottom: 5px;width: 90%;">
                                        <li class="header-cart account-popup1" id="shop"
                                            style="background-color: #d8f0fc;margin-left: 0.1rem !important;;margin-right: 0px;">
                                            <a href="javascript:;" class="nav-link menu-text"
                                                (click)="productsListLush('Dairy Products')"
                                                style="width: max-content;display: flex;">
                                                <p
                                                    style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;">
                                                    Dairy Products</p>
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;margin-top: -2px;"><polyline points="6 9 12 15 18 9"></polyline></svg> -->
                                            </a>
                                            <!-- <app-new-navbar [category]="plants"></app-new-navbar>    -->
                                            <app-new-navbar [category]="plants"></app-new-navbar>
                                        </li>
                                        <li class="header-cart account-popup1" id="shop"
                                            style="background-color: #d8f0fc;margin-right: 0px;">
                                            <a href="javascript:;" class="nav-link menu-text"
                                                (click)="productsListLush('Biscuits')"
                                                style="width: max-content;display: flex;">
                                                <p
                                                    style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;">
                                                    Biscuits</p>
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;margin-top: -2px;"><polyline points="6 9 12 15 18 9"></polyline></svg> -->
                                            </a>
                                        </li>
                                        <li class="header-cart account-popup1" id="shop"
                                            style="background-color: #d8f0fc;margin-left: 0px !important;margin-right: 0px;">
                                            <a href="javascript:;" class="nav-link menu-text"
                                                (click)="productsListLush('Carbonated Drink')"
                                                style="width: max-content;display: flex;">
                                                <p
                                                    style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;">
                                                    Carbonated Drink</p>
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;margin-top: -2px;"><polyline points="6 9 12 15 18 9"></polyline></svg> -->
                                            </a>
                                        </li>
                                        <li class="header-cart account-popup1" id="shop"
                                            style="background-color: #d8f0fc;margin-left: 0px !important;margin-right: 0px;">
                                            <a href="javascript:;" class="nav-link menu-text"
                                                (click)="productsListLush('Pooja Items')"
                                                style="width: max-content;display: flex;">
                                                <p
                                                    style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;">
                                                    Pooja Items</p>
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;margin-top: -2px;"><polyline points="6 9 12 15 18 9"></polyline></svg> -->
                                            </a>
                                        </li>
                                        <li class="header-cart account-popup1" id="shop"
                                            style="background-color: #d8f0fc;margin-left: 0px !important;margin-right: 0px;">
                                            <a href="javascript:;" class="nav-link menu-text"
                                                (click)="productsListLush('Chocolates')"
                                                style="width: max-content;display: flex;">
                                                <p
                                                    style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;">
                                                    Chocolates</p>
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;margin-top: -2px;"><polyline points="6 9 12 15 18 9"></polyline></svg> -->
                                            </a>
                                        </li>
                                        <li class="header-cart account-popup1" id="shop"
                                            style="background-color: #d8f0fc;margin-left: 0px !important;margin-right: 0px;">
                                            <a href="javascript:;" class="nav-link menu-text"
                                                (click)="productsListLush('Groceries')"
                                                style="width: max-content;display: flex;">
                                                <p
                                                    style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;">
                                                    Grocery</p>
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;margin-top: -2px;"><polyline points="6 9 12 15 18 9"></polyline></svg> -->
                                            </a>
                                        </li>
                                        <li class="header-cart account-popup1" id="shop"
                                            style="background-color: #d8f0fc;margin-left: 0px !important;margin-right: 0px;">
                                            <a href="javascript:;" class="nav-link menu-text"
                                                (click)="productsListLush('Fruits & Vegetables')"
                                                style="width: max-content;display: flex;">
                                                <p
                                                    style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;">
                                                    Fruits & Vegetables</p>
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;margin-top: -2px;"><polyline points="6 9 12 15 18 9"></polyline></svg> -->
                                            </a>
                                        </li>

                                        <li class="header-cart account-popup1" id="shop"
                                            style="background-color: #d8f0fc;margin-left: 0px !important;margin-right: 0px;">
                                            <a href="javascript:;" class="nav-link menu-text"
                                                (click)="productsListLush('Bath Soap & Wash')"
                                                style="width: max-content;display: flex;">
                                                <p
                                                    style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;">
                                                    Bath Soap & Wash</p>
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;margin-top: -2px;"><polyline points="6 9 12 15 18 9"></polyline></svg> -->
                                            </a>
                                        </li>
                                        <li class="header-cart account-popup1" id="shop"
                                            style="background-color: #d8f0fc;margin-left: 0px !important;margin-right: 0px;">
                                            <a href="javascript:;" class="nav-link menu-text"
                                                (click)="productsListLush('Shampoo')"
                                                style="width: max-content;display: flex;">
                                                <p
                                                    style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;">
                                                    Shampoo</p>
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;margin-top: -2px;"><polyline points="6 9 12 15 18 9"></polyline></svg> -->
                                            </a>
                                        </li>
                                        <li class="header-cart account-popup1" id="shop"
                                            style="background-color: #d8f0fc;margin-left: 0px !important;margin-right: 0px;">
                                            <a href="javascript:;" class="nav-link menu-text"
                                                (click)="productsListLush('Pharmacy')"
                                                style="width: max-content;display: flex;">
                                                <p
                                                    style="text-shadow: none;font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;">
                                                    Pharmacy</p>
                                                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;margin-top: -2px;"><polyline points="6 9 12 15 18 9"></polyline></svg> -->
                                            </a>
                                        </li>
                                    </ul>
                                    <!-- <li class="header-cart account-popup1" id="shop" style="text-shadow: none;margin-left: 2% !important">
                                                                                            <a href="javascript:;" class="nav-link menu-text" style="display: flex;"><p style="font-size: 12px;font-family: sans-serif !important; font-weight: 600;color:black; text-transform: none;">PAGES</p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;"><polyline points="6 9 12 15 18 9"></polyline></svg></a>
                                                                                            <app-new-navbar [category]="fruits"></app-new-navbar>
                                                                                            </li> -->

                                    <!-- <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                                                        <a href="javascript:;" class="nav-link menu-text"href="#/about-us" ><p style="font-weight: 580;color:#504d4d;font-size: 14px;white-space: nowrap;">ABOUT US</p></a>
                                                                        </li>
                                                                <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                                                    <a href="#/contact-us" class="nav-link menu-text"><p style="font-weight: 580;color:#504d4d;white-space: nowrap;font-size: 14px;">CONTACT US</p></a>
                                                                    </li>      -->
                                </ul>
                            </div>
                        </nav>
                        <div class="header-cart account-popup d-flex"
                            style=" width: 7%;position: relative;padding-left: 5px;padding-right: 5px; background-color: #386eb6;justify-content: center;">
                            <a href="javascript:;" routerLink="/cart"
                                style="text-decoration: none !important;color: #f3f2f2 !important;">
                                <span class="fa fa-shopping-cart" style="font-size:13px;margin-top: 40%;"> Cart
                                    {{appService.totalCartItems}}
                                    <!-- <span style="position: absolute;
                                                        background: #83c33a;
                                                        width: 17px;
                                                        height: 15px;
                                                        border-radius: 9px;
                                                        top: -6px;
                                                        left: 40%;" >
                                                       
                                                            <span style=" width: 100%;
                                                            color: #fff;
                                                            position: absolute;
                                                            font-size: 12px;
                                                            font-weight: bold;
                                                            text-align: center;">{{appService.totalCartItems}}</span>
                                                        </span> -->
                                </span>
                            </a>
                            <app-mini-cart></app-mini-cart>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </header>
</div>

<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;
                                    padding: 2vw;" *ngIf="configs.title === 'Taazah'">
    <header>
        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black"
                        (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <img src="../../../../../assets/images/taazah/taazahlogo.png" alt="Logo Image"
                        style="width: 34vw;
                                                height: 15vw;
                                                ;margin-left: 19vw;margin-right:6vw;object-fit: contain;cursor: pointer;" class="logo-imagekub custom-pointer" alt="Logo" [routerLink]="['/']">
                </li>
                <li class="d-flex header-cart account-popup1" style="margin-top: 4px;">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                                                margin-left: 1vw;
                                                                margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2"
                        style="height:auto;overflow-y: visible !important;">
                        <div class="row" style="margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li class="d-flex" style="    margin-left: 2vw;
                                                        margin-top: 2.5vw;margin-right: 2vw;"
                    *ngIf="!appService.userLoggedIn" (click)="logIn()">
                    <a href="javascript:;" style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:2vw"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4) }}...
                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                        </a>
                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;"
                            aria-labelledby="dropdownMenuButton">
                            <div id="dropdown" class="dropdown-menu show"
                                style=" transform: translate3d(-47px, -20px, 0px) !important;">
                                <a class="logout-cutom dropdown-item" routerLink="my-account"
                                    style="color: black !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: black !important;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: black !important;">Logout</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative; margin-top:3px;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                                                margin-top: 2vw;
                                                                margin-left: 1vw;">
                            <span style="position: absolute;
                                                                    background: #1b52a4;
                                                                    width: 5vw;
                                                                    height: 4.5vw;
                                                                    top: 0vw;
                                                                    border-radius: 2vw;">

                                <span
                                    style="color: #fff;
                                                                        position: absolute;
                                                                        margin-top: 2px;
                                                                        left: 0.3rem;
                                                                        font-size: 3vw;
                                                                        font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text"
                (click)="closeNavkub11()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="homelush()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    HOME</p>
            </a>

            <!-- <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">PRODUCTS &#8594;</p></a> -->
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Dairy Products')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Dairy & Bakery</p>
            </a>
            <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Indoor Plants')" style="width: max-content;"><p style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">INDOOR PLANTS</p></a>
                        <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Outdoor Plants')" style="width: max-content;"><p style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">OUTDOOR PLANTS</p></a>
                        <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Exotic Plants')" style="width: max-content;"><p style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">EXOTIC PLANTS</p></a>
                        <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Miniatures')" style="width: max-content;"><p style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">MINIATURES</p></a> -->
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Biscuits')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Biscuits</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Carbonated Drink')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Carbonated Drinks</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" style="width: max-content;"
                (click)="productsListLush('Pooja Items')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Pooja Items</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" style="width: max-content;"
                (click)="productsListLush('Chocolates')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Chocolates</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Groceries')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Grocery</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Fruits & Vegetables')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Fruits & Vegetables</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" style="width: max-content;"
                (click)="productsListLush('Bath Soap & Wash')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Bath Soap & Wash</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Shampoo')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Shampoo</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Pharmacy')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Pharmacy</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    ABOUT US</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    CONTACT US </p>
            </a>


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;SHOP </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="searchnav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>


        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text"
                (click)="closeNavkub1()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="homelush()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    HOME</p>
            </a>

            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Dairy Products')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Dairy Products</p>
            </a>
            <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Indoor Plants')" style="width: max-content;"><p style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">INDOOR PLANTS</p></a>
                                                <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Outdoor Plants')" style="width: max-content;"><p style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">OUTDOOR PLANTS</p></a>
                                                <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Exotic Plants')" style="width: max-content;"><p style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">EXOTIC PLANTS</p></a>
                                                <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Miniatures')" style="width: max-content;"><p style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">MINIATURES</p></a> -->
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Biscuits')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Biscuits</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Carbonated Drink')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Carbonated Drinks</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" style="width: max-content;"
                (click)="productsListLush('Pooja Items')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Pooja Items</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" style="width: max-content;"
                (click)="productsListLush('Chocolates')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Chocolates</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Groceries')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Grocery</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Fruits & Vegetables')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Fruits & Vegetables</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" style="width: max-content;"
                (click)="productsListLush('Bath Soap & Wash')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Bath Soap & Wash</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Shampoo')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Shampoo</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Pharmacy')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Pharmacy</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    ABOUT US</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    CONTACT US </p>
            </a>
        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>

            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">SHOP </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
        </div>
    </header>
</div>
<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'HOB'"
    style="background-color: rgb(129 130 134) !important;height: 40px;">
    <header>
        <div style="display: flex;justify-content: space-evenly; margin-left: 65%;">
            <div></div>
            <div style="width: 25%;">
                <div style="display: flex;justify-content: space-evenly;">
                    <span class="d-flex" style="margin-right: 5%;margin-top: 10px;margin-bottom: 10px;"
                        *ngIf="!appService.userLoggedIn" (click)="logIn()">
                        <a href="javascript:;" style="text-shadow: none;color: #000000!important;font-size: 10.5px;">
                            LOGIN / REGISTER
                        </a>
                    </span>

                    <span class="d-flex" style="margin-right:12%; margin-top: 6px;" *ngIf="appService.userLoggedIn">
                        <div class="dropdown">
                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                style="text-decoration: none !important;color: #41403f !important;font-family: system-ui;"><span
                                    class="lnr lnr-user" style="text-shadow: none;
                                                                        font-family: 'proxima-regular','sans-serif'!important;
                                                                        display: contents;
                                                                        color: #000000!important;
                                                                        text-align: center;
                                                                        font-size: 13px;
                                                                        font-weight: bold;">
                                    {{ appService.userName.substring(0,13) }}
                                </span>
                            </a>
                            <div id="dropdown" class="dropdown-menu" style="background-color: white!important;"
                                aria-labelledby="dropdownMenuButton">
                                <a class="logout-cutom dropdown-item" routerLink="my-account"
                                    style="color: #6b6b6a!important; font-size: 11px;font-weight: bold;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: #6b6b6a!important;font-size: 11px;font-weight: bold;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: #6b6b6a!important;font-size: 11px;font-weight: bold;cursor: pointer;">Logout</a>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="header-cart account-popup d-flex" style="position: relative;">
                <a href="javascript:;" routerLink="/cart"
                    style="text-decoration: none !important;color: #383737 !important; margin-top: 8%;">
                    <span class="fa fa-shopping-bag" style="font-size:14px;color: #383737;">
                        {{appService.totalCartItems}} / {{appService.cartProducts.results.reduce(add, 0) | number :
                        '1.2-2'}}.00
                    </span>
                </a>
                <app-mini-cart></app-mini-cart>
            </div>
        </div>

    </header>
</div>
<div class="container-fluid-header-sticky-header"
    *ngIf="configs.title === 'HOB' && routeurl!='/' && routeurl!='/?type=newArrival_hob'"
    style="width: 100%;height: 70px;background: linear-gradient(rgb(8, 8, 8), rgba(155, 154, 151, 0));position: absolute;z-index: 1;">
    <div class="row" style="display: inline-flex; justify-content: space-evenly; width: 100%;margin-right: 0px;margin-top: 15px;
                                    margin-left: 0px;">
        <div class="hob_logohead" style="cursor: pointer;" ng-reflect-router-link="/" [routerLink]="['/']">
            <img [src]="logoImage" alt="" class="logo_image_hob">
        </div>

        <div class="col-4">
            <div class="inq-menu-st row justify-content-center">
                <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                        data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation" class="navbar-toggler"><span
                            class="navbar-toggler-icon"></span><span>Menu</span></button>
                    <div id="navbarNav" class="collapse navbar-collapse">
                        <ul class="navbar-nav">
                            <li class="nav-item hob_head_item" id="home"
                                style="text-shadow: none;font-family: system-ui;"><a class="nav-link menu-text"
                                    ng-reflect-router-link="/" href="#/">
                                    <p style="color:white;font-size: 14px; ">HOME</p>
                                </a></li>
                            <li class="header-cart account-popup1 hob_head_item" id="shop" style="">
                                <a href="javascript:;" class="nav-link menu-text" (click)="toggleNew()">
                                    <p style="text-shadow: none;font-family: system-ui;color:white;font-size: 14px; ">
                                        NEW</p>
                                </a>
                            </li>
                            <li class="header-cart account-popup1 hob_head_item" id="shop"
                                style="text-shadow: none;font-family: system-ui;">
                                <a href="javascript:;" class="nav-link menu-text"
                                    (click)="productsListCat('Women\'s Woven')">
                                    <p style="color:white ;white-space: nowrap;font-size: 14px;">WOMEN</p>
                                </a>
                            </li>
                            <li class="header-cart account-popup1 hob_head_item" id="shop"
                                style="text-shadow: none;font-family: system-ui;">
                                <a (click)="productsListCat('Men\'s Woven')" class="nav-link menu-text"
                                    style="cursor: pointer;">
                                    <p style="color:white ;white-space: nowrap;font-size: 14px;">MEN</p>
                                </a>
                            </li>
                            <li class="header-cart account-popup1 hob_head_item" id="shop"
                                style="text-shadow: none;font-family: system-ui;">
                                <a (click)="productsList()" class="nav-link menu-text" style="cursor: pointer;">
                                    <p style="color:white ;white-space: nowrap;font-size: 14px;">SALE</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
        <div class="hob_logohead">
            <div class="search-bar-group" style="margin-right: 10px !important; width: 100%;">
                <form [formGroup]="searchForm" class="search-bar">
                    <div class="d-flex justify-content-between">


                        <!-- <p>Browse all <br><span>Categories</span></p> -->
                        <div class="input-group" style="border-bottom: 1px solid white">
                            <input type="text" placeholder="Search"
                                style="min-height: 42px; width: 70%;box-shadow: none;border:none;background-color: transparent !important;color: white;"
                                class="form-control search-form-input hob_search_input" formControlName="search_term"
                                (keyup)="getPreList()">
                            <div *ngIf="preList.length != 0">
                                <div class="autocomplete-items3">
                                    <div *ngFor="let preItem of preList">
                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                    </div>
                                </div>
                            </div>
                            <div class="input-group-btn" style="background-color: transparent;border-top-right-radius: 10%;
                                                                border-bottom-right-radius: 10%;">
                                <button class="btn btn-solid vimpro-searchbtn">
                                    <i class="fa fa-search" style="color: white;font-size: 12px;"></i>
                                </button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'PaulCaroline'">
    <header>
        <div style="background-color: rgb(129 130 134) !important;height: 40px;">
            <div style="display: flex;justify-content: space-evenly; margin-left: 65%;">
                <div></div>
                <div style="width: 25%;">
                    <div style="display: flex;justify-content: space-evenly;">
                        <span class="d-flex" style="margin-right: 5%;margin-top: 10px;margin-bottom: 10px;"
                            *ngIf="!appService.userLoggedIn" (click)="logIn()">
                            <a href="javascript:;"
                                style="text-shadow: none;color: #000000!important;font-size: 10.5px;">
                                LOGIN / REGISTER
                            </a>
                        </span>

                        <span class="d-flex" style="margin-right:12%; margin-top: 6px;" *ngIf="appService.userLoggedIn">
                            <div class="dropdown">
                                <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    style="text-decoration: none !important;color: #41403f !important;font-family: system-ui;"><span
                                        class="lnr lnr-user" style="text-shadow: none;
                                                                    font-family: 'proxima-regular','sans-serif'!important;
                                                                    display: contents;
                                                                    color: #000000!important;
                                                                    text-align: center;
                                                                    font-size: 13px;
                                                                    font-weight: bold;">
                                        {{ appService.userName.substring(0,13) }}
                                    </span>
                                </a>
                                <div id="dropdown" class="dropdown-menu" style="background-color: white!important;"
                                    aria-labelledby="dropdownMenuButton">
                                    <a class="logout-cutom dropdown-item" routerLink="my-account"
                                        style="color: #6b6b6a!important; font-size: 11px;font-weight: bold;">My
                                        Account</a>
                                    <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                        style="color: #6b6b6a!important;font-size: 11px;font-weight: bold;">My Order</a>

                                    <a class="logout-cutom dropdown-item" (click)="logOut()"
                                        style="color: #6b6b6a!important;font-size: 11px;font-weight: bold;cursor: pointer;">Logout</a>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="header-cart account-popup d-flex" style="position: relative;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: #383737 !important; margin-top: 8%;">
                        <span class="fa fa-shopping-bag" style="font-size:14px;color: #383737;">
                            {{appService.totalCartItems}} / {{appService.cartProducts.results.reduce(add, 0) | number :
                            '1.2-2'}}.00
                        </span>
                    </a>
                    <app-mini-cart></app-mini-cart>
                </div>
            </div>
        </div>
        <div style="width: 100%;height: 100px;background: black;">
            <div class="row"
                style="display: inline-flex; justify-content: space-between; width: 90%;margin-right: 0px;margin-top: 15px;margin-left: 5%;">
                <div class="hob_logo" style="cursor: pointer;" ng-reflect-router-link="/" [routerLink]="['/']">
                    <img [src]="logoImage" alt="" class="logo_image_hob">
                </div>

                <div class="col-4">
                    <div class="inq-menu-st row justify-content-center" style="margin-top: 4%;">
                        <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                                data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                                aria-label="Toggle navigation" class="navbar-toggler"><span
                                    class="navbar-toggler-icon"></span><span>Menu</span></button>
                            <div id="navbarNav" class="collapse navbar-collapse">
                                <ul class="navbar-nav">
                                    <li class="nav-item hob_head_item" id="home"
                                        style="text-shadow: none;font-family: system-ui;"><a class="nav-link menu-text"
                                            ng-reflect-router-link="/" href="#/">
                                            <p style="color:white;font-size: 14px; ">HOME</p>
                                        </a></li>
                                    <li class="header-cart account-popup1 hob_head_item" id="shop" style="">
                                        <a href="javascript:;" class="nav-link menu-text" (click)="toggleNew()">
                                            <p
                                                style="text-shadow: none;font-family: system-ui;color:white;font-size: 14px; ">
                                                NEW</p>
                                        </a>
                                    </li>
                                    <li class="header-cart account-popup1 hob_head_item" id="shop"
                                        style="text-shadow: none;font-family: system-ui;">
                                        <a href="javascript:;" class="nav-link menu-text"
                                            (click)="productsListkubendran('Women')">
                                            <p style="color:white ;white-space: nowrap;font-size: 14px;">WOMEN</p>
                                        </a>
                                    </li>
                                    <li class="header-cart account-popup1 hob_head_item" id="shop"
                                        style="text-shadow: none;font-family: system-ui;">
                                        <a (click)="productsListkubendran('Men')" class="nav-link menu-text"
                                            style="cursor: pointer;">
                                            <p style="color:white ;white-space: nowrap;font-size: 14px;">MEN</p>
                                        </a>
                                    </li>
                                    <li class="header-cart account-popup1 hob_head_item" id="shop"
                                        style="text-shadow: none;font-family: system-ui;">
                                        <a (click)="productsList()" class="nav-link menu-text" style="cursor: pointer;">
                                            <p style="color:white ;white-space: nowrap;font-size: 14px;">SALE</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <div class="paul_search">
                    <div class="search-bar-group" style="margin-right: 10px !important; width: 100%;">
                        <form [formGroup]="searchForm" class="search-bar">
                            <div class="d-flex justify-content-between">


                                <!-- <p>Browse all <br><span>Categories</span></p> -->
                                <div class="input-group" style="border-bottom: 1px solid white">
                                    <input type="text" placeholder="Search"
                                        style="min-height: 42px; width: 70%;box-shadow: none;border:none;background-color: transparent !important;color: white;"
                                        class="form-control search-form-input hob_search_input"
                                        formControlName="search_term" (keyup)="getPreList()">
                                    <div *ngIf="preList.length != 0">
                                        <div class="autocomplete-items3">
                                            <div *ngFor="let preItem of preList">
                                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group-btn" style="background-color: transparent;border-top-right-radius: 10%;
                                                                        border-bottom-right-radius: 10%;">
                                        <button class="btn btn-solid vimpro-searchbtn">
                                            <i class="fa fa-search" style="color: white;font-size: 12px;"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </header>
</div>

<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'SriSenbaga'"
    style="background-color: rgb(255, 255, 255) !important;">
    <header>
        <div style="display: flex;justify-content: space-between;">
            <div style="width: 30%;">
                <div style="display: flex;justify-content: space-evenly;">
                    <span style="font-size: 14px; color: #6b6b6a; margin-top: 13px;"><img
                            style="width: 16px;margin-top: -2px;"
                            src="../../../../../assets/images/shenbaga/qustion.jpg"> Can we help you?
                    </span>
                    <span style="font-size: 14px; color: #6b6b6a;margin-top: 13px;cursor: pointer;"
                        (click)="shenbagamlocation()"><i class="fa-map-marker fa"
                            style="margin-right: 5px;"></i>Bookshop Finder</span>
                </div>
            </div>
            <div style="width: 25%;">
                <div style="display: flex;justify-content: space-evenly;">
                    <span style="font-size: 14px; color: #6b6b6a; margin-top: 13px;cursor: pointer;"
                        (click)="myOrder()"><img style="width: 22px;"
                            src="../../../../../assets/images/shenbaga/box.jpg"> Track Your Order
                    </span>
                    <span class="d-flex" style="margin-right: 5%;margin-top: 13px;" *ngIf="!appService.userLoggedIn"
                        (click)="logIn()">
                        <a href="javascript:;" style="text-shadow: none;color: #6b6b6a!important;font-size: 14px;">
                            <span class="fa fa-user-o" style="font-size:14px;margin-right: 6px;">
                            </span>
                            Account
                        </a>
                    </span>

                    <span class="d-flex" style="margin-right:12%; margin-top: 10px;" *ngIf="appService.userLoggedIn">
                        <div class="dropdown">
                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                style="text-decoration: none !important;color: #41403f !important;font-family: system-ui;"><span
                                    class="lnr lnr-user" style="text-shadow: none;
                                                                        font-family: 'proxima-regular','sans-serif'!important;
                                                                        display: contents;
                                                                        color: #4f4f4f!important;
                                                                        text-align: center;
                                                                        font-size: 14px;
                                                                        font-weight: bold;">
                                    {{ appService.userName.substring(0,13) }}
                                </span>
                            </a>
                            <div id="dropdown" class="dropdown-menu" style="background-color: white!important;"
                                aria-labelledby="dropdownMenuButton">
                                <a class="logout-cutom dropdown-item" routerLink="my-account"
                                    style="color: #6b6b6a!important; font-size: 12px;font-weight: bold;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: #6b6b6a!important;font-size: 12px;font-weight: bold;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: #6b6b6a!important;font-size: 12px;font-weight: bold;cursor: pointer;">Logout</a>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div style="display: flex;margin-top: 10px; background-color: #f8ee12;">
            <div style="width: 25%; margin-left: 2%;">
                <img src="../../../../../assets/images/shenbaga/shenbaga logo.png" alt="" [routerLink]="['/']"
                    style="width: 100%;margin-top: 3%; cursor: pointer;margin-bottom: 20px;">
            </div>
            <div style="width: 83%; display: flex;justify-content: space-evenly;">
                <div style="margin-left: 5%;width: 60%;">
                    <div class="inq-menu-st row">
                        <nav class="navbar navbar-expand-lg navbar-light" style="width: 100%;"><button type="button"
                                data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
                                aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"><span
                                    class="navbar-toggler-icon"></span><span>Menu</span></button>
                            <div id="navbarNav" class="collapse navbar-collapse">
                                <ul class="navbar-nav"
                                    style="margin-top: 3px; padding: 3%;padding-left: 0px !important;width: 100%;justify-content: space-evenly;">
                                    <!-- <li class="nav-item" id="home" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                                    href="#/"><p style="font-weight: 580;color:#504d4d;font-size: 14px;">HOME</p></a></li> -->
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 0rem !important;margin-right: 0rem;">
                                        <a href="javascript:;" class="nav-link menu-text" [routerLink]="['/']"
                                            style="width: max-content;display: flex;">
                                            <p
                                                style="text-shadow: none;font-size: 13px;font-family: 'Roboto'!important;color:black">
                                                Home</p>
                                        </a>
                                        <!-- <app-new-navbar [category]="plants"></app-new-navbar>    -->
                                    </li>
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 0rem !important;margin-right: 0rem;">
                                        <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"
                                            style="width: max-content;">
                                            <p
                                                style="text-shadow: none;font-size: 13px;font-family: 'Roboto'!important;color:black">
                                                Books</p>
                                        </a>
                                    </li>
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 0rem !important;margin-right: 0rem;">
                                        <a href="javascript:;" class="nav-link menu-text"
                                            style="width: max-content;display: flex;">
                                            <p
                                                style="text-shadow: none;font-size: 13px;font-family: 'Roboto'!important;color:black">
                                                Category</p><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-chevron-down" style="color: black;">
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                            </svg>
                                        </a>
                                        <app-new-navbar [category]="allcategories"></app-new-navbar>
                                    </li>
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 0rem !important;margin-right: 0rem;">
                                        <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"
                                            style="width: max-content;">
                                            <p
                                                style="text-shadow: none;font-size: 13px;font-family: 'Roboto'!important;color:black">
                                                Authors</p>
                                        </a>
                                        <!-- <app-new-navbar [category]="fruits"></app-new-navbar>     -->
                                    </li>
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 0rem !important;margin-right: 0rem;">
                                        <a href="javascript:;" class="nav-link menu-text" style="width: max-content;"
                                            (click)="toggleSriBest()">
                                            <p
                                                style="text-shadow: none;font-size: 13px;font-family: 'Roboto'!important;color:black">
                                                Best Sellers</p>
                                        </a>
                                    </li>
                                    <li class="header-cart account-popup1" id="shop"
                                        style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0rem !important;margin-right: 0rem;">
                                        <a href="javascript:;" class="nav-link menu-text" style="display: flex;">
                                            <p
                                                style="text-shadow: none;font-size: 13px;font-family: 'Roboto'!important;color:black">
                                                PAGES</p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-chevron-down" style="color: black;">
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                            </svg>
                                        </a>
                                        <app-new-navbar [category]="fruits"></app-new-navbar>
                                    </li>
                                    <!-- <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                                                            <a href="javascript:;" class="nav-link menu-text"href="#/about-us" ><p style="font-weight: 580;color:#504d4d;font-size: 14px;white-space: nowrap;">ABOUT US</p></a>
                                                                            </li>
                                                                    <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                                                        <a href="#/contact-us" class="nav-link menu-text"><p style="font-weight: 580;color:#504d4d;white-space: nowrap;font-size: 14px;">CONTACT US</p></a>
                                                                        </li>      -->
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <ul class="d-flex" style="justify-content: space-evenly;width: 20%;">
                    <li class="d-flex header-cart account-popup1" style="margin-top: 15%;">
                        <a href="javascript:;"
                            style="text-decoration: none !important;color: #41403f !important;font-size: 13px;"
                            (click)="search()">
                            <i class="fa fa-search" style="font-size: 13px;" aria-hidden="true"></i> Search
                        </a>
                        <div class="min-cart-product-wrapper popup-content2 "
                            style="height:auto;overflow-y: visible !important;margin-top: -30px;top: 25%; min-width: 30%;width: 30%;">
                            <div class="row" style="margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="dropdown-menu autocomplete-items"
                                                style="width: 100%! important;">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"
                                                        style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="text" placeholder="Search here."
                                            class="form-control search-form-input" formControlName="search_term"
                                            (keyup)="getPreList()">
                                        <div *ngIf="preList.length != 0">
                                            <div class="autocomplete-items2">
                                                <div *ngFor="let preItem of preList">
                    <li class="" (click)="searchSenbaga(preItem)">{{preItem.name}}</li>
            </div>
        </div>
</div>
<div class="input-group-btn">
    <button class="btn btn-solid">
        <i class="fa fa-search" (click)="savePreList1()"></i>
    </button>
</div>
</div>
</form>

</div>


</div>
</li>
<li class="header-cart account-popup d-flex" style="position: relative;margin-top: 15%;">
    <a href="javascript:;" routerLink="/cart"
        style="text-decoration: none !important;color: #41403f !important; margin-top: -8%;">
        <span class="fa fa-shopping-basket" style="font-size:13px"> Cart
            <span style="position: absolute;
                                                            background: #21216d;
                                                            width: 15px;
                                                            height: 15px;
                                                            border-radius: 20px;
                                                            top: 15px;
                                                            right: 74%;">

                <span
                    style=" width: 100%;
                                                                color: #fff;
                                                                position: absolute;
                                                                font-size: 10px;
                                                                font-weight: bold;
                                                                text-align: center;margin-top: 10%;">{{appService.totalCartItems}}</span>
            </span>
        </span>
    </a>
    <app-mini-cart></app-mini-cart>
</li>
</ul>
</div>
</div>


</header>
</div>

<div class="container-fluid-header-sticky-header1" style="background-color:#f8ee12;height: 17vw;
                                        padding: 3vw;" *ngIf="configs.title === 'SriSenbaga'">
    <header>
        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black"
                        (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <img src="../../../../../assets/images/shenbaga/shenbaga logo.png" alt="Logo Image"
                        style="width: 39vw;
                                                    height: 17vw;
                                                    ;margin-left: 14vw;margin-right:6vw;object-fit: contain;cursor: pointer;" class="logo-imagekub custom-pointer" alt="Logo" [routerLink]="['/']">
                </li>
                <li class="d-flex header-cart account-popup1" style="margin-top: 4px;">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                                                    margin-left: 1vw;
                                                                    margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2"
                        style="height:auto;overflow-y: visible !important;">
                        <div class="row" style="margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li class="d-flex" style="    margin-left: 2vw;
                                                            margin-top: 2.5vw;margin-right: 2vw;"
                    *ngIf="!appService.userLoggedIn" (click)="logIn()">
                    <a href="javascript:;"
                        style="text-decoration: none !important;color: black !important; margin-left: 10px; margin-right: 5px;">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:2vw"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4) }}...
                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                        </a>
                        <div id="dropdown" class="dropdown-menu"
                            style="background-color: white !important;min-width: 4rem;"
                            aria-labelledby="dropdownMenuButton">
                            <div id="dropdown" class="dropdown-menu show"
                                style=" transform: translate3d(0px, -20px, 0px) !important; min-width: 7rem">
                                <a class="logout-cutom dropdown-item" routerLink="my-account"
                                    style="color: black !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: black !important;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: black !important;">Logout</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative; margin-top:3px;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                                                    margin-top: 2vw;
                                                                    margin-left: 1vw;">
                            <span style="position: absolute;
                                                                        background: #303571;
                                                                        width: 6vw;
                                                                        height: 6vw;
                                                                        top: 6vw;
                                                                        right: 2vw;
                                                                        border-radius: 4vw;">

                                <span
                                    style="color: #fff;
                                                                            position: absolute;
                                                                            margin-top: 2px;
                                                                            left: 0.5rem;
                                                                            top: 1vw;
                                                                            font-size: 3vw;
                                                                            font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#f8ee12">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: #303571;" class="nav-link menu-text"
                (click)="closeNavkub11()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="homelush()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#303571">
                    Home</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#303571">
                    Books</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" style="width: max-content;" (click)="toggleSriBest()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#303571">
                    Bestseller</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#303571">
                    Author</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#303571">
                    About Us</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#303571">
                    Contact Us </p>
            </a>


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;SHOP </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="searchnav" class="sidenav" style="background-color:#f8ee12">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>


        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#f8ee12">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: #303571;" class="nav-link menu-text"
                (click)="closeNavkub1()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="homelush()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#303571">
                    Home</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#303571">
                    Books</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" style="width: max-content;" (click)="toggleSriBestmob()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#303571">
                    Bestseller</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#303571">
                    Author</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#303571">
                    About Us</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#303571">
                    Contact Us </p>
            </a>


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>

            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">SHOP </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
        </div>
    </header>
</div>
<div class="container-fluid-header-sticky-header1" style="background-color:#2f6164;height: 17vw;
                                        padding: 3vw;" *ngIf="configs.title === 'HOB'">
    <header>
        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black"
                        (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <img [src]="logoImage" alt="Logo Image" style="width: 39vw;
                                            height: 17vw;
                                            ;margin-left: 14vw;margin-right:6vw;object-fit: contain;cursor: pointer;"
                        class="logo-imagekub custom-pointer" alt="Logo" [routerLink]="['/']">
                    <!-- <img src="../../../../../assets/images/hob/logo.png" alt="Logo Image" style="width: 39vw;
                                                    height: 17vw;
                                                    ;margin-left: 14vw;margin-right:6vw;object-fit: contain;cursor: pointer;"class="logo-imagekub custom-pointer" alt="Logo"
                                                            [routerLink]="['/']">  -->
                </li>
                <li class="d-flex header-cart account-popup1" style="margin-top: 4px;">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                                                    margin-left: 1vw;
                                                                    margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2"
                        style="height:auto;overflow-y: visible !important;">
                        <div class="row" style="margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li class="d-flex" style="    margin-left: 2vw;
                                                            margin-top: 2.5vw;margin-right: 2vw;"
                    *ngIf="!appService.userLoggedIn" (click)="logIn()">
                    <a href="javascript:;"
                        style="text-decoration: none !important;color: black !important; margin-left: 10px; margin-right: 5px;">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:2vw"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4) }}...
                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                        </a>
                        <div id="dropdown" class="dropdown-menu"
                            style="background-color: white !important;min-width: 4rem;"
                            aria-labelledby="dropdownMenuButton">
                            <div id="dropdown" class="dropdown-menu show"
                                style=" transform: translate3d(0px, -20px, 0px) !important; min-width: 7rem">
                                <a class="logout-cutom dropdown-item" routerLink="my-account"
                                    style="color: black !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: black !important;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: black !important;">Logout</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative; margin-top:3px;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                                                    margin-top: 2vw;
                                                                    margin-left: 1vw;">
                            <span style="position: absolute;
                                                                        background: black;
                                                                        width: 6vw;
                                                                        height: 6vw;
                                                                        top: -3vw;
                                                                        right: -3vw;
                                                                        border-radius: 4vw;">

                                <span
                                    style="color: white;
                                                                            position: absolute;
                                                                            margin-top: 2px;
                                                                            left: 0.5rem;
                                                                            top: 1vw;
                                                                            font-size: 3vw;
                                                                            font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#2f6164">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: #d5d6df !important;"
                class="nav-link menu-text" (click)="closeNavkub11()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="homelush()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Home</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="toggleNewmob()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    New</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" style="width: max-content;" (click)="productsLists()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Women</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Men</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Sale</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    About Us</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Contact Us </p>
            </a>


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;SHOP </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="searchnav" class="sidenav" style="background-color:#2f6164">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>


        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#2f6164">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: #d5d6df !important;"
                class="nav-link menu-text" (click)="closeNavkub1()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="homelush()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Home</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="toggleNewmob()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    New</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Women</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Men</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Sale</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    About Us</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Contact Us </p>
            </a>


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>

            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">SHOP </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
        </div>
    </header>
</div>
<div class="container-fluid-header-sticky-header1" style="background-color:#2f6164;height: 17vw;
                                        padding: 3vw;" *ngIf="configs.title === 'PaulCaroline'">
    <header>
        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black"
                        (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <img [src]="logoImage" alt="Logo Image" style="width: 39vw;
                                            height: 17vw;
                                            ;margin-left: 14vw;margin-right:6vw;object-fit: contain;cursor: pointer;"
                        class="logo-imagekub custom-pointer" alt="Logo" [routerLink]="['/']">
                    <!-- <img src="../../../../../assets/images/hob/logo.png" alt="Logo Image" style="width: 39vw;
                                                    height: 17vw;
                                                    ;margin-left: 14vw;margin-right:6vw;object-fit: contain;cursor: pointer;"class="logo-imagekub custom-pointer" alt="Logo"
                                                            [routerLink]="['/']">  -->
                </li>
                <li class="d-flex header-cart account-popup1" style="margin-top: 4px;">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                                                    margin-left: 1vw;
                                                                    margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2"
                        style="height:auto;overflow-y: visible !important;">
                        <div class="row" style="margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li class="d-flex" style="    margin-left: 2vw;
                                                            margin-top: 2.5vw;margin-right: 2vw;"
                    *ngIf="!appService.userLoggedIn" (click)="logIn()">
                    <a href="javascript:;"
                        style="text-decoration: none !important;color: black !important; margin-left: 10px; margin-right: 5px;">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:2vw"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4) }}...
                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                        </a>
                        <div id="dropdown" class="dropdown-menu"
                            style="background-color: white !important;min-width: 4rem;"
                            aria-labelledby="dropdownMenuButton">
                            <div id="dropdown" class="dropdown-menu show"
                                style=" transform: translate3d(0px, -20px, 0px) !important; min-width: 7rem">
                                <a class="logout-cutom dropdown-item" routerLink="my-account"
                                    style="color: black !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: black !important;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: black !important;">Logout</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative; margin-top:3px;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                                                    margin-top: 2vw;
                                                                    margin-left: 1vw;">
                            <span style="position: absolute;
                                                                        background: black;
                                                                        width: 6vw;
                                                                        height: 6vw;
                                                                        top: -3vw;
                                                                        right: -3vw;
                                                                        border-radius: 4vw;">

                                <span
                                    style="color: white;
                                                                            position: absolute;
                                                                            margin-top: 2px;
                                                                            left: 0.5rem;
                                                                            top: 1vw;
                                                                            font-size: 3vw;
                                                                            font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#2f6164">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: #d5d6df !important;"
                class="nav-link menu-text" (click)="closeNavkub11()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="homelush()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Home</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="toggleNewmob()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    New</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" style="width: max-content;" (click)="productsLists()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Women</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Men</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Sale</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    About Us</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Contact Us </p>
            </a>


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;SHOP </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="searchnav" class="sidenav" style="background-color:#2f6164">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>


        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#2f6164">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: #d5d6df !important;"
                class="nav-link menu-text" (click)="closeNavkub1()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="homelush()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Home</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="toggleNewmob()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    New</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Women</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Men</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Sale</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    About Us</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#d5d6df">
                    Contact Us </p>
            </a>


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>

            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">SHOP </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
        </div>
    </header>
</div>
<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'KatbookStore'"
    style="background-color: rgb(255, 255, 255) !important;">
    <header>
        <div style="display: flex;margin-top: 20px;">
            <div style="width: 15%; margin-left: 2%;">
                <img src="../../../../../assets/images/kbook/Screenshot 2024-03-27 145350.png" alt=""
                    [routerLink]="['/']"
                    style="width: 87%;margin-left: 12%;margin-top: 8px; cursor: pointer;margin-bottom: 20px;">
            </div>
            <div style="width: 78%;">
                <div
                    style=" margin-right: inherit;display: flex;justify-content: space-between;padding-top: 5px;width: 95%;margin-left: 4%;">
                    <div class="search-bar-group" style="margin-right: 10px !important; margin-top: 5px; width: 80%;">
                        <form [formGroup]="searchForm" class="search-bar">
                            <div class="d-flex justify-content-between">


                                <!-- <p>Browse all <br><span>Categories</span></p> -->
                                <div class="input-group">

                                    <input type="text" placeholder="Search"
                                        style="min-height: 37px; border: 1px solid #b8b8b8; width: 60%;font-size:12px; background-color: #f9f9f9;"
                                        class="k_searchinput" formControlName="search_term" (keyup)="getPreList()">
                                    <div *ngIf="preList.length != 0">
                                        <div class="autocomplete-items3">
                                            <div *ngFor="let preItem of preList">
                                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                            </div>
                                        </div>
                                    </div>
                                    <div id='vimpro_cate' class="input-group-prepend desktop-only">
                                        <button class="ksearch_drop dropdown-togglecat v_cate_top"
                                            style="min-height: 35px;" type="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"
                                            (click)="closePrelist()">Categories<svg xmlns="http://www.w3.org/2000/svg"
                                                width="16" height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" class="feather feather-chevron-down"
                                                style="color: black;">
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                            </svg></button>
                                        <div class="dropdown-menu autocomplete-items vim_dropdown" style="width: 27%">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item vimpro_drop1" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name |
                                                    titlecase}}</a>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="input-group-btn" style="background-color: #23a5ef;border-top-right-radius: 10%;
                                                            border-bottom-right-radius: 10%;">
                                        <button class="kbook-searchbtn" style="min-height: 35px;">
                                            <i class="fa fa-search" (click)="savePreList1()" style="color: white;"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <ul class="d-flex" style="justify-content: end;width: 30%;margin-top: 1%;">

                        <li class="header-cart account-popup d-flex" style="position: relative;">
                            <a href="javascript:;" routerLink="/cart"
                                style="text-decoration: none !important;color: #999999 !important;">
                                <span>
                                    <img src="../../../../../assets/images/kbook/bagicon.png" alt="" style="width:20%">
                                    <span style="position: absolute;
                                                            background: #0156ff;
                                                            width: 17px;
                                                            height: 17px;
                                                            border-radius: 9px;
                                                            top: -5px;
                                                            left: 10%;">

                                        <span
                                            style=" width: 100%;
                                                                color: #fff;
                                                                position: absolute;
                                                                font-size: 12px;
                                                                font-weight: bold;
                                                                text-align: center;">{{appService.totalCartItems}}</span>
                                    </span>
                                </span>
                            </a>
                            <app-mini-cart></app-mini-cart>
                        </li>
                        <li class="d-flex" style="margin-right: 25%; width: 100px;" *ngIf="!appService.userLoggedIn"
                            (click)="logIn()">
                            <a href="javascript:;" style="text-shadow: none;color: #999999!important;">
                                <span class="fa fa-user-o" style="font-size:23px;margin-right: 6px;padding-top: 4px;">
                                </span>

                            </a>
                        </li>

                        <li class="d-flex" style="margin-right:20%;width: 95px;" *ngIf="appService.userLoggedIn">
                            <div class="dropdown">
                                <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    style="text-decoration: none !important;color: #41403f !important;font-family: system-ui;"><span
                                        class="lnr lnr-user" style="text-shadow: none;
                                                                font-family: 'proxima-regular','sans-serif'!important;
                                                                display: contents;
                                                                color: #000000!important;
                                                                text-align: center;
                                                                font-size: 18px;
                                                                font-weight: bold;">
                                        {{ appService.userName.substring(0,6) }}
                                    </span>
                                </a>
                                <div id="dropdown" class="dropdown-menu" style="background-color: white!important;"
                                    aria-labelledby="dropdownMenuButton">
                                    <a class="logout-cutom dropdown-item" routerLink="myaccount-katbook"
                                        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #41403f!important;text-align: left; font-size: 12px;font-weight: bold;">My
                                        Account</a>
                                    <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #41403f!important;text-align: left; font-size: 12px;font-weight: bold;">My
                                        Order</a>

                                    <a class="logout-cutom dropdown-item" (click)="logOut()"
                                        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #41403f!important;text-align: left; font-size: 12px;font-weight: bold;">Logout</a>
                                </div>
                            </div>
                        </li>
                        <!-- <li class="d-flex header-cart account-popup1" style="margin-right: 20%;
                                                margin-left: 20%;">
                                                    <a href="javascript:;"  style="text-decoration: none !important;color: #41403f !important;" (click)="search()">
                                                        <i class="fa fa-search" style="font-size: 15px;" aria-hidden="true"></i>
                                                    </a>
                                                    <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y: visible !important;margin-top: -30px;top: 13%; min-width: 30%;width: 30%;">
                                                        <div class="row" style="margin: 5px;">
                                                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                                <div *ngFor="let category of allCategories">
                                                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                                </div>                                      
                                                                            </div>
                                                                          </div>
                                                                        <input type="text" placeholder="Search here."
                                                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                                        <div *ngIf="preList.length != 0">
                                                                            <div class="autocomplete-items2">
                                                                                <div *ngFor="let preItem of preList">
                                                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="input-group-btn">
                                                                            <button class="btn btn-solid" >
                                                                                <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                            </form>
                                                            
                                                        </div>
                                                        
                                                
                                                    </div>
                                                </li> -->

                    </ul>
                    <div style="margin-top: 1%;text-align: center;">
                        <select class="form-control-Kat" id="country" style="width: 130%;">
                            <!-- <select class="form-control" id="country" (change)="handleCountry($event)" formControlName="country" required></select> -->
                            <option value="">Outlet</option>
                            <!-- <option *ngFor="let country of countryList" [ngValue]="country">{{country.country}}</option> -->
                            <option *ngFor="let country of countryList" value="{{country}}">
                                {{country}}</option>
                        </select>
                    </div>

                </div>

            </div>
        </div>
        <div style="border-top: #c5c5c5 2px solid;border-bottom: #c5c5c5 2px solid;width: 100%;">
            <div style="width: 100%;display: flex;justify-content: center;">
                <div class="inq-menu-st row">
                    <nav class="navbar navbar-expand-lg navbar-light" style="width: 100%;"><button type="button"
                            data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
                            aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"><span
                                class="navbar-toggler-icon"></span><span>Menu</span></button>
                        <div id="navbarNav" class="collapse navbar-collapse">
                            <ul class="navbar-nav" style="padding:1px;padding-left: 0px !important;width: 100%;">
                                <!-- <li class="nav-item" id="home" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                            href="#/"><p style="font-weight: 580;color:#504d4d;font-size: 14px;">HOME</p></a></li> -->
                                <li class="header-cart account-popup1" id="shop"
                                    style="margin-left: 0.1rem !important;width: 20%;">
                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()"
                                        style="width: max-content;display: flex;">
                                        <p
                                            style="text-shadow: none;font-size: 16px;font-family: sans-serif !important; font-weight: 600;color:#5c5b5b;text-transform: none;">
                                            Katbook Store</p>
                                    </a>
                                    <!-- <app-new-navbar [category]="plants"></app-new-navbar>    -->
                                    <!-- <app-new-navbar [category]="plants"></app-new-navbar>    -->
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="margin-left: 0.1rem !important;width: 20%;">
                                    <a href="javascript:;" class="nav-link menu-text" style="width: max-content;">
                                        <p
                                            style="text-shadow: none;font-size: 16px;font-family: sans-serif !important; font-weight: 600;text-transform: none;color:#5c5b5b">
                                            Special Discounts</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="margin-left: 2% !important;width: 20%;">
                                    <a href="javascript:;" (click)="productsLists()" class="nav-link menu-text"
                                        style="width: max-content;">
                                        <p
                                            style="text-shadow: none;font-size: 16px;font-family: sans-serif!important; font-weight: 600;text-transform: none;color:#5c5b5b">
                                            Buy for Your Students</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="margin-left: 2% !important;width: 20%;">
                                    <a href="javascript:;" class="nav-link menu-text" style="width: max-content;">
                                        <p
                                            style="text-shadow: none;font-size: 16px;font-family: sans-serif !important; font-weight: 600;text-transform: none;color:#5c5b5b">
                                            Katbook Services</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="margin-left: 2% !important;width: 20%;">
                                    <a href="javascript:;" class="nav-link menu-text" style="width: max-content;">
                                        <p
                                            style="text-shadow: none;font-size: 16px;font-family: sans-serif !important; font-weight: 600;text-transform: none;color:#5c5b5b">
                                            KAM-Magazines</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="text-shadow: none;font-family: sans-serif !important;margin-left: 2% !important">
                                    <a href="javascript:;" class="nav-link menu-text" style="display: flex;">
                                        <p
                                            style="font-size: 16px; font-weight: 600;text-transform: none; color: #5c5b5b;">
                                            Pages</p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-chevron-down" style="color: black;">
                                            <polyline points="6 9 12 15 18 9"></polyline>
                                        </svg>
                                    </a>
                                    <app-new-navbar [category]="fruits"></app-new-navbar>
                                </li>
                                <!-- <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                                                    <a href="javascript:;" class="nav-link menu-text"href="#/about-us" ><p style="font-weight: 580;color:#504d4d;font-size: 14px;white-space: nowrap;">ABOUT US</p></a>
                                                                    </li>
                                                            <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                                                <a href="#/contact-us" class="nav-link menu-text"><p style="font-weight: 580;color:#504d4d;white-space: nowrap;font-size: 14px;">CONTACT US</p></a>
                                                                </li>      -->
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
</div>

<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;margin-bottom: 15px;
                                    padding: 2vw;" *ngIf="configs.title === 'KatbookStore'">
    <header>
        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black"
                        (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <img src="../../../../../assets/images/kbook/Screenshot 2024-03-27 145350.png" alt="Logo Image"
                        style="width: 43vw;
                                                height: 15vw;
                                                ;margin-left: 15vw;margin-right:2vw;object-fit: contain;cursor: pointer;"
                        class="logo-imagekub custom-pointer" alt="Logo" [routerLink]="['/']">
                </li>
                <li class="d-flex header-cart account-popup1" style="margin-top: 4px;">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                                                margin-left: 1vw;
                                                                margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2"
                        style="height:auto;overflow-y: visible !important;">
                        <div class="row" style="margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li class="d-flex" style="    margin-left: 4vw; width: 80px;
                                                        margin-top: 2.5vw;margin-right: 2vw;"
                    *ngIf="!appService.userLoggedIn" (click)="logIn()">
                    <a href="javascript:;" style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" style="margin-left:2vw;width: 80px;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:1vw"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4) }}...
                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                        </a>
                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;"
                            aria-labelledby="dropdownMenuButton">
                            <div id="dropdown" class="dropdown-menu show"
                                style=" transform: translate3d(-47px, -20px, 0px) !important;">
                                <a class="logout-cutom dropdown-item" routerLink="myaccount-katbook"
                                    style="color: black !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: black !important;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: black !important;">Logout</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative; margin-top:3px;"
                    *ngIf="!appService.userLoggedIn">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: black !important;">
                        <img src="../../../../../assets/images/kbook/bagicon.png" alt=""
                            style="width:54%;margin-top: 1vw;">
                        <span style="position: absolute;
                                                                   background: #0156ff;
                                                                   width: 5vw;
                                                                    height: 5vw;
                                                                    left: 15px;
                                                                    bottom: 27px;
                                                                    border-radius: 4vw;">
                            <span
                                style="color: #fff;
                                                                        position: absolute;
                                                                        margin-top: 2px;
                                                                        left: 0.3rem;
                                                                        font-size: 3vw;
                                                                        font-weight: bold;">{{appService.totalCartItems}}</span>
                        </span>

                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative; margin-top:3px;"
                    *ngIf="appService.userLoggedIn">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: black !important;">
                        <img src="../../../../../assets/images/kbook/bagicon.png" alt=""
                            style="width:68%;margin-top: 1vw;">
                        <span style="position: absolute;
                                                                   background: #0156ff;
                                                                   width: 5vw;
                                                                    height: 5vw;
                                                                    left: 15px;
                                                                    bottom: 27px;
                                                                    border-radius: 4vw;">
                            <span
                                style="color: #fff;
                                                                        position: absolute;
                                                                        margin-top: 2px;
                                                                        left: 0.3rem;
                                                                        font-size: 3vw;
                                                                        font-weight: bold;">{{appService.totalCartItems}}</span>
                        </span>

                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text"
                (click)="closeNavkub11()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="homelush()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Home</p>
            </a>

            <!-- <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">PRODUCTS &#8594;</p></a> -->
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Katbook Store</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Special Discounts</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Buy for your Students</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Kbook Services</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    KAM-Magazines</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    International Books</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" style="width: max-content;"
                (click)="routeTo()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    About Us</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" style="width: max-content;"
                (click)="routeTo()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Contact Us</p>
            </a>


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;SHOP </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="searchnav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>


        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text"
                (click)="closeNavkub1()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="homelush()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Home</p>
            </a>

            <!-- <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">PRODUCTS &#8594;</p></a> -->
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Katbook Store</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Special Discounts</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Buy for your Students</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Kbook Services</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    KAM-Magazines</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsLists()" style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    International Books</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" style="width: max-content;"
                (click)="routeTo()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    About Us</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" style="width: max-content;"
                (click)="routeTo()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    Contact Us</p>
            </a>


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>

            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">SHOP </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
        </div>
    </header>
</div>
<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'Vistas'"
    style="background-color: white !important;">
    <header>
        <div class="row" style="display: inline-flex; justify-content: normal; width: 100%;">
            <div class="col-1">

                <img src="../../../../../assets/images/vistas/Final Logo.jpg" alt="Logo Image"
                    style=" border: 1px black solid; padding: 8px; object-fit: cover;margin: 15px 0 0 35px; box-shadow: 0 2px 15px #0003, 0 5px 16px #00000030;"
                    class="logo-image custom-pointer" height="110" width="160" alt="Logo" [routerLink]="['/']">
            </div>
            <div class="col-4" style="margin-left: 28vw; margin-top: 26px;">
                <div class="inq-menu-st row justify-content-center">
                    <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation" class="navbar-toggler"><span
                                class="navbar-toggler-icon"></span><span>Menu</span></button>
                        <div id="navbarNav" class="collapse navbar-collapse">
                            <ul class="navbar-nav" style="padding:15px;">
                                <li class="nav-item" id="home"
                                    style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                    <a class="nav-link menu-text" ng-reflect-router-link="/" href="#/">
                                        <p style="font-weight: bold;color:black">HOME</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">
                                        <p
                                            style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important; font-weight: bold;color:black">
                                            PRODUCTS</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                    <a href="javascript:;" class="nav-link menu-text" href="#/about-us">
                                        <p style="font-weight: bold;color:black;white-space: nowrap;">ABOUT US</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                    <a href="#/contact-us" class="nav-link menu-text">
                                        <p style="font-weight: bold;color:black;white-space: nowrap;">CONTACT US</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="col-3 header-special" style="margin-top: 3.4%;margin-right:inherit;">

                <ul class="d-flex">
                    <li class="d-flex header-cart account-popup1" style="margin-right: 20%;
                                            margin-left: 20%;">
                        <a href="javascript:;" style="text-decoration: none !important;color: black !important;"
                            (click)="search()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </a>
                        <div class="min-cart-product-wrapper popup-content2"
                            style="height:auto;overflow-y: visible !important;margin-top: -30px;">
                            <div class="row" style="margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="dropdown-menu autocomplete-items"
                                                style="width: 100%! important;">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"
                                                        style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="text" placeholder="Search here."
                                            class="form-control search-form-input" formControlName="search_term"
                                            (keyup)="getPreList()">
                                        <div *ngIf="preList.length != 0">
                                            <div class="autocomplete-items2">
                                                <div *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
            </div>
        </div>
</div>
<div class="input-group-btn">
    <button class="btn btn-solid">
        <i class="fa fa-search" (click)="savePreList1()"></i>
    </button>
</div>
</div>
</form>

</div>


</div>
</li>

<li class="d-flex" style="margin-right: 25%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
    <a href="javascript:;"
        style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;display: contents;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">
        <span class="fa fa-user" style="font-size:25px;margin-right: 6px;color: #c49072;">
        </span> Login
    </a>
</li>

<li class="d-flex" style="margin-right:12%;" *ngIf="appService.userLoggedIn">
    <div class="dropdown">
        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style="text-decoration: none !important;color: #1d1b1a !important;"><span class="lnr lnr-user">
                {{ appService.userName.substring(0,6) }}
            </span>
        </a>
        <div id="dropdown" class="dropdown-menu" style="background-color: white!important;"
            aria-labelledby="dropdownMenuButton">
            <a class="logout-cutom dropdown-item" routerLink="my-account"
                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My
                Account</a>
            <a class="logout-cutom dropdown-item" routerLink="my-orders"
                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My
                Order</a>

            <a class="logout-cutom dropdown-item" (click)="logOut()"
                style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">Logout</a>
        </div>
    </div>
</li>

<li class="header-cart account-popup d-flex" style="position: relative;">
    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
        <span class="fa fa-shopping-basket" style="font-size:25px">
            <span style="position: absolute;
                                                        background: #FBCB0C;
                                                        width: 18px;
                                                        height: 18px;
                                                        border-radius: 9px;
                                                        top: -10px;
                                                        right: 9%;">

                <span style="  color: #fff;
                                                            position: absolute;
                                                            left: 0.2rem;
                                                            font-size: 14px;
                                                            font-weight: bold;">{{appService.totalCartItems}}</span>
            </span>
        </span>
        <!-- <span class="menu-title">
                                                        Cart
                                                    </span> -->
    </a>
    <app-mini-cart></app-mini-cart>
</li>
</ul>

</div>
</div>



</header>
</div>

<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 20vw;
                        padding: 2vw;" *ngIf="configs.title === 'Vistas'">
    <header>
        <div>
            <ul style="display:flex;gap: 0px;margin-top: 9px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black"
                        (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <img src="../../../../../assets/images/vistas/Final Logo.jpg" alt="Logo Image" style="width: 34vw;
                                    height: 17vw;
                                    ;margin-left: 19vw;margin-right:6vw;object-fit: contain;"
                        class="logo-imagekub custom-pointer" alt="Logo" [routerLink]="['/']">
                </li>
                <li class="d-flex header-cart account-popup1">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                                    margin-left: 1vw;
                                                    margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                                    <div class="row" style="margin: 5px;">
                                                        <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                            <div *ngFor="let category of allCategories">
                                                                                <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                            </div>                                      
                                                                        </div>
                                                                      </div>
                                                                </div>
                                                        </form>
                                                    </div>
                                                </div> -->
                </li>
                <li class="d-flex" style="    margin-left: 2vw;
                                            margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn"
                    (click)="logIn()">
                    <a href="javascript:;" style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:1vw"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4) }}...
                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                        </a>
                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;"
                            aria-labelledby="dropdownMenuButton">
                            <div id="dropdown" class="dropdown-menu show"
                                style=" transform: translate3d(-47px, -20px, 0px) !important;">
                                <a class="logout-cutom dropdown-item" routerLink="my-account"
                                    style="color: black !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: black !important;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: black !important;">Logout</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                                    margin-top: 2vw;
                                                    margin-left: 1vw;">
                            <span style="position: absolute;
                                                        background: #FBCB0C;
                                                        width: 5vw;
                                                        height: 4.5vw;
                                                        top: 0vw;
                                                        border-radius: 2vw;">

                                <span style="color: #fff;
                                                            position: absolute;
                                                            margin-top: 2px;
                                                            left: 0.4rem;
                                                            font-size: 3vw;
                                                            font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                        <!-- <span class="menu-title">
                                                        Cart
                                                    </span> -->
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text"
                (click)="closeNavkub11()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myfruits1','FRUITS')">
                <p style="color:black">HOME&#8594;</p>
            </a>

            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p style="color:black">PRODUCTS &#8594;</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p style="color:black">ABOUT US &#8594;</p>
            </a>
            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text"
                (click)="productsListkubendran2('myvegetables1','VEGETABLES')">
                <p style="color:black">CONTACT US &#8594;</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myothers1','OTHERS')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;PRODUCTS </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <!-- <div id="myothers1" class="sidenav">
                                    <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;OTHER DEPARTMENTS </span>
                                    
                                    <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                                
                                  </div> -->
        <div id="searchnav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>

        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closeNavkub1()">
                <p>&#8592;</p>
            </a>
            <a class="nav-link menu-text" ng-reflect-router-link="/" href="#/" (click)="closeNavkub1()">
                <p style="color:black">HOME</p>
            </a>

            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsList()">
                <p style="color:black;">PRODUCTS</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" (click)="routeTo()">
                <p style="color:black">ABOUT US</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" (click)="routeTo()">
                <p style="color:black">CONTACT US</p>
            </a>
            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myothers')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>

            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">PRODUCTS </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
        </div>
    </header>
</div>
<!-- <div class="earthy-worthy-section earthy-worthy-menu"> -->
<div class="container-fluid header sticky-header vimpro-header"
    *ngIf="configs.title != 'Aiema' && configs.title != 'Cle' && configs.title != 'LinenRaj' && configs.title != 'BestGifts' && configs.title != 'AvonSeals'  && configs.title != 'Schift Cosmetics' && configs.title != 'SpecialWires' && configs.title != 'Babys Pride' && configs.title != 'LushNGreen' && configs.title != 'Taazah' && configs.title !== 'KatbookStore' && configs.title != 'Vistas'  && configs.title != 'Accel' && configs.title != 'Deera' && configs.title != 'ShubhCards' && configs.title != 'SriSenbaga' && configs.title != 'HOB' && configs.title != 'PaulCaroline'">
    <header>
        <div class="inq-htop-st inq-row" id="banner" *ngIf="promotionBanner != '' && configs.title === 'Deera'">
            <div class="text-center">
                <p>
                    <span [innerHtml]="promotionBanner"></span>
                    <!-- <span>
                                <a href="#"><strong> More Details</strong></a>
                            </span> -->
                </p>
                <!-- <p>Get <strong>UPTO 40% OFF</strong> on your 1st order <span><a href="#"><strong> More Details</strong>
                                </a></span></p> -->
                <span style="cursor: pointer;"><i class="fa fa-close" (click)='hideBanner()'></i></span>
            </div>
        </div>
        <div class="flex-container" *ngIf="configs.title != 'BestGifts'&& configs.title != 'Kubendran'">
            <img [src]="logoImage" *ngIf="configs.title != 'Karupattiraja' && configs.title != 'Carience' "
                alt="Logo Image" class="logo-image custom-pointer mob-logo shubhcards-logo" height="50" alt="Logo"
                [routerLink]="['/']">
            <img [src]="logoImage" *ngIf="configs.title == 'Carience' " alt="Logo Image"
                class="logo-image custom-pointer mob-logo" height="80" alt="Logo" [routerLink]="['/']">
            <img [src]="logoImage" *ngIf="configs.title == 'Karupattiraja'" alt="Logo Image"
                class="logo-karupatti custom-pointer mob-logo" height="70" alt="Logo" style="margin-left:40px;"
                [routerLink]="['/']">

            <div class="header-row-one">
                <div class="search-bar-group" style="margin-right: 10px !important; margin-top: 5px;">
                    <form [formGroup]="searchForm" class="search-bar">
                        <div class="d-flex justify-content-between search-input" *ngIf="configs.title != 'ShubhCards'">


                            <!-- <p>Browse all <br><span>Categories</span></p> -->
                            <div class="input-group">
                                <div class="input-group-prepend" *ngIf="configs.title != 'Vimpro'">
                                    <button class="btn search-drop dropdown-toggle" type="button" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false" (click)="closePrelist()"
                                        style="font-size: 14px !important;">All Categories</button>
                                    <div class="dropdown-menu autocomplete-items">
                                        <div *ngFor="let category of allCategories">
                                            <a class="dropdown-item" (click)="navigateToList(category)"
                                                style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                        </div>
                                    </div>
                                </div>


                                <div id='vimpro_cate' class="input-group-prepend desktop-only"
                                    *ngIf="configs.title == 'Vimpro'" style="    position: fixed!important;">
                                    <button class="btn search-drop dropdown-toggle v_cate_top" style="min-height: 42px;"
                                        type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        (click)="closePrelist()">Top Categories</button>
                                    <div class="dropdown-menu autocomplete-items vim_dropdown"
                                        style="background-color: rgb(37 84 199);;width: 15rem!important">
                                        <div *ngFor="let category of allCategories">

                                            <a *ngIf="category.name.toLowerCase().includes('pp flute board')"
                                                class="dropdown-item vimpro_drop1" (click)="navigateToList(category)"
                                                style="cursor:pointer;font-size: 14px !important;color: white!important;">PP
                                                Flute Board</a>
                                            <a *ngIf="!category.name.toLowerCase().includes('pp flute board')"
                                                class="dropdown-item vimpro_drop1" (click)="navigateToList(category)"
                                                style="cursor:pointer;font-size: 14px !important;color: white!important;">{{category.name
                                                | titlecase}}</a>

                                        </div>

                                    </div>
                                </div>

                                <input type="text" placeholder="Search here.." *ngIf="configs.title !== 'Vimpro'"
                                    class="form-control search-form-input" formControlName="search_term"
                                    (keyup)="getPreList()">
                                <input type="text" placeholder="Search our catalogue.."
                                    *ngIf="configs.title === 'Vimpro'"
                                    style="border-radius: 50px 0px 0px 50px !important;"
                                    class="form-control search-form-input" formControlName="search_term"
                                    (keyup)="getPreList()">

                                <div *ngIf=" configs.title != 'Vimpro' && preList.length != 0">
                                    <div class="autocomplete-items1">
                                        <div *ngFor="let preItem of preList">
                                            <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf=" configs.title == 'Vimpro' && preList.length != 0">
                                    <div class="autocomplete-items3">
                                        <div *ngFor="let preItem of preList">
                                            <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group-btn">
                                    <button class="btn btn-solid vimpro-searchbtn">
                                        <i class="fa fa-search" (click)="savePreList1()"></i>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                    <ul class="account mobile-cart"
                        style="margin-top:-40px;margin-bottom:0px !important;margin-left:10px">
                        <li class="header-cart account-popup" style="margin-right:-28px;">
                            <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                <span class="fa fa-shopping-cart white_vimpro" style="font-size:25px;">
                                    <span class="count b-radius">
                                        <span class="count-number f-increse">{{appService.totalCartItems}}</span>
                                    </span>
                                </span>
                                <span class="menu-title white_vimpro">
                                    Cart
                                </span>
                            </a>
                            <!-- <h3 *ngIf=" configs.title == 'Vimpro'"class="sure_we_can">s</h3>  -->

                            <!-- <h3  *ngIf="configs.title ==='Vimpro'" class="sure_we">sure we can</h3> -->
                            <h3 *ngIf="configs.title ==='Vimpro'" class="sure_we"><span
                                    style="font-size:26px;font-family: cursive;">s</span>ure we can</h3>

                            <app-mini-cart></app-mini-cart>
                        </li>

                    </ul>
                </div>
                <div class="account-section">
                    <ul class="account">

                        <li *ngIf="!appService.userLoggedIn" (click)="logIn()" style="margin-right:10px !important">
                            <a *ngIf="configs.title!='Vimpro'" href="javascript:;">
                                <span class="fa fa-user" style="font-size:20px !important">
                                </span> Login
                            </a>
                            <div class="disktop-only">
                                <a *ngIf="configs.title=='Vimpro'" href="javascript:;" style="color: #ffffff!important">
                                    <span class="fa fa-user" style="color: #ffffff">
                                    </span> Login
                                </a>
                            </div>

                            <div class="mobile-only">
                                <a *ngIf="configs.title=='Vimpro'" href="javascript:;"
                                    style="color: #0025ab!important;top: 18px; position: absolute;font-size: 15px;">
                                    <span class="fa fa-user"
                                        style="font-size: 21px !important;color: #0025ab!important;">
                                    </span> Login
                                </a>
                            </div>
                        </li>



                        <li *ngIf="appService.userLoggedIn">
                            <div class="dropdown">
                                <a *ngIf="configs.title!='Vimpro'" class="menu-title dropdown-toggle " type="button"
                                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false"><span class="lnr lnr-user loginbut">
                                    </span>
                                    {{ appService.userName }}
                                </a>
                                <a *ngIf="configs.title=='Vimpro'" class="menu-title dropdown-toggle loginicon"
                                    type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false"><span class="lnr lnr-user loginbut">
                                    </span>
                                    {{ appService.userName }}
                                </a>
                                <div id="dropdown" class="dropdown-menu l-5px" aria-labelledby="dropdownMenuButton">
                                    <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                    <a *ngIf="configs.title !== 'BombayHardware'  && configs.title !== 'Schift Cosmetics' && configs.title !== 'AvonSeals' && configs.title != 'SpecialWires' && configs.title != 'Vistas' && configs.title != 'Cle'"
                                        class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a>
                                    <a *ngIf="configs.title === 'BombayHardware' || configs.title === 'Schift Cosmetics' || configs.title === 'AvonSeals'  || configs.title === 'SpecialWires' || configs.title === 'Vistas' || configs.title === 'Cle'"
                                        class="logout-cutom dropdown-item" routerLink="enquiry-status">My Enquiry</a>

                                    <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                </div>
                            </div>
                        </li>

                        <li class="header-cart account-popup desktop-only">
                            <a *ngIf="configs.title=='Vimpro'" href="javascript:;" routerLink="/cart"
                                style="text-decoration: none !important;color: white!important;">
                                <span class="fa fa-shopping-cart" style="font-size:25px">
                                    <span class="count">
                                        <span class="count-number">{{appService.totalCartItems}}</span>
                                    </span>
                                </span>
                                <span class="menu-title" style="color: white!important;">
                                    Cart
                                </span>
                            </a>
                            <a *ngIf="configs.title!='Vimpro'" href="javascript:;" routerLink="/cart"
                                style="text-decoration: none !important;">
                                <span class="fa fa-shopping-cart" style="font-size:25px">
                                    <span class="count">
                                        <span class="count-number">{{appService.totalCartItems}}</span>
                                    </span>
                                </span>
                                <span class="menu-title">
                                    Cart
                                </span>
                            </a>
                            <app-mini-cart></app-mini-cart>
                        </li>
                    </ul>
                    <div *ngIf="configs.title=='Vimpro'" (click)="submitEnquiryForVimpro()" class="reach_us_out"><i
                            class="fa fa-envelope" aria-hidden="true"></i><span>&nbsp;Reach Us Out Here</span></div>
                    <div *ngIf="configs.title=='Vimpro'" (click)="submitEnquiryForVimpro()" class="reach_us"><i
                            class="fa fa-envelope" aria-hidden="true"></i><span>&nbsp;Reach Us</span></div>
                </div>

            </div>

        </div>

    </header>
</div>
<div class="container-fluid header vimpro-header shubhcards-header"
    *ngIf="configs.title != 'Aiema' && configs.title != 'Cle' && configs.title != 'LinenRaj' && configs.title != 'BestGifts' && configs.title != 'AvonSeals'  && configs.title != 'Schift Cosmetics' && configs.title != 'SpecialWires' && configs.title != 'Babys Pride' && configs.title != 'LushNGreen' && configs.title != 'Taazah' && configs.title !== 'KatbookStore' && configs.title != 'Vistas'  && configs.title != 'Accel' && configs.title != 'Deera' && configs.title != 'Karupattiraja' && configs.title != 'SriSenbaga' && configs.title != 'HOB' && configs.title != 'PaulCaroline'">
    <header class="transparent-header">
        <div class="background-transparent">
            <!-- [class]="currentRating >= 1 ? 'fa fa-star checked': 'fa fa-star-o'" -->
            <div [class]="enablecolor == true ? 'flex-container': 'flex-container color-container'"
                *ngIf="configs.title != 'BestGifts'&& configs.title != 'Kubendran'">
                <img [src]="logoImage" *ngIf="configs.title != 'Karupattiraja' && configs.title != 'Carience' "
                    alt="Logo Image" class="logo-image custom-pointer mob-logo shubhcards-logo" height="50" alt="Logo"
                    [routerLink]="['/']">

                <div class="header-row-one">
                    <div class="search-bar-group" style="margin-right: 10px !important; margin-top: 5px;">
                        <form [formGroup]="searchForm" class="search-bar" *ngIf="configs.title != 'ShubhCards'">
                            <div class="d-flex justify-content-between search-input">



                                <div class="input-group">
                                    <div class="input-group-prepend" *ngIf="configs.title != 'Vimpro'">
                                        <button class="btn search-drop dropdown-toggle" type="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            (click)="closePrelist()" style="font-size: 14px !important;">All
                                            Categoriesgg</button>
                                        <div class="dropdown-menu autocomplete-items">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>


                                    <div id='vimpro_cate' class="input-group-prepend desktop-only"
                                        *ngIf="configs.title == 'Vimpro'" style="    position: fixed!important;">
                                        <button class="btn search-drop dropdown-toggle v_cate_top"
                                            style="min-height: 42px;" type="button" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" (click)="closePrelist()">Top
                                            Categories</button>
                                        <div class="dropdown-menu autocomplete-items vim_dropdown"
                                            style="background-color: rgb(37 84 199);;width: 15rem!important">
                                            <div *ngFor="let category of allCategories">

                                                <a *ngIf="category.name.toLowerCase().includes('pp flute board')"
                                                    class="dropdown-item vimpro_drop1"
                                                    (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;color: white!important;">PP
                                                    Flute Board</a>
                                                <a *ngIf="!category.name.toLowerCase().includes('pp flute board')"
                                                    class="dropdown-item vimpro_drop1"
                                                    (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;color: white!important;">{{category.name
                                                    | titlecase}}</a>

                                            </div>

                                        </div>
                                    </div>

                                    <input type="text" placeholder="Search here.." *ngIf="configs.title !== 'Vimpro'"
                                        class="form-control search-form-input" formControlName="search_term"
                                        (keyup)="getPreList()">
                                    <input type="text" placeholder="Search our catalogue.."
                                        *ngIf="configs.title === 'Vimpro'"
                                        style="border-radius: 50px 0px 0px 50px !important;"
                                        class="form-control search-form-input" formControlName="search_term"
                                        (keyup)="getPreList()">

                                    <div *ngIf=" configs.title != 'Vimpro' && preList.length != 0">
                                        <div class="autocomplete-items1">
                                            <div *ngFor="let preItem of preList">
                                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="input-group-btn">
                                        <button class="btn btn-solid vimpro-searchbtn">
                                            <i class="fa fa-search" (click)="savePreList1()"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                        <ul class="account mobile-cart"
                            style="margin-top:-40px;margin-bottom:0px !important;margin-left:10px">
                            <li class="header-cart account-popup" style="margin-right:-28px;">
                                <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                    <span class="fa fa-shopping-cart white_vimpro" style="font-size:25px;">
                                        <span class="count b-radius">
                                            <span class="count-number f-increse">{{appService.totalCartItems}}</span>
                                        </span>
                                    </span>
                                    <span class="menu-title white_vimpro">
                                        Cart
                                    </span>
                                </a>

                                <h3 *ngIf="configs.title ==='Vimpro'" class="sure_we"><span
                                        style="font-size:26px;font-family: cursive;">s</span>ure we can</h3>

                                <app-mini-cart></app-mini-cart>
                            </li>

                        </ul>
                    </div>
                    <div class="account-section">
                        <ul class="account">

                            <li *ngIf="!appService.userLoggedIn" (click)="logIn()" style="margin-right:10px !important">
                                <a *ngIf="configs.title!='Vimpro'" href="javascript:;">
                                    <span class="fa fa-user" style="font-size:20px !important">
                                    </span> Login
                                </a>
                                <div class="disktop-only">
                                    <a *ngIf="configs.title=='Vimpro'" href="javascript:;"
                                        style="color: #ffffff!important">
                                        <span class="fa fa-user" style="color: #ffffff">
                                        </span> Login
                                    </a>
                                </div>

                                <div class="mobile-only">
                                    <a *ngIf="configs.title=='Vimpro'" href="javascript:;"
                                        style="color: #0025ab!important;top: 18px; position: absolute;font-size: 15px;">
                                        <span class="fa fa-user"
                                            style="font-size: 21px !important;color: #0025ab!important;">
                                        </span> Login
                                    </a>
                                    <ul class="account mobile-cart" style="margin-left:10px">
                                        <li class="header-cart account-popup" style="margin-right:-28px;">
                                            <a href="javascript:;" routerLink="/cart"
                                                style="text-decoration: none !important;">
                                                <span class="fa fa-shopping-cart white_vimpro" style="font-size:25px;">
                                                    <span class="count b-radius">
                                                        <span
                                                            class="count-number f-increse">{{appService.totalCartItems}}</span>
                                                    </span>
                                                </span>
                                                <span class="menu-title white_vimpro">
                                                    Cart
                                                </span>
                                            </a>
                                            <!-- <h3 *ngIf=" configs.title == 'Vimpro'"class="sure_we_can">s</h3>  -->

                                            <!-- <h3  *ngIf="configs.title ==='Vimpro'" class="sure_we">sure we can</h3> -->
                                            <h3 *ngIf="configs.title ==='Vimpro'" class="sure_we"><span
                                                    style="font-size:26px;font-family: cursive;">s</span>ure we can</h3>

                                            <app-mini-cart></app-mini-cart>
                                        </li>

                                    </ul>
                                </div>
                            </li>





                            <li *ngIf="appService.userLoggedIn">
                                <div class="dropdown">
                                    <a *ngIf="configs.title!='Vimpro'" class="menu-title dropdown-toggle " type="button"
                                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"><span class="lnr lnr-user loginbut">
                                        </span>
                                        {{ appService.userName }}
                                    </a>
                                    <a *ngIf="configs.title=='Vimpro'" class="menu-title dropdown-toggle loginicon"
                                        type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false"><span class="lnr lnr-user loginbut">
                                        </span>
                                        {{ appService.userName }}
                                    </a>
                                    <div id="dropdown" class="dropdown-menu l-5px" aria-labelledby="dropdownMenuButton">
                                        <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                        <a *ngIf="configs.title !== 'BombayHardware'  && configs.title !== 'Schift Cosmetics' && configs.title !== 'AvonSeals' && configs.title != 'SpecialWires' && configs.title != 'Vistas' && configs.title != 'Cle'"
                                            class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a>
                                        <a *ngIf="configs.title === 'BombayHardware' || configs.title === 'Schift Cosmetics' || configs.title === 'AvonSeals'  || configs.title === 'SpecialWires' || configs.title === 'Vistas' || configs.title === 'Cle'"
                                            class="logout-cutom dropdown-item" routerLink="enquiry-status">My
                                            Enquiry</a>

                                        <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                    </div>
                                </div>
                            </li>

                            <li class="header-cart account-popup desktop-only">
                                <a *ngIf="configs.title=='Vimpro'" href="javascript:;" routerLink="/cart"
                                    style="text-decoration: none !important;color: white!important;">
                                    <span class="fa fa-shopping-cart" style="font-size:25px">
                                        <span class="count">
                                            <span class="count-number">{{appService.totalCartItems}}</span>
                                        </span>
                                    </span>
                                    <span class="menu-title" style="color: white!important;">
                                        Cart
                                    </span>
                                </a>
                                <a *ngIf="configs.title!='Vimpro'" href="javascript:;" routerLink="/cart"
                                    style="text-decoration: none !important;">
                                    <span class="fa fa-shopping-cart" style="font-size:25px">
                                        <span class="count">
                                            <span class="count-number">{{appService.totalCartItems}}</span>
                                        </span>
                                    </span>
                                    <span class="menu-title">
                                        Cart
                                    </span>
                                </a>
                                <app-mini-cart></app-mini-cart>
                            </li>
                        </ul>
                        <div *ngIf="configs.title=='Vimpro'" (click)="submitEnquiryForVimpro()" class="reach_us_out"><i
                                class="fa fa-envelope" aria-hidden="true"></i><span>&nbsp;Reach Us Out Here</span></div>
                        <div *ngIf="configs.title=='Vimpro'" (click)="submitEnquiryForVimpro()" class="reach_us"><i
                                class="fa fa-envelope" aria-hidden="true"></i><span>&nbsp;Reach Us</span></div>
                    </div>

                </div>

            </div>
            <div class="container-fluid navbar-row vimpro-nav shubhcards-nav"
                *ngIf="configs.title != 'Aiema' && configs.title != 'Schift Cosmetics' && configs.title != 'Cle' && configs.title != 'LinenRaj' && configs.title != 'BestGifts' && configs.title != 'Kubendran' && configs.title != 'SpecialWires' && configs.title != 'Babys Pride' && configs.title != 'LushNGreen' && configs.title != 'Taazah' && configs.title !== 'KatbookStore' && configs.title != 'Vistas' && configs.title != 'SriSenbaga' && configs.title != 'HOB' && configs.title != 'PaulCaroline'">
                <app-menu></app-menu>
            </div>
        </div>
    </header>
</div>
<section style="position: relative;" *ngIf="configs.title == 'Accel'">

    <div class="container-fluid accel_top_banner" style="position: inherit;" *ngIf="configs.title === 'Accel'">
        <!-- Top Alert Box Banner -->
        <div class="alert alert-warning alert-banner" style="position: static;">
            <strong>Join Accel Limited’s Apprenticeship Program for Freshers.</strong> Contact us for more information
            <button type="button" class="close" data-dismiss="alert">&times;</button>
        </div>
    </div>
    <div class="container-fluid custom-container" style="border-bottom: 1px solid #e8e8e8;"
        *ngIf="configs.title === 'Accel'">
        <!-- Top Alert Box Banner -->
        <div class="container second_header">
            <div class="sec_header">
                <strong class="sec_header">To be a leader in customer service through excellence in people, process and
                    technologies.</strong>
            </div>

            <div class="col-lg-6 col-sm-12 text-left align_sec_header">
                <nav>
                    <ul style="display: flex;margin-top: 10px;justify-content: end;">

                        <!-- <li><a class="navUser-action" href="/giftcertificates.php"  style="white-space: nowrap"><i class="fa fa-gift"></i> Gift Certificates</a></li> -->
                        <li class="d-flex navUser-item d-none d-sm-block" (click)="logIn()" style="white-space: nowrap">
                            <a href="javascript:;"
                                style="text-decoration: none !important;white-space: nowrap;color: #2d2d2d!important;font-size: 13px;font-family: Raleway,Arial,Helvetica,sans-serif;margin-top: 5px;">
                                <span class="fa fa-user" style="font-size:13px">
                                </span> My Account
                            </a>
                        </li>
                        <li *ngIf="!appService.userLoggedIn"><a class="navUser-action" (click)="logIn()"
                                style="white-space: nowrap;color: #2d2d2d!important;font-size: 13px;font-family: Raleway,Arial,Helvetica,sans-serif;margin-left: 10%;margin-top: 10px;cursor: pointer;"><i
                                    class="fa fa-lock"></i> Sign in</a></li>

                        <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 50px;">
                            <div class="dropdown">
                                <a class="menu-title dropdown-toggle profile_name" type="button" #dropdownMenuButton
                                    id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false"
                                    style="text-decoration: none !important;color: #3C57A6 !important;"
                                    (click)="openacceldropdown()"><span class="lnr lnr-user">
                                    </span>
                                    {{ appService.userName}}
                                </a>
                                <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton"
                                    class="dropdown-menu l-5px">
                                    <!-- <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #3C57A6 !important;">My Account</a> -->
                                    <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                        style="color: #3C57A6 !important;">My Order</a>

                                    <a class="logout-cutom dropdown-item" (click)="logOut()"
                                        style="color: #3C57A6 !important;">Logout</a>
                                </div>
                            </div>
                        </li>




                    </ul>
                </nav>

            </div>

        </div>
    </div>
    <div class="container-fluid header sticky-header accel-header">
        <header>

            <div class="flex-container in_container">
                <img src=".../../../../../../../assets/images/accel/accellogo.png" alt="Logo Image"
                    class="logo-image custom-pointer mob-logo" height="50" alt="Logo" [routerLink]="['/']">


                <div class="header-row-one header_search">
                    <div class="search-bar-group desktop-only" style="margin-right: 10px !important; margin-top: 5px;">
                        <form [formGroup]="searchForm" class="search-bar">
                            <div class="d-flex justify-content-between search-input accel_search">



                                <div class="input-group rounded-input-group">
                                    <input type="text" placeholder="Search here.."
                                        class="form-control search-form-input sch_bg" formControlName="search_term"
                                        (keyup)="getPreList()">


                                    <div *ngIf="preList.length != 0">
                                        <div class="autocomplete-items3">
                                            <div *ngFor="let preItem of preList">
                                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="input-group-btn">
                                        <button class="btn btn-solid vimpro-searchbtn">
                                            <i class="fa fa-search" (click)="savePreList1()"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </form>
                        <ul class="account mobile-cart"
                            style="margin-top:-40px;margin-bottom:0px !important;margin-left:10px">
                            <li class="header-cart account-popup" style="margin-right:-28px;">
                                <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                    <span class="fa fa-shopping-cart white_vimpro" style="font-size:25px;">
                                        <span class="count b-radius">
                                            <span class="count-number f-increse">{{appService.totalCartItems}}</span>
                                        </span>
                                    </span>
                                    <span class="menu-title white_vimpro">
                                        Cart
                                    </span>
                                </a>


                                <app-mini-cart></app-mini-cart>
                            </li>
                        </ul>
                    </div>
                    <div class="account-section" style="margin-right: -8%;">
                        <ul class="account">

                            <img [src]="logoImage" alt="Logo Image"
                                class="logo-image custom-pointer mob-logo desktop-only" height="50" alt="Logo"
                                [routerLink]="['/']">
                            <div style="display: grid;">
                                <p style="display: flex;"><strong class="desktop-only" style="font-size: 13px;">Call us
                                        now :</strong><strong class="mobile-only" style="font-size: 11px;">Call us now
                                        :</strong>
                                    <span class="mobile-only" style="font-size: 11px;">+91-44-2822 2263/ +91 73050
                                        82265</span>

                                    <span class="desktop-only" style="font-size: 13px;">+91-44-2822 2263/+91 73050
                                        82265</span>
                                </p>
                                <p> <strong style="font-size: 13px;">Email :</strong><span style="font-size: 13px;">
                                        info@accelacademy.in </span>

                                </p>

                            </div>





                        </ul>


                        <!-- <div *ngIf="configs.title=='Vimpro'" (click)="submitEnquiryForVimpro()" class="reach_us"><i class="fa fa-envelope" aria-hidden="true"></i><span>&nbsp;Reach Us</span></div> -->
                    </div>

                </div>

            </div>

        </header>
    </div>
    <div class="container-fluid-header-sticky-header thirdheader" *ngIf="configs.title === 'Accel'">
        <header>
            <div class="row" style="width: 100%;">
                <div class="col-1"></div>

                <div class="col-1">
                    <div class="input-group">
                        <div id="topnav">
                            <ul id="nav">

                                <li class="all_cate">

                                    <a class="nav-hgt"
                                        style="color:white!important;padding-top: 10px;text-align: left;padding-left: 10px;cursor: pointer;">
                                        <i class="fa fa-bars" aria-hidden="true"></i>&nbsp; CATEGORIES
                                        &nbsp;&nbsp;&nbsp;&nbsp; <i class="fa fa-caret-down" aria-hidden="true"
                                            style="padding-left: 28px"></i></a>
                                    <ul class="submenu" style="position: absolute;left: 0px;top: 60px; z-index: 32;">

                                        <li>
                                            <a (click)="productList('Long Term Courses')" style="cursor: pointer;"
                                                class="accel-header-hover">Long Term Courses &#8658; </a>
                                            <ul>
                                                <li><a style="cursor: pointer;line-height: 1.5"
                                                        class="accel-header-hover"
                                                        (click)="sendProductDetails1('Diploma in Computer Hardware and Networking')">Diploma
                                                        in Computer Hardware and Networking</a></li>
                                                <li><a style="cursor: pointer;" class="accel-header-hover"
                                                        (click)="sendProductDetails1('PG Program In Cloud Engineering')">PG
                                                        Program In Cloud Engineering</a></li>
                                                <li><a style="cursor: pointer;" class="accel-header-hover"
                                                        (click)="sendProductDetails1('PG Program In Cyber Security')">PG
                                                        Program In Cyber Security</a></li>
                                                <li><a style="cursor: pointer;line-height: 1.5"
                                                        class="accel-header-hover"
                                                        (click)="sendProductDetails1('Post Graduate Diploma in IT Infrastructure Management Services')">PG
                                                        Diploma in IT Infrastructure Management Services</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a (click)="productList('Short Term Courses')" style="cursor: pointer;"
                                                class="accel-header-hover">Short Term Courses &#8658; </a>
                                            <ul>
                                                <li><a style="cursor: pointer;" class="accel-header-hover"
                                                        (click)="sendProductDetails1('Certificate Program in AWS')">Certificate
                                                        Program in AWS</a></li>
                                                <li><a style="cursor: pointer;" class="accel-header-hover"
                                                        (click)="sendProductDetails1('Certificate Program In Linux Server Administration')">Certificate
                                                        Program In Linux Server Administration</a></li>
                                                <li><a style="cursor: pointer;" class="accel-header-hover"
                                                        (click)="sendProductDetails1('Certificate Program In Soft Skills')">Certificate
                                                        Program In Soft Skills</a></li>
                                                <li><a style="cursor: pointer;" class="accel-header-hover"
                                                        (click)="sendProductDetails1('Certificate Program In Windows Server Administration')">Certificate
                                                        Program In Windows Server Administration</a></li>
                                            </ul>
                                        </li>

                                    </ul>

                                </li>
                            </ul>
                        </div>
                        <!-- <div class="input-group-prepend dropdown">
                                    <button class="btn search-drop dropdown-toggle cate_bt" type="button"   id="nav"   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" style="font-size: 14px !important;">All Categories</button>
                                    <div class="dropdown-menu autocomplete-items accel_dropdown" style="width: 12.5rem!important;">
                                        <div *ngFor="let category of allCategories">
                                            <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                        </div>                                      
                                    </div>
                                  </div> -->







                    </div>


                </div>
                <div class="col-4" style="margin-left:15vw">
                    <div class="inq-menu-st row justify-content-center">
                        <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                                data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                                aria-label="Toggle navigation" class="navbar-toggler"><span
                                    class="navbar-toggler-icon"></span><span>Menu</span></button>
                            <div id="navbarNav" class="collapse navbar-collapse">
                                <ul class="navbar-nav" style="padding:10px;">
                                    <li class="nav-item" id="home" style="margin-left: 0.1rem !important"><a
                                            class="nav-link menu-text" ng-reflect-router-link="/" href="#/">
                                            <p>HOME</p>
                                        </a></li>
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 0.1rem !important">
                                        <a href="javascript:;" class="nav-link menu-text"
                                            (click)="productsListkubendran('')">
                                            <p>COURSES</p>
                                        </a>
                                        <app-new-navbarkub [category]="Courses"></app-new-navbarkub>
                                    </li>
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 0.1rem !important">
                                        <a href="javascript:;" class="nav-link menu-text" style="white-space: nowrap;">
                                            <p>ALUMNI SPEAK</p>
                                        </a>
                                        <!-- <app-new-navbarkub [category]="vegetables"></app-new-navbarkub> -->
                                    </li>
                                    <!-- <li class="header-cart account-popup1" id="shop">
                                                <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                                 <app-new-navbar></app-new-navbar>
                                                </li> -->
                                    <!-- <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsListkubendran('OTHERS')"><p>OTHER DEPARTMENTS</p></a>
                                                     <app-new-navbarkub [category]="others"></app-new-navbarkub>
                                                    </li> -->
                                    <li class="header-cart account-popup1" id="shop"
                                        style="margin-left: 0.1rem !important">
                                        <a href="#/about-us" class="nav-link menu-text">
                                            <p>PAGES</p>
                                        </a>
                                        <app-new-navbarkub [category]="pages"></app-new-navbarkub>
                                    </li>


                                    <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                    href="#/contact-us">CONTACT US</a></li> -->

                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <div class="col-3" style="margin-top: 1%">

                    <ul class="d-flex">
                        <li class="d-flex header-cart account-popup1" style="margin-right: 18%;
                                    margin-left: 20%;">


                        </li>

                        <!-- <li>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search">
                                        <div class="input-group-append">
                                          <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                            <i class="fa fa-search"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </li> -->




                        <!--     
                                    <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #4f4f4f !important;">
                                            <span class="fa fa-shopping-basket" style="font-size:25px">
                                                <span style="position: absolute;
                                                background: #FBCB0C;
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;" >
                                               
                                                    <span style="  color: #4f4f4f;
                                                    position: absolute;
                                                    left: 0.2rem;
                                                    font-size: 14px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                                </span>
                                            </span>  
                                             
                                        </a>
                                        <app-mini-cart></app-mini-cart>
                                    </li> -->
                    </ul>

                </div>
            </div>




        </header>
    </div>

    <!-- start -->
    <div class="header-row-one mobile-only" *ngIf="configs.title === 'Accel'">
        <div class="search-bar-group" style="margin-right: 10px !important; margin-top: 5px;">
            <form [formGroup]="searchForm" class="search-bar">
                <div class="d-flex justify-content-between search-input">


                    <!-- <p>Browse all <br><span>Categories</span></p> -->
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button class="btn search-drop dropdown-toggle" type="button" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false" (click)="closePrelist()"
                                style="font-size: 14px !important;">All Categories</button>
                            <div class="dropdown-menu autocomplete-items" id="dropdown">
                                <div *ngFor="let category of allCategories">
                                    <a class="dropdown-item" (click)="navigateToList(category)"
                                        style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                </div>
                            </div>
                        </div>




                        <input type="text" placeholder="Search here.." class="form-control search-form-input"
                            formControlName="search_term" (keyup)="getPreList()">


                        <div *ngIf="preList.length != 0">
                            <div class="autocomplete-items1">
                                <div *ngFor="let preItem of preList">
                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                </div>
                            </div>
                        </div>

                        <div class="input-group-btn">
                            <button class="btn btn-solid vimpro-searchbtn"
                                style="border: 1px solid;background: #00377c;color: white;">
                                <i class="fa fa-search" (click)="savePreList1()"></i>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
            <ul class="account mobile-cart" style="margin-top:-40px;margin-bottom:0px !important;margin-left:10px">
                <li class="header-cart account-popup">
                    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                        <span class="fa fa-shopping-cart white_vimpro" style="font-size:25px;color: #00377c;">
                            <span class="count b-radius">
                                <span class="count-number f-increse">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                        <span class="menu-title white_vimpro" style="color: #00377c;">
                            Cart
                        </span>
                    </a>



                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>
        </div>
        <div class="account-section">
            <ul class="account">




                <li *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle " type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                                class="lnr lnr-user loginbut">
                            </span>
                            {{ appService.userName }}
                        </a>

                        <div id="dropdown" class="dropdown-menu l-5px" aria-labelledby="dropdownMenuButton">
                            <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                            <a class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a>

                            <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                        </div>
                    </div>
                </li>


            </ul>


        </div>

    </div>

    <!-- end -->


</section>
<div class="container-fluid navbar-row vimpro-nav shubhcards-nav"
    *ngIf="configs.title != 'Aiema' && configs.title != 'Schift Cosmetics' && configs.title != 'Cle' && configs.title != 'LinenRaj' && configs.title != 'BestGifts' && configs.title != 'Kubendran' && configs.title != 'SpecialWires' && configs.title != 'Babys Pride' && configs.title != 'LushNGreen' && configs.title != 'Taazah' && configs.title !== 'KatbookStore' && configs.title != 'Vistas' && configs.title != 'ShubhCards' && configs.title != 'SriSenbaga' && configs.title != 'HOB' && configs.title != 'PaulCaroline'">
    <app-menu></app-menu>
</div>
<!-- </div> -->
<div *ngIf="configs.title === 'Aiema'">
    <div class="earthy-worthy-section earthy-worthy-menu">
        <div class="container-fluid header sticky-header">
            <header>
                <div class="flex-container">
                    <img [src]="logoImage" alt="Logo Image" class="custom-pointer aiema-logo" height="50" alt="Logo"
                        style="width: 72px!important; height: 63px!important;" [routerLink]="['/']">
                    <div class="header-row-one" style="width: 100%;">
                        <div class="search-bar-group"
                            style="margin-right: 10px !important;display:contents;z-index:10; width:100%;margin-top: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style=" width:100%;margin-top:15px">
                                <div class="d-flex justify-content-between search-input-aiema"
                                    style="margin-right:25px;">
                                    <!-- <p>Browse all <br><span>Categories</span></p> -->
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <button class="btn search-drop dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                (click)="closePrelist()" style="font-size: 14px !important;">All
                                                Categories</button>
                                            <div class="dropdown-menu autocomplete-items">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"
                                                        style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="text" placeholder="Search here.."
                                            class="form-control search-form-input" formControlName="search_term"
                                            (keyup)="getPreList()">
                                        <div *ngIf="preList.length != 0">
                                            <div class="autocomplete-items1">
                                                <div *ngFor="let preItem of preList">
                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-btn">
                                            <button class="btn btn-solid">
                                                <i class="fa fa-search" (click)="savePreList1()"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="account-section">
                            <ul class="account" style="margin-right:0px">
                                <li *ngIf="!appService.userLoggedIn" style="margin-left:20px !important">
                                    <div class="dropdown">
                                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton1"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            style="text-decoration: none !important;font-size: 14px;font-weight: 500;"><span
                                                class="fa fa-user" style="font-size:25px; color: rgb(102, 102, 102);">
                                            </span>
                                            User/Member Login
                                        </a>
                                        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <a class="logout-cutom dropdown-item" routerLink="login-new">User
                                                Login/Register</a>
                                            <a class="logout-cutom dropdown-item" routerLink="member-register">Member
                                                Register</a>
                                        </div>
                                    </div>
                                </li>

                                <li *ngIf="appService.userLoggedIn" style="margin-left:20px !important">
                                    <div class="dropdown">
                                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            style="text-decoration: none !important;"><span class="lnr lnr-user">
                                            </span>
                                            {{ appService.userName }}
                                        </a>
                                        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                            <!-- <a class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a> -->
                                            <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="float-right">
                                <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>
                            </div> -->
                    </div>

                </div>

            </header>
        </div>
        <div class="container-fluid navbar-row" style="padding-left: 0px !important;padding-right: 0px !important;">
            <app-menu></app-menu>
        </div>
    </div>
</div>

<div *ngIf="configs.title === 'Cle'" class="avonHome1">
    <div class="container-fluid-header-sticky-header">
        <div class="container-fluid header sticky-header cle-header">
            <span class="container-fluid header sticky-header cle-header" style="display: flex;
                 justify-content: center;font-weight:700;margin-left: 10%;">E-commerce portal of Indian
                Footwear(Leather And Non Leather) Products</span>
            <header>
                <div class="flex-container">
                    <img [src]="logoImage" alt="Logo Image" class="custom-pointer cle-logo" height="50" alt="Logo"
                        style="width: 72px!important; height: 100px!important;" [routerLink]="['/']">
                    <div style="margin-left: 7px;">
                        <span style="font-weight: bold;font-size: 15px;">Council For Leather Exports (CLE)</span>
                        <div>
                            <span style="font-weight: bold;font-size: 14px;">चर्म निर्यात परिषद</span>
                        </div>
                        <div style="max-width: 92%;">
                            <span style="font-size: 13px;">(Sponsored by Ministry of Commerce &amp; Industry, Government
                                of India)</span>
                        </div>
                    </div>
                    <div class="header-row-one" style="width: 100%;margin-left: 10%;">
                        <div class="container-fluid"
                            style="padding-left: 0px !important;padding-right: 0px !important;padding-top: 5% !important;">
                            <div class="inq-menu-st row ">
                                <!-- <div class="inq-menu-st row justify-content-center"> -->
                                <nav class="navbar navbar-expand-lg navbar-light"><button type="button"
                                        data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
                                        aria-expanded="false" aria-label="Toggle navigation"
                                        class="navbar-toggler"><span
                                            class="navbar-toggler-icon"></span><span>Menu</span></button>
                                    <div id="navbarNav" class="collapse navbar-collapse">
                                        <ul class="navbar-nav" style="padding-top:20px">
                                            <li class="nav-item cle-homenav" id="home"><a class="nav-link menu-text"
                                                    ng-reflect-router-link="/" href="#/"
                                                    style="width: max-content!important;">
                                                    <p style="font-size: 15px; font-weight: 500; color: black;">HOME</p>
                                                </a></li>
                                            <li class="header-cart account-popup1" id="shop"
                                                style="width: max-content!important;cursor: pointer;">
                                                <a (click)="productsLists()" class="nav-link menu-text">
                                                    <p
                                                        style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">
                                                        COMPANIES</p>
                                                </a>
                                            </li>
                                            <li class="header-cart account-popup1" id="shop"
                                                style="width: max-content!important;">
                                                <a href="javascript:;" class="nav-link menu-text"
                                                    (click)="productsList()" style="display: flex;">
                                                    <p style="font-size: 15px; font-weight: 500; color: black;">CATEGORY
                                                    </p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-chevron-down"
                                                        style="color: black;">
                                                        <polyline points="6 9 12 15 18 9"></polyline>
                                                    </svg>
                                                </a>
                                                <app-new-navbar [category]="fruits"></app-new-navbar>
                                            </li>
                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                            <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>BESTGIFTS</p></a> -->
                                            <!-- <app-new-navbar [category]="vegetables"></app-new-navbar> -->
                                            <!-- </li> -->
                                            <!-- <li class="header-cart account-popup1" id="shop">
                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                                     <app-new-navbar></app-new-navbar>
                                                    </li> -->
                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                            <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>VALENTINE SPECIAL</p></a> -->
                                            <!-- <app-new-navbar [category]="others"></app-new-navbar> -->
                                            <!-- </li> -->
                                            <li class="header-cart account-popup1" id="shop"
                                                style="width: max-content!important;">
                                                <a href="#/investor" class="nav-link menu-text">
                                                    <p
                                                        style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">
                                                        INVESTOR ENQUIRY</p>
                                                </a>
                                            </li>
                                            <li class="header-cart account-popup1" id="shop" style="text-shadow: none;">
                                                <a href="javascript:;" class="nav-link menu-text"
                                                    style="display: flex;">
                                                    <p
                                                        style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">
                                                        PAGES</p><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                        height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-chevron-down"
                                                        style="color: black;">
                                                        <polyline points="6 9 12 15 18 9"></polyline>
                                                    </svg>
                                                </a>
                                                <app-new-navbar [category]="clepages"></app-new-navbar>
                                            </li>

                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                                            <a href="#/about-us" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">ABOUT US</p></a>
                                                            </li>
                                                            <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                                                <a href="#/contact-us" class="nav-link menu-text" ><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">CONTACT US</p></a>
                                                                </li> -->

                                            <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                        href="#/contact-us">CONTACT US</a></li> -->

                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div class="col-3" style="margin-top: 2.5%; padding-right: 0px; padding-left: 18%;">

                            <ul class="d-flex cle-headericon">
                                <div style="font-weight: 500!important;">Search</div>
                                <li class="d-flex header-cart account-popup1" style="margin-right: 9%;
                                    margin-left: 15%;">

                                    <a href="javascript:;"
                                        style="text-decoration: none !important;color:#0a0a0a !important;"
                                        (click)="search()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-search">
                                            <circle cx="11" cy="11" r="8"></circle>
                                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                        </svg>
                                    </a>

                                    <div class="min-cart-product-wrapper popup-content2 "
                                        style="height:auto;overflow-y:visible!important;width: 150%!important;margin-right: -50%;">
                                        <div class="row" style="border-bottom : 2px;margin: 5px;">
                                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="dropdown-menu autocomplete-items"
                                                            style="width: 100%! important;">
                                                            <div *ngFor="let category of allCategories">
                                                                <a class="dropdown-item"
                                                                    (click)="navigateToList(category)"
                                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input type="text" placeholder="Search here."
                                                        class="form-control search-form-input"
                                                        formControlName="search_term" (keyup)="getPreList()">
                                                    <div *ngIf="preList.length != 0">
                                                        <!-- <div class="autocomplete-items2"> -->
                                                        <div class="autocomplete-items2">
                                                            <div *ngFor="let preItem of preList">
                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                        </div>
                    </div>
                </div>
                <div class="input-group-btn">
                    <button class="btn btn-solid">
                        <i class="fa fa-search" (click)="savePreList1()"></i>
                    </button>
                </div>
        </div>
        </form>

    </div>


</div>
</li>
<!-- <li>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search">
                                        <div class="input-group-append">
                                          <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                            <i class="fa fa-search"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </li> -->

<li class="d-flex" style="margin-right: 19%;margin-left: 40%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
    <span class="menu-title" style="cursor: pointer;">Login</span> <a href="javascript:;"
        style="text-decoration: none !important;color: #0a0a0a !important; font-size: 13px;">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="29" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-user">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
        </svg>
    </a>
</li>

<li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 30%;">
    <div class="dropdown">
        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style="text-decoration: none !important;color: #0a0a0a !important;"><span class="lnr lnr-user">
            </span>
            <!-- {{ appService.userName }} -->
            {{ appService.userName.substring(0,4)}}..
        </a>
        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #0a0a0a !important;">My
                Account</a>
            <a *ngIf="configs.title !== 'Cle'" class="logout-cutom dropdown-item" routerLink="my-orders"
                style="color: #0a0a0a!important;">My Order</a>
            <a *ngIf="configs.title === 'Cle'" class="logout-cutom dropdown-item" routerLink="enquiry-status"
                style="color: #0a0a0a!important;">My Enquiry</a>
            <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #0a0a0a!important;">Logout</a>
        </div>
    </div>
</li>

<li class="header-cart account-popup d-flex" style="position: relative;margin-left: 40%;
                                    ">
    <span class="menu-title" style="margin-right: 2%;cursor: pointer;
                                        ">Cart</span>
    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #0a0a0a!important;">
        <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-shopping-bag">
                <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <path d="M16 10a4 4 0 0 1-8 0"></path>
            </svg>
            <span style="position: absolute;
                                                
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;">

                <span style="  color: #0b0b0b;
                                                    position: absolute;
                                                    left: 1.2rem;
                                                    font-size: 15px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
            </span>
        </span>
        <!-- <span class="menu-title">
                                                Cart
                                            </span> -->
    </a>

    <app-mini-cart></app-mini-cart>
</li>
</ul>

</div>
<!-- <div class="float-right">
                                <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>
                            </div> -->
</div>

</div>

</header>
</div>

</div>
</div>

<div *ngIf="configs.title === 'Cle'" class="avonHome2">
    <div class="container-fluid-header-sticky-header">
        <div class="container-fluid header sticky-header cle-header">
            <span class="container-fluid header sticky-header cle-header" style="display: flex;
                 justify-content: center;font-weight:700">E-commerce portal of Indian Footwear(Leather And Non Leather)
                Products</span>
            <header>
                <div class="flex-container">
                    <img [src]="logoImage" alt="Logo Image" class="custom-pointer cle-logo" height="50" alt="Logo"
                        style="width: 72px!important; height: 100px!important;" [routerLink]="['/']">
                    <div style="margin-left: 7px;width: 20%;">
                        <span style="font-weight: bold;font-size: 15px;">Council For Leather Exports (CLE)</span>
                        <div>
                            <span style="font-weight: bold;font-size: 14px;">चर्म निर्यात परिषद</span>
                        </div>
                        <div class="header2" style="max-width: 81%;">
                            <span style="font-size: 12px;">(Sponsored by Ministry of Commerce &amp; Industry, Government
                                of India)</span>
                        </div>
                        <div class="header1" style="max-width: 90%;">
                            <span style="font-size: 12px;">(Sponsored by Ministry of Commerce &amp; Industry, Government
                                of India)</span>
                        </div>
                    </div>
                    <div class="header-row-one" style="width: 72%;">
                        <div class="container-fluid"
                            style="padding-left: 0px !important;padding-right: 0px !important;padding-top: 5% !important;">
                            <div class="inq-menu-st row ">
                                <!-- <div class="inq-menu-st row justify-content-center"> -->
                                <nav class="navbar navbar-expand-lg navbar-light"><button type="button"
                                        data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
                                        aria-expanded="false" aria-label="Toggle navigation"
                                        class="navbar-toggler"><span
                                            class="navbar-toggler-icon"></span><span>Menu</span></button>
                                    <div id="navbarNav" class="collapse navbar-collapse">
                                        <ul class="navbar-nav" style="padding-top:20px">
                                            <li class="nav-item cle-homenav1" id="home"><a class="nav-link menu-text"
                                                    ng-reflect-router-link="/" href="#/"
                                                    style="width: max-content!important;">
                                                    <p style="font-size: 15px; font-weight: 500; color: black;">HOME</p>
                                                </a></li>
                                            <li class="header-cart account-popup1" id="shop"
                                                style="width: max-content!important;cursor: pointer;">
                                                <a (click)="productsLists()" class="nav-link menu-text">
                                                    <p
                                                        style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">
                                                        COMPANIES</p>
                                                </a>
                                            </li>
                                            <li class="header-cart account-popup1 cle-homenav1" id="shop"
                                                style="width: max-content!important;">
                                                <a href="javascript:;" class="nav-link menu-text"
                                                    (click)="productsList()" style="display: flex;">
                                                    <p style="font-size: 15px; font-weight: 500; color: black;">CATEGORY
                                                    </p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-chevron-down"
                                                        style="color: black;">
                                                        <polyline points="6 9 12 15 18 9"></polyline>
                                                    </svg>
                                                </a>
                                                <app-new-navbar [category]="fruits"></app-new-navbar>
                                            </li>
                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                            <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>BESTGIFTS</p></a> -->
                                            <!-- <app-new-navbar [category]="vegetables"></app-new-navbar> -->
                                            <!-- </li> -->
                                            <!-- <li class="header-cart account-popup1" id="shop">
                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                                     <app-new-navbar></app-new-navbar>
                                                    </li> -->
                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                            <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>VALENTINE SPECIAL</p></a> -->
                                            <!-- <app-new-navbar [category]="others"></app-new-navbar> -->
                                            <!-- </li> -->
                                            <li class="header-cart account-popup1 cle-homenav1" id="shop"
                                                style="width: max-content!important;">
                                                <a href="#/investor" class="nav-link menu-text">
                                                    <p
                                                        style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">
                                                        INVESTOR ENQUIRY</p>
                                                </a>
                                            </li>
                                            <li class="header-cart account-popup1" id="shop"
                                                style="text-shadow: none;margin-left: 0.5rem!important;">
                                                <a href="javascript:;" class="nav-link menu-text"
                                                    style="display: flex;">
                                                    <p
                                                        style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">
                                                        PAGES</p><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                        height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-chevron-down"
                                                        style="color: black;">
                                                        <polyline points="6 9 12 15 18 9"></polyline>
                                                    </svg>
                                                </a>
                                                <app-new-navbar [category]="clepages"></app-new-navbar>
                                            </li>
                                            <!-- <li class="header-cart account-popup1 cle-homenav1" id="shop" style="width: max-content!important;">
                                                            <a href="#/about-us" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">ABOUT US</p></a>
                                                            </li>
                                                            <li class="header-cart account-popup1 cle-homenav1" id="shop" style="width: max-content!important;">
                                                                <a href="#/contact-us" class="nav-link menu-text" ><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">CONTACT US</p></a>
                                                                </li> -->

                                            <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                        href="#/contact-us">CONTACT US</a></li> -->

                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div class="col-3" style="margin-top: 2.5%; padding-right: 0px; padding-left: 0px;">

                            <ul class="d-flex cle-headericon">
                                <div style="font-weight: 500!important;">Search</div>
                                <li class="d-flex header-cart account-popup1" style="margin-right: 9%;
                                    margin-left: 2%;">

                                    <a href="javascript:;"
                                        style="text-decoration: none !important;color:#0a0a0a !important;"
                                        (click)="search()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-search">
                                            <circle cx="11" cy="11" r="8"></circle>
                                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                        </svg>
                                    </a>

                                    <div class="min-cart-product-wrapper popup-content2 "
                                        style="height:auto;overflow-y:visible!important;width: 130%!important;margin-right: -10%;">
                                        <div class="row" style="border-bottom : 2px;margin: 5px;">
                                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="dropdown-menu autocomplete-items"
                                                            style="width: 100%! important;">
                                                            <div *ngFor="let category of allCategories">
                                                                <a class="dropdown-item"
                                                                    (click)="navigateToList(category)"
                                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input type="text" placeholder="Search here."
                                                        class="form-control search-form-input"
                                                        formControlName="search_term" (keyup)="getPreList()">
                                                    <div *ngIf="preList.length != 0">
                                                        <!-- <div class="autocomplete-items2"> -->
                                                        <div class="autocomplete-items2">
                                                            <div *ngFor="let preItem of preList">
                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                        </div>
                    </div>
                </div>
                <div class="input-group-btn">
                    <button class="btn btn-solid">
                        <i class="fa fa-search" (click)="savePreList1()"></i>
                    </button>
                </div>
        </div>
        </form>

    </div>


</div>
</li>
<!-- <li>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search">
                                        <div class="input-group-append">
                                          <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                            <i class="fa fa-search"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </li> -->

<li class="d-flex loginheader" *ngIf="!appService.userLoggedIn" (click)="logIn()">
    <span class="menu-title" style="cursor: pointer;">Login</span> <a href="javascript:;"
        style="text-decoration: none !important;color: #0a0a0a !important; font-size: 13px;">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="29" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-user">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
        </svg>
    </a>
</li>

<li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
    <div class="dropdown">
        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style="text-decoration: none !important;color: #0a0a0a !important;"><span class="lnr lnr-user">
            </span>
            <!-- {{ appService.userName }} -->
            {{ appService.userName.substring(0,4)}}..
        </a>
        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #0a0a0a !important;">My
                Account</a>
            <a *ngIf="configs.title !== 'Cle'" class="logout-cutom dropdown-item" routerLink="my-orders"
                style="color: #0a0a0a!important;">My Order</a>
            <a *ngIf="configs.title === 'Cle'" class="logout-cutom dropdown-item" routerLink="enquiry-status"
                style="color: #0a0a0a!important;">My Enquiry</a>
            <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #0a0a0a!important;">Logout</a>
        </div>
    </div>
</li>

<li class="header-cart account-popup d-flex" style="position: relative;">
    <span class="menu-title" style="margin-right: 2%;cursor: pointer;
                                        ">Cart</span>
    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #0a0a0a!important;">
        <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-shopping-bag">
                <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <path d="M16 10a4 4 0 0 1-8 0"></path>
            </svg>
            <span style="position: absolute;
                                                
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;">

                <span style="  color: #0b0b0b;
                                                    position: absolute;
                                                    left: 1.2rem;
                                                    font-size: 15px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
            </span>
        </span>
        <!-- <span class="menu-title">
                                                Cart
                                            </span> -->
    </a>

    <app-mini-cart></app-mini-cart>
</li>
</ul>

</div>
<!-- <div class="float-right">
                                <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>
                            </div> -->
</div>

</div>

</header>
</div>

</div>
</div>

<div *ngIf="configs.title === 'Cle'" class="avonHome3">
    <div class="container-fluid-header-sticky-header">
        <div class="container-fluid header sticky-header cle-header">
            <span class="container-fluid header sticky-header cle-header" style="display: flex;
                 justify-content: center;font-weight:700;margin-left: 10%;">E-commerce portal of Indian
                Footwear(Leather And Non Leather) Products</span>
            <header>
                <div class="flex-container">
                    <img [src]="logoImage" alt="Logo Image" class="custom-pointer cle-logo" height="50" alt="Logo"
                        style="width: 72px!important; height: 100px!important;" [routerLink]="['/']">
                    <div style="margin-left: 7px;">
                        <span style="font-weight: bold;font-size: 15px;">Council For Leather Exports (CLE)</span>
                        <div>
                            <span style="font-weight: bold;font-size: 14px;">चर्म निर्यात परिषद</span>
                        </div>
                        <div style="max-width: 100%;">
                            <span style="font-size: 12px;">(Sponsored by Ministry of Commerce &amp; Industry, Government
                                of India)</span>
                        </div>
                    </div>
                    <div class="header-row-one" style="width: 100%;margin-left: 10%;">
                        <div class="container-fluid"
                            style="padding-left: 0px !important;padding-right: 0px !important;padding-top: 5% !important;">
                            <div class="inq-menu-st row ">
                                <!-- <div class="inq-menu-st row justify-content-center"> -->
                                <nav class="navbar navbar-expand-lg navbar-light"><button type="button"
                                        data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
                                        aria-expanded="false" aria-label="Toggle navigation"
                                        class="navbar-toggler"><span
                                            class="navbar-toggler-icon"></span><span>Menu</span></button>
                                    <div id="navbarNav" class="collapse navbar-collapse">
                                        <ul class="navbar-nav" style="padding-top:20px">
                                            <li class="nav-item cle-homenav2" id="home"><a class="nav-link menu-text"
                                                    ng-reflect-router-link="/" href="#/"
                                                    style="width: max-content!important;">
                                                    <p style="font-size: 15px; font-weight: 500; color: black;">HOME</p>
                                                </a></li>
                                            <li class="header-cart account-popup1 cle-homenav2" id="shop"
                                                style="width: max-content!important;cursor: pointer;">
                                                <a (click)="productsLists()" class="nav-link menu-text">
                                                    <p
                                                        style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">
                                                        COMPANIES</p>
                                                </a>
                                            </li>
                                            <li class="header-cart account-popup1 cle-homenav2" id="shop"
                                                style="width: max-content!important;">
                                                <a href="javascript:;" class="nav-link menu-text"
                                                    (click)="productsList()" style="display: flex;">
                                                    <p style="font-size: 15px; font-weight: 500; color: black;">CATEGORY
                                                    </p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-chevron-down"
                                                        style="color: black;">
                                                        <polyline points="6 9 12 15 18 9"></polyline>
                                                    </svg>
                                                </a>
                                                <app-new-navbar [category]="fruits"></app-new-navbar>
                                            </li>
                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                            <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>BESTGIFTS</p></a> -->
                                            <!-- <app-new-navbar [category]="vegetables"></app-new-navbar> -->
                                            <!-- </li> -->
                                            <!-- <li class="header-cart account-popup1" id="shop">
                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                                     <app-new-navbar></app-new-navbar>
                                                    </li> -->
                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                            <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>VALENTINE SPECIAL</p></a> -->
                                            <!-- <app-new-navbar [category]="others"></app-new-navbar> -->
                                            <!-- </li> -->
                                            <li class="header-cart account-popup1 cle-homenav2" id="shop"
                                                style="width: max-content!important;">
                                                <a href="#/investor" class="nav-link menu-text">
                                                    <p
                                                        style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">
                                                        INVESTOR ENQUIRY</p>
                                                </a>
                                            </li>
                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                                                <a href="#/about-us" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">PAGES</p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;"><polyline points="6 9 12 15 18 9"></polyline></svg></a>
                                                                 <app-new-navbarkub [category]="clepages"></app-new-navbarkub>
                                                                </li> -->
                                            <li class="header-cart account-popup1" id="shop"
                                                style="text-shadow: none;margin-left: 0.5rem!important;">
                                                <a href="javascript:;" class="nav-link menu-text"
                                                    style="display: flex;">
                                                    <p
                                                        style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap">
                                                        PAGES</p><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                        height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-chevron-down"
                                                        style="color: black;">
                                                        <polyline points="6 9 12 15 18 9"></polyline>
                                                    </svg>
                                                </a>
                                                <app-new-navbar [category]="clepages"></app-new-navbar>
                                            </li>

                                            <!-- <li class="header-cart account-popup1 cle-homenav2" id="shop" style="width: max-content!important;">
                                                            <a href="#/about-us" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">ABOUT US</p></a>
                                                            </li>
                                                            <li class="header-cart account-popup1 cle-homenav2" id="shop" style="width: max-content!important;">
                                                                <a href="#/contact-us" class="nav-link menu-text" ><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">CONTACT US</p></a>
                                                                </li> -->

                                            <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                        href="#/contact-us">CONTACT US</a></li> -->

                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div class="col-3" style="margin-top: 2.5%; padding-right: 0px; padding-left: 14%;">

                            <ul class="d-flex cle-headericon">
                                <div style="font-weight: 500!important;">Search</div>
                                <li class="d-flex header-cart account-popup1" style="margin-right: 4%;
                                    margin-left: 2%;">

                                    <a href="javascript:;"
                                        style="text-decoration: none !important;color:#0a0a0a !important;"
                                        (click)="search()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-search">
                                            <circle cx="11" cy="11" r="8"></circle>
                                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                        </svg>
                                    </a>

                                    <div class="min-cart-product-wrapper popup-content2 "
                                        style="height:auto;overflow-y:visible!important;width: 150%!important;margin-right: -50%;">
                                        <div class="row" style="border-bottom : 2px;margin: 5px;">
                                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="dropdown-menu autocomplete-items"
                                                            style="width: 100%! important;">
                                                            <div *ngFor="let category of allCategories">
                                                                <a class="dropdown-item"
                                                                    (click)="navigateToList(category)"
                                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input type="text" placeholder="Search here."
                                                        class="form-control search-form-input"
                                                        formControlName="search_term" (keyup)="getPreList()">
                                                    <div *ngIf="preList.length != 0">
                                                        <!-- <div class="autocomplete-items2"> -->
                                                        <div class="autocomplete-items2">
                                                            <div *ngFor="let preItem of preList">
                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                        </div>
                    </div>
                </div>
                <div class="input-group-btn">
                    <button class="btn btn-solid">
                        <i class="fa fa-search" (click)="savePreList1()"></i>
                    </button>
                </div>
        </div>
        </form>

    </div>


</div>
</li>
<!-- <li>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search">
                                        <div class="input-group-append">
                                          <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                            <i class="fa fa-search"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </li> -->

<li class="d-flex" style="margin-right: 19%;margin-left: 25%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
    <span class="menu-title" style="cursor: pointer;">Login</span> <a href="javascript:;"
        style="text-decoration: none !important;color: #0a0a0a !important; font-size: 13px;">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="29" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-user">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
        </svg>
    </a>
</li>

<li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 15%;">
    <div class="dropdown">
        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style="text-decoration: none !important;color: #0a0a0a !important;"><span class="lnr lnr-user">
            </span>
            <!-- {{ appService.userName }} -->
            {{ appService.userName.substring(0,4)}}..
        </a>
        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #0a0a0a !important;">My
                Account</a>
            <a *ngIf="configs.title !== 'Cle'" class="logout-cutom dropdown-item" routerLink="my-orders"
                style="color: #0a0a0a!important;">My Order</a>
            <a *ngIf="configs.title === 'Cle'" class="logout-cutom dropdown-item" routerLink="enquiry-status"
                style="color: #0a0a0a!important;">My Enquiry</a>
            <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #0a0a0a!important;">Logout</a>
        </div>
    </div>
</li>

<li class="header-cart account-popup d-flex" style="position: relative;margin-left: 27%;">
    <span class="menu-title" style="margin-right: 2%;cursor: pointer;
                                        ">Cart</span>
    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #0a0a0a!important;">
        <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-shopping-bag">
                <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <path d="M16 10a4 4 0 0 1-8 0"></path>
            </svg>
            <span style="position: absolute;
                                                
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;">

                <span style="  color: #0b0b0b;
                                                    position: absolute;
                                                    left: 1.2rem;
                                                    font-size: 15px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
            </span>
        </span>
        <!-- <span class="menu-title">
                                                Cart
                                            </span> -->
    </a>

    <app-mini-cart></app-mini-cart>
</li>
</ul>

</div>
<!-- <div class="float-right">
                                <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>
                            </div> -->
</div>

</div>

</header>
</div>

</div>
</div>



<div *ngIf="configs.title === 'LinenRaj' && routeurl!='/'">

    <div class="container-fluid-header-sticky-header">

        <div class="container-fluid header sticky-header cle-header">



            <header class="container-fluid-header-sticky-header"
                style="background-color:#fff;height: 9vw;display: block;">

                <div class="flex-container header-linenraj">

                    <div class="collapsenavbar-collapse-1">

                        <ul class="navbar-nav">

                            <li class="nav-item cle-homenav" id="home"><a class="nav-link menu-text"
                                    ng-reflect-router-link="/" href="#/" style="width: max-content!important;">
                                    <p class="hover-underline-animation"
                                        style="font-size: 10px; font-weight:600;font-family: lato;letter-spacing: 2px; color: black;margin-left: 60px;">
                                        HOME</p>
                                </a></li>

                        </ul>



                    </div>



                    <img [src]="logoImage" alt="Logo Image" class="custom-pointer aiema-logo" height="60" alt="Logo"
                        style="margin-left:30%;box-shadow: none;" [routerLink]="['/']">









                    <div class="header-row-one" style="width: 100%;">

                        <div class="container-fluid"
                            style="padding-left: 0px !important;padding-right: 0px !important;">

                            <div class="inq-menu-st row ">

                                <!-- <div class="inq-menu-st row justify-content-center"> -->

                                <nav class="navbar navbar-expand-lg navbar-light"><button type="button"
                                        data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
                                        aria-expanded="false" aria-label="Toggle navigation"
                                        class="navbar-toggler"><span
                                            class="navbar-toggler-icon"></span><span>Menu</span></button>



                                </nav>

                            </div>

                        </div>

                        <div class="col-3" style="margin-top: 2.5%; padding-right: 0px; padding-left: 0px;">



                            <ul class="d-flex cle-headericon" style="margin-left: -38px;">



                                <!-- <li class="d-flex header-cart account-popup1" style="margin-right: 9%;

                                   margin-left: 2%;" >

                                  

                                       <a href="javascript:;"   style="text-decoration: none !important;color:#0a0a0a !important;">

                                         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>

                                       </a>

                                      

                                       <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y:visible!important;">

                                           <div class="row" style="border-bottom : 2px;margin: 5px;">

                                               <form  class="search-bar" style="width: 100%;">

                                                       <div class="input-group">

                                                           <div class="input-group-prepend">

                                                               <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">

                                                                   <div>

                                                                       <a class="dropdown-item" style="cursor:pointer;font-size: 14px !important;"></a>

                                                                   </div>                                      

                                                               </div>

                                                             </div>

                                                           <input type="text" placeholder="Search here."

                                                               class="form-control search-form-input" formControlName="search_term">

                                                           <div >

                                                             

                                                                   <div class="autocomplete-items2">

                                                                  

                                                               </div>

                                                           </div>

                                                           <div class="input-group-btn">

                                                               <button class="btn btn-solid" >

                                                                   <i class="fa fa-search"></i>

                                                               </button>

                                                           </div>

                                                       </div>

                                               </form>

                                              

                                           </div>

                        
                                  

                                       </div>

                                   </li> -->


                                <!-- <li class="d-flex" style="margin-right:19px;    margin-left: 15px;" *ngIf="!appService.userLoggedIn" (click)="logIn()">

                                     

                                        <a href="javascript:;"  style="text-decoration: none !important;color: #0a0a0a !important; font-size: 13px;">

                                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="29" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>

                                       </a>

                                   </li> -->



                                <!-- <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">

                                       <div class="dropdown">

                                           <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"

                                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: black !important;"><span

                                                   class="lnr lnr-user">{{ appService.userName.substring(0,18) }}...

                                               </span>

                                              

                                           </a>

                                           <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                               <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #0a0a0a !important;">My Account</a>

                                               <a *ngIf= "configs.title != 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #0a0a0a!important;">My Order</a>

                                            

                                               <a class="logout-cutom dropdown-item" style="color: #0a0a0a!important;" (click)="logOut()">Logout</a>

                                           </div>

                                       </div>

                                   </li> -->

                                <li class="d-flex header-cart account-popup1" style="margin-right: 18%;">
                                    <a href="javascript:;"
                                        style="text-decoration: none !important;color:#b59f6e !important;"
                                        (click)="search()">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </a>
                                    <div class="min-cart-product-wrapper popup-content2 "
                                        style="height:auto;overflow-y:visible!important;">
                                        <div class="row" style="margin: 5px;">
                                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="dropdown-menu autocomplete-items"
                                                            style="width: 100%! important;">
                                                            <div *ngFor="let category of allCategories">
                                                                <a class="dropdown-item"
                                                                    (click)="navigateToList(category)"
                                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input type="text" placeholder="Search here."
                                                        class="form-control search-form-input"
                                                        formControlName="search_term" (keyup)="getPreList()">
                                                    <div *ngIf="preList.length != 0">
                                                        <!-- <div class="autocomplete-items2"> -->
                                                        <div class="autocomplete-items2">
                                                            <div *ngFor="let preItem of preList">
                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                        </div>
                    </div>
                </div>
                <div class="input-group-btn">
                    <button class="btn btn-solid">
                        <i class="fa fa-search" (click)="savePreList1()"></i>
                    </button>
                </div>
        </div>
        </form>

    </div>


</div>
</li>

<li class="d-flex" style="margin-left: 2vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
    <a href="javascript:;" style="text-decoration: none !important;color: #b59f6e !important">
        <span class="fa fa-user" style="font-size:2vw">
        </span>
    </a>
</li>

<li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 2%;margin-top: 2%">
    <div class="dropdown">
        <a class="menu-title dropdown-toggle profile_name" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style="text-decoration: none !important;color: #b59f6e !important;"><span class="lnr lnr-user">
            </span>
            {{ appService.userName.substring(0,15)}}
        </a>
        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #b59f6e !important;">My
                Account</a>
            <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #b59f6e !important;">My Order</a>

            <!-- <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #b59f6e !important;">Logout</a> -->
            <a class="logout-cutom dropdown-item" (click)="initialLogout()"
                style="color: #b59f6e !important;">Logout</a>
        </div>
    </div>
</li>

<li class="header-cart account-popup d-flex" style="position: relative;">

    <!-- <span class="menu-title" style="margin-right: 2%;cursor: pointer;

                                       ">Cart</span>                                      -->

    <a id="openbaglinen" href="javascript:;" data-toggle="modal" data-target="#bagModal"
        style="text-decoration: none !important;color: #0a0a0a!important;margin-left: 10px;">

        <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-shopping-bag">
                <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <path d="M16 10a4 4 0 0 1-8 0"></path>
            </svg>

            <span style="position: absolute;

                                              

                                               width: 18px;

                                               height: 18px;

                                               border-radius: 9px;

                                               top: -10px;

                                               right: 9%;">

                <!--                                                

                                                   <span style="  color: #0b0b0b;

                                                   position: absolute;

                                                   left: 1.2rem;

                                                   font-size: 15px;

                                                   font-weight: bold;">{{appService.totalCartItems}}</span> -->

                <span class="count-1">

                    <span class="count-number-1">{{appService.totalCartItems}}</span>

                </span>

            </span>

        </span>

        <!-- <span class="menu-title">

                                               Cart

                                           </span> -->

    </a>



    <!-- <app-mini-cart></app-mini-cart> -->

</li>

</ul>



</div>

<!-- <div class="float-right">

                               <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>

                           </div> -->

</div>



</div>



</header>

</div>



</div>

<div class="container-fluid-header-sticky-header1 best_gifts">
    <header>
        <div>
            <ul style="display:flex;justify-content: center;">
                <!-- <li style="margin-top: -5px;"><span style="font-size: 7vw;cursor:pointer;color:#3C57A6" (click)="openNav()">&#9776;</span>
    </li> -->
                <!-- <li class="nav-item cle-homenav" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"

        href="#/" style="width: max-content!important;"><p class="hover-underline-animation" style="font-size: 10px; font-weight:600;font-family: lato;letter-spacing: 2px; color: black;">HOME</p></a></li> -->
                <li style="margin-left: 6px;">
                    <div class="row">
                        <span>
                            <img [src]="logoImage" alt="Logo Image" class="logo-image custom-pointer gifts_img"
                                style="margin-top: 8%;box-shadow:none;" alt="Logo" [routerLink]="['/']">
                        </span>
                    </div>
                </li>

                <li class="d-flex header-cart account-popup1" style="margin-left: 10%;">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 4vw;
                                margin-left: 1vw;
                                margin-top: 7vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                <div class="row" style="margin: 5px;">
                                    <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                        <div *ngFor="let category of allCategories">
                                                            <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                        </div>                                      
                                                    </div>
                                                  </div>
                                            </div>
                                    </form>
                                </div>
                            </div> -->
                </li>

                <li class="d-flex" style="margin-left: 12%;
                        margin-top: 5vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                    <a href="javascript:;" style="text-decoration: none !important;color: #b59f6e !important">
                        <span class="fa fa-user" style="font-size:4vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 8%;margin-top: 5%">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle profile_name" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: #b59f6e !important;"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4)}}...
                        </a>
                        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="logout-cutom dropdown-item" routerLink="my-account"
                                style="color: #b59f6e !important;">My Account</a>
                            <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                style="color: #b59f6e !important;">My Order</a>

                            <!-- <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #b59f6e !important;">Logout</a> -->
                            <a class="logout-cutom dropdown-item" (click)="initialLogout()"
                                style="color: #b59f6e !important;">Logout</a>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: #b59f6e !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                margin-top: 6vw;
                                margin-left: 4vw;">
                            <span style="position: absolute;
                                    background: #FBCB0C;
                                    width: 5vw;
                                    height: 3.5vw;
                                    border-radius: 1vw;">

                                <span style="color: #fff;
                                        position: absolute;
                                        left: 0.4rem;
                                        font-size: 3vw;
                                        font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                        <!-- <span class="menu-title">
                                    Cart
                                </span> -->
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closeNav1()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myfruits1','FRUITS')">
                <p>SHOP &#8594;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myfruits1','FRUITS')">
                <p>BESTGIFTS &#8594;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myfruits1','FRUITS')">
                <p>VALETINE SPECIAL &#8594;</p>
            </a>


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;SHOP </span>

            <app-new-navbar [category]="fruitsres"></app-new-navbar>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;BESTGIFTS </span>

            <app-new-navbar [category]="vegetablesres"></app-new-navbar>

        </div>
        <div id="myothers1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;VALETINE SPECIAL </span>

            <app-new-navbar [category]="othersres"></app-new-navbar>

        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closeNav()">
                <p>&#8592;</p>
            </a>
            <a class="nav-link menu-text" ng-reflect-router-link="/" href="#/" (click)="closeNav()">
                <p>HOME</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')">
                <p>SHOP &#8594;</p>
            </a>

            <a href="javascript:void(0)" class="nav-link menu-text" (click)="bestgifts()">
                <p>BESTGIFTS</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="bestgifts()">
                <p>VALETINE SPECIAL</p>
            </a>


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;SHOP </span>

            <app-new-navbar [category]="fruitsres"></app-new-navbar>

        </div>
        <div id="myvegetables" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;BESTGIFTS </span>

            <app-new-navbar [category]="vegetablesres"></app-new-navbar>

        </div>
        <div id="myothers" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;VALETINE SPECIAL </span>

            <app-new-navbar [category]="othersres"></app-new-navbar>

        </div>
        <div id="searchnav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>
        </div>


    </header>
</div>

</div>


<!-- 1 -->
<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;margin-top: -40px;margin-bottom: 10%;
padding: 2vw;" *ngIf="configs.title === 'Cle'">
    <span style="background-color:white;height: 12vw;margin-top: -40px;
 padding: 2vw;font-size: 10px;font-weight: 800;">E-commerce portal of Indian Footwear(Leather And Non Leather)
        Products</span>
    <header>
        <div>
            <ul style="display: flex;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:#000000"
                        (click)="openNav()">&#9776;</span>
                </li>
                <li>
                    <div class="row">
                        <span>
                            <img [src]="logoImage" alt="Logo Image" style="width: 20vw;
            height: 17vw;
            padding: 1vw;
            margin-top: 1px;
            object-fit: contain;" class="logo-image custom-pointer" alt="Logo" [routerLink]="['/']">
                        </span>

                        <span style="font-weight: bold;font-size: 10px;margin-left: -16px;">Council For Leather Exports
                            (CLE) <br>
                            <span style="font-weight: bold;font-size: 9px;">चर्म निर्यात परिषद</span><br>
                            <span style="font-size: 8px;">(Sponsored by Ministry of Commerce &amp; <br>Industry,
                                Government of India)</span>
                        </span>
                    </div>
                </li>
            </ul>
            <ul style="
display: flex;
transform: translate(260px, -59px);">
                <li class="d-flex header-cart account-popup1">
                    <a href="javascript:;" (click)="search()">
                        <i aria-hidden="true" style="text-decoration: none !important;color: #000000 !important;    font-size: 5vw;
                            margin-left: 1vw;
                            margin-top: 2vw;" (click)="opensearchnavkub()"><svg xmlns="http://www.w3.org/2000/svg"
                                width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-search">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2 "
                        style="height:auto;overflow-y:visible!important;">
                        <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="text" placeholder="Search here." class="form-control search-form-input"
                                        formControlName="search_term" (keyup)="getPreList()">
                                    <div *ngIf="preList.length != 0">
                                        <div class="autocomplete-items2">
                                            <div *ngFor="let preItem of preList">
                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
        </div>
</div>
</div>
<div class="input-group-btn">
    <button class="btn btn-solid">
        <i class="fa fa-search" (click)="savePreList1()"></i>
    </button>
</div>
</div>
</form>

</div>


</div>
</li>
<li class="d-flex" style="    margin-left: 4vw;
                    margin-top: 1.5vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
    <a href="javascript:;" style="text-decoration: none !important;color: #0a0a0a !important">
        <span style="font-size:4vw"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-user" style="transform: translate(0px, -5px);">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
            </svg>

        </span>
    </a>
</li>

<li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
    <div class="dropdown">
        <a class="menu-title dropdown-toggle profile_name" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style="text-decoration: none !important;color: #000000 !important;"><span class="lnr lnr-user">
            </span>
            {{ appService.userName.substring(0,4) }}
        </a>
        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div id="dropdown" class="dropdown-menu show"
                style=" transform: translate3d(-47px, -70px, 0px) !important;">
                <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #000000 !important;">My
                    Account</a>
                <a *ngIf="configs.title !== 'Cle' && configs.title != 'LinenRaj'" class="logout-cutom dropdown-item"
                    routerLink="my-orders" style="color: #000000 !important;">My Order</a>
                <a *ngIf="configs.title === 'Cle' || configs.title === 'LinenRaj'" class="logout-cutom dropdown-item"
                    routerLink="enquiry-status" style="color: #0a0a0a!important;">My Enquiry</a>
                <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #000000 !important;">Logout</a>
            </div>

        </div>
    </div>
</li>
<li class="header-cart account-popup d-flex" style="position: relative;margin-left: -10px;">
    <a href="javascript:;" routerLink="/cart" data-toggle="modal" data-target="#bagModal"
        style="text-decoration: none !important;color: #000000 !important;">
        <span style="font-size: 5vw;
                            margin-top: 2vw;
                            margin-left: 4vw;"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" class="feather feather-shopping-bag">
                <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <path d="M16 10a4 4 0 0 1-8 0"></path>
            </svg>

            <span style="position: absolute;
                               
                                width: 3vw;
                                height: 2.5vw;
                                border-radius: 1vw;">

                <span style="color: #090909;
                                    position: absolute;
                                    left: 0.1rem;
                                    top: -8px;
                                    font-size: 4vw;
                                    font-weight: bold;">{{appService.totalCartItems}}</span>
            </span>
        </span>
        <!-- <span class="menu-title">
                                Cart
                            </span> -->
    </a>
    <app-mini-cart></app-mini-cart>
</li>
</ul>

</div>
<div id="mySidenav1" class="sidenav" style="background-color:#fff">
    <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNav1()">
        <p>&#8592;</p>
    </a>
    <a href="javascript:void(0)" class="nav-link menu-text" style="cursor:pointer;"
        (click)="productsListkubendran2('myfruits1','FRUITS')">
        <p>Category &#8594;</p>
    </a>
    <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')">
        <p>Companies &#8594;</p>
    </a>
    <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')">
        <p>Investor Enquiry&#8594;</p>
    </a>
    <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')">
        <p>About Us &#8594;</p>
    </a>
    <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')">
        <p>Contact Us &#8594;</p>
    </a>


</div>
<div id="myfruits1" class="sidenav">
    <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;category </span>

    <app-new-navbar [category]="fruitsres"></app-new-navbar>

</div>
<div id="myvegetables1" class="sidenav">
    <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;Companies</span>

    <app-new-navbar [category]="vegetablesres"></app-new-navbar>

</div>
<div id="myvegetables1" class="sidenav">
    <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;Investor Enquiry </span>

    <app-new-navbar [category]="vegetablesres"></app-new-navbar>

</div>
<div id="myvegetables1" class="sidenav">
    <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;About Us </span>

    <app-new-navbar [category]="vegetablesres"></app-new-navbar>

</div>
<div id="myothers1" class="sidenav">
    <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;Contact Us </span>

    <app-new-navbar [category]="othersres"></app-new-navbar>

</div>
<div id="searchnav" class="sidenav" style="background-color: rgb(255, 255, 255)!important;">
    <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
        (click)="closesearchnavkub()">
        <p>&#8592;</p>
    </a>
    <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
        <input type="text" placeholder="Search here." class="form-control search-form-input"
            formControlName="search_term" (keyup)="getPreList()">
        <ul *ngFor="let preItem of preList">
            <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                {{preItem.name}}</li>
        </ul>
    </form>

</div>
<div id="mySidenav" class="sidenav" style="background-color:#fff">
    <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNav()">
        <p>&#8592;</p>
    </a>
    <a class="nav-link menu-text" ng-reflect-router-link="/" href="#/" (click)="closeNav()">
        <p>Home</p>
    </a>
    <a href="javascript:void(0)" class="nav-link menu-text" (click)="bestgifts()">
        <p>Companies</p>
    </a>
    <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')">
        <p>Category &#8594;</p>
    </a>
    <a href="javascript:void(0)" class="nav-link menu-text" href="#/investor" (click)="routeTo()">
        <p>Investor Enquiry</p>
    </a>
    <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" (click)="routeTo()">
        <p>About Us</p>
    </a>
    <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" (click)="routeTo()">
        <p>Contact Us</p>
    </a>


</div>
<div id="myvegetables" class="sidenav" style="background-color:#fff!important">
    <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;Category</span>

    <app-new-navbar [category]="vegetablesres"></app-new-navbar>

</div>
<div id="myfruits" class="sidenav" style="background-color:#fff!important">
    <span style="font-size: 5vw;cursor:pointer;color:#805425" (click)="openNav()">&#8592;Back </span>

    <app-new-navbar [category]="fruitsres"></app-new-navbar>

</div>
<div id="myvegetables" class="sidenav" style="background-color:#fff!important">
    <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;Investor Enquiry</span>

    <app-new-navbar [category]="vegetablesres"></app-new-navbar>

</div>
<div id="myvegetables" class="sidenav" style="background-color:#fff!important">
    <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;About Us </span>

    <app-new-navbar [category]="vegetablesres"></app-new-navbar>

</div>
<div id="myothers" class="sidenav" style="background-color:#fff!important">
    <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;Contact Us </span>

    <app-new-navbar [category]="othersres"></app-new-navbar>

</div>



</header>
</div>
<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'BestGifts'"
    style="background-color:#fff;height: 5vw;">
    <header>
        <div class="row header_bestgift">
            <div class="col-1">
                <img [src]="logoImage" alt="Logo Image" style="margin-left: 40px;" class="logo-image custom-pointer"
                    height="80" width="80" alt="Logo" [routerLink]="['/']">
            </div>
            <div class="col-6" *ngIf="configs.title === 'BestGifts'" style="margin-left:auto">
                <div class="inq-menu-st row justify-content-center">
                    <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation" class="navbar-toggler"><span
                                class="navbar-toggler-icon"></span><span>Menu</span></button>
                        <div id="navbarNav" class="collapse navbar-collapse">
                            <ul class="navbar-nav" style="padding:20px">
                                <li class="nav-item" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                        href="#/" style="width: max-content!important;">
                                        <p>HOME</p>
                                    </a></li>
                                <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">
                                        <p>SHOP</p>
                                    </a>
                                    <app-new-navbar [category]="fruits"></app-new-navbar>
                                </li>
                                <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>BESTGIFTS</p></a> -->
                                <!-- <app-new-navbar [category]="vegetables"></app-new-navbar> -->
                                <!-- </li> -->
                                <!-- <li class="header-cart account-popup1" id="shop">
                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                     <app-new-navbar></app-new-navbar>
                                    </li> -->
                                <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>VALENTINE SPECIAL</p></a> -->
                                <!-- <app-new-navbar [category]="others"></app-new-navbar> -->
                                <!-- </li> -->
                                <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                    <a href="#/about-us" class="nav-link menu-text">
                                        <p>ABOUT US</p>
                                    </a>
                                </li>
                                <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                    <a href="#/contact-us" class="nav-link menu-text">
                                        <p>CONTACT US</p>
                                    </a>
                                </li>

                                <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                        href="#/contact-us">CONTACT US</a></li> -->

                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <!-- <div class="col-3" style="margin-top: 2%"> -->
            <div class="col-3" style="margin-top: 2%">

                <ul class="d-flex">
                    <li class="d-flex header-cart account-popup1" style="margin-right: 18%;
                        margin-left: 20%;">
                        <a href="javascript:;" style="text-decoration: none !important;color:#3C57A6 !important;"
                            (click)="search()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </a>
                        <div class="min-cart-product-wrapper popup-content2 "
                            style="height:auto;overflow-y:visible!important;">
                            <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="dropdown-menu autocomplete-items"
                                                style="width: 100%! important;">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"
                                                        style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="text" placeholder="Search here."
                                            class="form-control search-form-input" formControlName="search_term"
                                            (keyup)="getPreList()">
                                        <div *ngIf="preList.length != 0">
                                            <!-- <div class="autocomplete-items2"> -->
                                            <div class="autocomplete-items2">
                                                <div *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
            </div>
        </div>
</div>
<div class="input-group-btn">
    <button class="btn btn-solid">
        <i class="fa fa-search" (click)="savePreList1()"></i>
    </button>
</div>
</div>
</form>

</div>


</div>
</li>

<!-- <li>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <div class="input-group-append">
                              <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </li> -->

<li class="d-flex" style="margin-right: 12%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
    <a href="javascript:;" style="text-decoration: none !important;color: #3C57A6 !important;">
        <span class="fa fa-user" style="font-size:25px">
        </span> Login
    </a>
</li>

<li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
    <div class="dropdown">
        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style="text-decoration: none !important;color: #3C57A6 !important;"><span class="lnr lnr-user">
            </span>
            {{ appService.userName }}
        </a>
        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #3C57A6 !important;">My
                Account</a>
            <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #3C57A6!important;">My Order</a>

            <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #3C57A6!important;">Logout</a>
        </div>
    </div>
</li>

<li class="header-cart account-popup d-flex" style="position: relative;">
    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #3C57A6!important;">
        <span class="fa fa-shopping-basket" style="font-size:25px">
            <span style="position: absolute;
                                    background: #FBCB0C;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 9px;
                                    top: -10px;
                                    right: 9%;">

                <span style="  color: #fff;
                                        position: absolute;
                                        left: 0.2rem;
                                        font-size: 14px;
                                        font-weight: bold;">{{appService.totalCartItems}}</span>
            </span>
        </span>
        <!-- <span class="menu-title">
                                    Cart
                                </span> -->
    </a>
    <app-mini-cart></app-mini-cart>
</li>
</ul>

</div>
</div>



</header>
</div>


<div class="container-fluid-header-sticky-header1 best_gifts" *ngIf="configs.title === 'BestGifts'">
    <header>
        <div>
            <ul style="display:flex">
                <li style="margin-top: -5px;"><span style="font-size: 7vw;cursor:pointer;color:#3C57A6"
                        (click)="openNav()">&#9776;</span>
                </li>
                <li style="margin-left: 6px;">
                    <div class="row">
                        <span>
                            <img [src]="logoImage" alt="Logo Image" class="logo-image custom-pointer gifts_img"
                                alt="Logo" [routerLink]="['/']">
                        </span>
                    </div>
                </li>
                <!-- 
<li class="d-flex header-cart account-popup1">
                        <a href="javascript:;" (click)="search()">
                            <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: #3C57A6 !important;    font-size: 5vw;
                            margin-left: 1vw;
                            margin-top: 2vw;"></i>
                        </a>
                        <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y:visible!important;">
                            <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                    <div *ngFor="let category of allCategories">
                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                    </div>                                      
                                                </div>
                                              </div>
                                            <input type="text" placeholder="Search here."
                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                            <div *ngIf="preList.length != 0">
                                                <div class="autocomplete-items2">
                                                    <div *ngFor="let preItem of preList">
                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-btn">
                                                <button class="btn btn-solid" >
                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                </button>
                                            </div>
                                        </div>
                                </form>
                                
                            </div>
                            
                    
                        </div>
                    </li> -->

                <li class="d-flex header-cart account-popup1">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                            margin-left: 1vw;
                            margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2"
                        style="height:auto;overflow-y: visible !important;">
                        <div class="row" style="margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>



                <li class="d-flex" style="    margin-left: 4vw;
                    margin-top: 1.5vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                    <a href="javascript:;" style="text-decoration: none !important;color: #3C57A6 !important">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle profile_name" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: #3C57A6 !important;"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4)}}...
                        </a>
                        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="logout-cutom dropdown-item" routerLink="my-account"
                                style="color: #3C57A6 !important;">My Account</a>
                            <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                style="color: #3C57A6 !important;">My Order</a>

                            <a class="logout-cutom dropdown-item" (click)="logOut()"
                                style="color: #3C57A6 !important;">Logout</a>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: #3C57A6 !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                            margin-top: 2vw;
                            margin-left: 4vw;">
                            <span style="position: absolute;
                                background: #FBCB0C;
                                width: 3vw;
                                height: 2.5vw;
                                border-radius: 1vw;">

                                <span style="color: #fff;
                                    position: absolute;
                                    left: 0.3rem;
                                    font-size: 2vw;
                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                        <!-- <span class="menu-title">
                                Cart
                            </span> -->
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closeNav1()">
                <p>&#8592;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myfruits1','FRUITS')">
                <p>SHOP &#8594;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myfruits1','FRUITS')">
                <p>BESTGIFTS &#8594;</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text"
                (click)="productsListkubendran2('myfruits1','FRUITS')">
                <p>VALETINE SPECIAL &#8594;</p>
            </a>


        </div>
        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;SHOP </span>

            <app-new-navbar [category]="fruitsres"></app-new-navbar>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;BESTGIFTS </span>

            <app-new-navbar [category]="vegetablesres"></app-new-navbar>

        </div>
        <div id="myothers1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;VALETINE SPECIAL </span>

            <app-new-navbar [category]="othersres"></app-new-navbar>

        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closeNav()">
                <p>&#8592;</p>
            </a>
            <a class="nav-link menu-text" ng-reflect-router-link="/" href="#/" (click)="closeNav()">
                <p>HOME</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')">
                <p>SHOP &#8594;</p>
            </a>

            <a href="javascript:void(0)" class="nav-link menu-text" (click)="bestgifts()">
                <p>BESTGIFTS</p>
            </a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="bestgifts()">
                <p>VALETINE SPECIAL</p>
            </a>


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;SHOP </span>

            <app-new-navbar [category]="fruitsres"></app-new-navbar>

        </div>
        <div id="myvegetables" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;BESTGIFTS </span>

            <app-new-navbar [category]="vegetablesres"></app-new-navbar>

        </div>
        <div id="myothers" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;VALETINE SPECIAL </span>

            <app-new-navbar [category]="othersres"></app-new-navbar>

        </div>
        <div id="searchnav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>
        </div>


    </header>
</div>
<button id="sendquery-model-vimpro" style="display: none" type="button" class="btn btn-primary" data-toggle="modal"
    data-target="#sendqueryModel-vimpro">
    Launch demo modal
</button>
<div class="modal fade" id="sendqueryModel-vimpro" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" style="
      max-width: 640px !important;
      position: relative !important;
      bottom: -30px !important;
      -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
      -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
      -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
      transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
    ">
        <div *ngIf="configs.title === 'Vimpro'" class="modal-content status-model-container">
            <div class="modal-body" style="border: none !important">
                <div class="model-content m-content">
                    <button #closeModal type="button" (click)="close_vimpro_model()" class="close" data-dismiss="modal"
                        aria-label="Close">
                        <span style="font-size: 1.6rem !important" aria-hidden="true">&times;</span>
                    </button>
                    <div class="title-container">
                        <div class="m-title">Query Form</div>

                    </div>
                    <form [formGroup]="sendquery_vimpro" class="gift-form" novalidate>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="customer_name" class="form-label">Name <span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="form-control" id="customer_name"
                                    [class.is-invalid]="f_vimpro.customer_name.invalid&&validateForm"
                                    formControlName="customer_name" required />
                                <div class="invalid-feedback" *ngIf="f_vimpro.customer_name.errors">
                                    <div *ngIf="f_vimpro.customer_name.errors.required">Name is required.</div>
                                    <div *ngIf="f_vimpro.customer_name.errors.maxlength">
                                        Name can't be more than 30 characters long.
                                    </div>
                                    <div *ngIf="f_vimpro.customer_name.errors.pattern">
                                        Enter a valid name
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="company_name" class="form-label">Company Name</label>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="form-control" id="company_name"
                                    formControlName="company_name" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="customer_email" class="form-label">Email<span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <input type="email" class="form-control" id="customer_email"
                                    [class.is-invalid]="f_vimpro.customer_email.invalid&&validateForm"
                                    formControlName="customer_email" maxlength="50" required />
                                <div class="invalid-feedback" *ngIf="f_vimpro.customer_email.errors">
                                    <div *ngIf="f_vimpro.customer_email.errors.required">Email is required.</div>
                                    <div *ngIf="f_vimpro.customer_email.errors.pattern">
                                        Please enter a valid email
                                    </div>
                                    <!-- <div *ngIf="f_vimpro.customer_email.errors.email || f_vimpro.customer_email.errors.pattern">
                    Email must be a valid email address
                  </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="customer_mobile" class="form-label">Mobile<span
                                        class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <input type="number" class="form-control" id="customer_mobile"
                                    [class.is-invalid]="f_vimpro.customer_mobile.invalid&&validateForm"
                                    formControlName="customer_mobile" maxlength="50" required />
                                <div class="invalid-feedback" *ngIf="f_vimpro.customer_mobile.errors">
                                    <div *ngIf="f_vimpro.customer_mobile.errors.required">Mobile is required.</div>
                                    <div
                                        *ngIf="f_vimpro.customer_mobile.errors.max||f_vimpro.customer_mobile.errors.min">
                                        Mobile should be 10 characters long.
                                    </div>
                                    <!-- <div *ngIf="f_vimpro.customer_mobile.errors.email || f_vimpro.customer_mobile.errors.pattern">
                    Mobile must be valid
                  </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label for="category" class="form-label">Query<span class="required">*</span></label>
                            </div>
                            <div class="col-md-7">
                                <textarea class="form-control" id="queries"
                                    [class.is-invalid]="f_vimpro.queries.invalid&&validateForm"
                                    formControlName="queries" required rows="5"></textarea>
                                <div class="invalid-feedback" *ngIf="f_vimpro.queries.errors">
                                    <div *ngIf="f_vimpro.queries.errors.required">
                                        Query is required.
                                    </div>
                                    <div *ngIf="f_vimpro.queries.errors.maxlength">
                                        Query can't be more than 600 characters long.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-bottom: 45px">
                            <button class="btn btn-primary" style="
                  border-radius: 5px !important;
                  font-size: 1rem;
                  padding: 5px 25px;
                  margin-top: 5px;
                  float: right;
                " (click)="saveForm_vimpro()">
                                SUBMIT
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </div> -->
<div *ngIf="configs.title=='LinenRaj' || configs.title=='Schift Cosmetics'">

    <!-- on click the bag -->
    <div class="modal fade" id="bagModal" tabindex="-1" aria-labelledby="bagModal" aria-hidden="true">
        <div class="modal-dialog product_bag">
            <div class="modal-content product_bag_slide">
                <div class="modal-header">
                    <h5 class="modal-title " id="bagModal">YOUR BAG</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closebeforecheck">
                        <span aria-hidden="true" style="font-size: 22px;" class="close_schift_bt">&times;</span>
                    </button>
                </div>
                <div class="modal-body scrollbar" *ngIf="appService.cartProducts.results.length >0">
                    <div *ngFor="let cart of appService.cartProducts.results; let i = index;" style="margin-bottom: 10%;display: flex;
            justify-content: center;">
                        <div style="display: flex;">
                            <div>
                                <!-- <img src="https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/7.jpg" style="width:50px;" > -->
                                <img [src]="cart.image_source" style="width:50px;">
                            </div>

                            <div style="position: relative;margin-left: 2%;margin-bottom: 10px;">
                                <div>
                                    <p style="font-size:13px !important;font-family:Trebuchet MS, sans-serif !;
                            overflow: hidden!important;
                            display: -webkit-box!important;
                            -webkit-line-clamp: 2!important;
                            -webkit-box-orient: vertical!important;
                            text-overflow: ellipsis!important;" class="name_schift">{{cart.product_name}}</p>
                                </div>

                                <p style="white-space: nowrap;margin-top: 10px;">{{cart.quantity * cart.cost | number :
                                    '1.2-2'}} </p>

                            </div>
                            <div>
                                <div class="scift_close_btn">
                                    <!-- <img src="https://cdn.shopify.com/s/files/1/0618/3183/9957/files/pen.png?v=1648532958" height="16"  style="box-shadow:none"> -->
                                    <img src="https://cdn.shopify.com/s/files/1/0618/3183/9957/files/cross.png?v=1646738334"
                                        style="height: 16px;box-shadow:none;position:relative;" class="close"
                                        *ngIf="appService.currentUrl !== 'checkout'" (click)="removeItem(i)">

                                </div>
                                <div class="mini_cart_actions_ln"
                                    style="min-width: 80px;max-width: 80px;border: 1px solid #ddd;position: relative;top: 39px;border-radius: 7px;"
                                    class="schift_right">
                                    <div class="" style="display: flex;justify-content: space-around;">
                                        <span (click)="decreaseItem(i)" style=" padding-bottom: 4px;">
                                            <i class="fa fa-minus"
                                                style="font-size: 10px; cursor: pointer;position: relative;"></i></span>
                                        <span class="inq-sp-2">{{cart.quantity}}</span>
                                        <span (click)="increaseItem(i)">
                                            <i class="fa fa-plus" style="font-size: 10px; cursor: pointer"></i></span>
                                    </div>

                                </div>
                            </div>
                            <div>
                            </div>
                        </div>

                    </div>



                </div>
                <div *ngIf="appService.cartProducts.results.length<=0">
                    <div>
                        <h4 class="pt-4 ng-star-inserted"
                            style="margin-top: 40px;display: flex; justify-content: center;">Your cart is empty.</h4>
                    </div>
                </div>
                <div></div>
                <div class="modal-footer" style="display: contents" *ngIf="appService.cartProducts.results.length>0">
                    <button style="margin-left: 10%;" type="button" class="btn btn-secondary" (click)="routeTo2()"
                        class="cart_bt">view bag</button>
                    <button style="margin-left: 10%;" type="button" class="btn btn-primary" (click)="routeTo1()"
                        class="ckt_bt">Proceed to checkout</button>
                </div>
            </div>
        </div>

    </div>
</div>


<button type="button" id="linenrajcart" class="btn btn-primary" data-toggle="modal" data-target="#addToCart_ln"
    style="display: none;">
    Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade addtocart" id="addToCart_ln" tabindex="-1" role="dialog" aria-labelledby="addToCart_ln"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered add_cart" role="document" id="cart_ln">
        <div class="title_message linen-pop111">
            <div class="modal-content add_cart" style="min-width: 120%;max-height: 32vh;">
                <div class="modal-header">
                    <h5 class="modal-title" id="addToCart_ln">Are you sure you want to Log Out?</h5>
                    <button id="closemodal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                </div>
                <div class="modal-body" style="margin-left: 5%;">
                    Click Ok to confirm.
                </div>
                <div class="modal-footer custom-model-footer">
                    <button style="    border-radius: 5px;
            border: 2px solid #dde7f1;
            background-color: #fff;
            color: #333;
            margin-right: 5px;
            margin-left: 5px;
            padding: 4px 15px;" class="button-light" data-dismiss="modal">Cancel</button>
                    <button id="cancel_btn" type="button" style="border-radius: 5px;
            border: none;
            background-color: #eb9a61;
            color: #fff;
            margin-right: 5px;
            margin-left: 5px;
            padding: 4px 15px;" (click)='logOut()'>Ok</button>
                </div>
            </div>
        </div>
        <div class="title_message1">
            <div class="modal-content add_cart cancelOrder" style="min-width: 120%;max-height: 24vh;">
                <div class="modal-header">
                    <h5 class="modal-title" id="addToCart_ln">Are you sure you want to Log Out?</h5>
                    <button id="closemodal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="margin-left: 5%;">
                    Click Ok to confirm.
                </div>
                <div class="modal-footer custom-model-footer">
                    <button style="    border-radius: 5px;
            border: 2px solid #dde7f1;
            background-color: #fff;
            color: #333;
            margin-right: 5px;
            margin-left: 5px;
            padding: 4px 15px;" class="button-light" data-dismiss="modal">Cancel</button>
                    <button id="cancel_btn" type="button" style="border-radius: 5px;
            border: none;
            background-color: #eb9a61;
            color: #fff;
            margin-right: 5px;
            margin-left: 5px;
            padding: 4px 15px;" (click)='logOut()'>Ok</button>
                </div>

            </div>
        </div>
    </div>
</div>




<!-- deera -->


<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'Deera'"
    style="background-color: #fefefe !important;">
    <header>
        <div class="row" style="display: inline-flex; justify-content: normal; width: 100%;">
            <div class="col-1" style="margin-left: 1%;">
                <!--                             
                <img src="../../assets/images/schift/logo.png" alt="Logo Image" style="padding:8px; object-fit: contain;"class="logo-image custom-pointer" height="100"  alt="Logo"
                [routerLink]="['/']">  -->
                <img [src]="logoImage" alt="Logo Image" class="logo-image custom-pointer" height="80" width="130"
                    alt="Logo" style="padding:8px; object-fit: contain;" [routerLink]="['/']">
            </div>
            <div class="col-4" style="margin-left: 24vw; margin-top: 17px;">
                <div class="inq-menu-st row justify-content-center">
                    <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation" class="navbar-toggler"><span
                                class="navbar-toggler-icon"></span><span>Menu</span></button>
                        <div id="navbarNav" class="collapse navbar-collapse" style="font-size: 16px;">
                            <ul class="navbar-nav" style="padding:15px;">
                                <!-- <li class="nav-item" id="home" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                            href="#/"><p style="font-weight: 580;color:#504d4d;font-size: 14px;">HOME</p></a></li> -->
                                <!-- <li  *ngFor="let category of allCategories"   class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important;">
                                                <a href="javascript:;" class="nav-link menu-text" (click)="productsListkubendran(category.name)" style="width: max-content;display: flex;"><p style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">{{category.name}}</p><svg  *ngIf="category.subcategories" xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;"><polyline points="6 9 12 15 18 9"></polyline></svg></a>
                                                
                                                <app-new-navbar [category]="category.name"></app-new-navbar>   
                                            </li>  -->
                                <li class="header-cart account-popup1" id="shop"
                                    style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                    <a href="javascript:;" class="nav-link menu-text" style="display: flex;"
                                        (click)="productsListkubendran('Baking')">
                                        <p
                                            style="font-size: 16px; font-weight: 580; color: #504d4d;white-space: nowrap;">
                                            BAKING</p>
                                    </a>

                                </li>
                                <li class="header-cart account-popup1"
                                    style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important;cursor: pointer;">
                                    <div class="dropdown" (mouseover)="toggleDropdown1(true)"
                                        (mouseout)="toggleDropdown1(false)">
                                        <!-- <button class="dropbtn">Hover me</button> -->
                                        <div (click)="productsListkubendran('Disposable Cutlery')">
                                            <a class="nav-link menu-text" style="display: flex;">
                                                <p
                                                    style="font-size: 16px; font-weight: 580; color: #504d4d;white-space: nowrap;">
                                                    DISPOSABLE CUTLERY

                                                </p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-down" style="color: black;">
                                                    <polyline points="6 9 12 15 18 9"></polyline>
                                                </svg>
                                            </a>
                                        </div>

                                        <div>
                                            <div class="dropdown-content" [class.show]="dropdownOpen1">
                                                <div class="dropdown-submenu">
                                                    <a style="font-size: 14px!important;"
                                                        *ngFor="let subcategory of subcategory1; index as j"
                                                        (mouseover)="toggleSubmenu2(true,subcategory.name)"
                                                        (mouseout)="toggleSubmenu3(false)"
                                                        (click)="appliedCategoryRedirection(subcategory.name)">{{subcategory.name}}
                                                        <!-- <a href="#">Color Ziplock Pouches</a> -->

                                                        <!-- <a href="#">Color Ziplock Pouches</a> -->
                                                        <div class="submenu-content"
                                                            [class.show]="subCategoryData===subcategory.name">
                                                            <div class="dropdown-submenu">
                                                                <a style="font-size: 14px!important;"
                                                                    *ngFor="let data of subcategory.sub_category; index as k"
                                                                    (mouseover)="appliedDeeraColor(data.color)"
                                                                    (mouseout)="toggleSubSubmenu1(false)">{{data.color}}
                                                                    <!-- <a href="#">Submenu Link 2</a> -->

                                                                    <!-- <a href="#">Green</a> -->
                                                                    <div class="subsubmenu-content"
                                                                        [class.show]="active2===data.color">
                                                                        <a style="font-size: 14px!important;"
                                                                            *ngFor="let data1 of data.dimension; index as k"
                                                                            (click)="appliedDeeraDimension(data1)">{{data1}}</a>
                                                                        <!-- <a href="#">120*120</a> -->
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </li>
                                <li class="header-cart account-popup1"
                                    style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important;cursor: pointer;">
                                    <div class="dropdown" (mouseover)="toggleDropdown(true)"
                                        (mouseout)="toggleDropdown(false)">
                                        <!-- <button class="dropbtn">Hover me</button> -->
                                        <div (click)="productsListkubendran('Packaging')">
                                            <a class="nav-link menu-text" style="display: flex;">
                                                <p
                                                    style="font-size: 16px; font-weight: 580; color: #504d4d;white-space: nowrap;">
                                                    PACKAGING</p><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-down" style="color: black;">
                                                    <polyline points="6 9 12 15 18 9"></polyline>
                                                </svg>
                                            </a>
                                        </div>
                                        <div class="dropdown-content" [class.show]="dropdownOpen">
                                            <div class="dropdown-submenu">
                                                <a style="font-size: 14px!important;"
                                                    *ngFor="let subcategory of subcategory2; index as j"
                                                    (mouseover)="toggleSubmenu(true,subcategory.name)"
                                                    (mouseout)="toggleSubmenu1(false)"
                                                    (click)="appliedCategoryRedirection(subcategory.name)">{{subcategory.name}}
                                                    <!-- <a href="#">Color Ziplock Pouches</a> -->
                                                    <!-- <a href="#">Color Ziplock Pouches</a> -->
                                                    <div class="submenu-content"
                                                        [class.show]="subCategoryData===subcategory.name && activeSubCategory===subcategory.name">
                                                        <div class="dropdown-submenu">
                                                            <a style="font-size: 14px!important;"
                                                                *ngFor="let data of subcategory.sub_category; index as k"
                                                                (mouseover)="appliedDeeraColor(data.color)"
                                                                (mouseout)="toggleSubSubmenu(false)"
                                                                (click)="deeraColorRedirection()">{{data.color}}
                                                                <!-- <a href="#">Submenu Link 2</a> -->

                                                                <!-- <a href="#">Green</a> -->
                                                                <div class="subsubmenu-content"
                                                                    [class.show]="active2===data.color">
                                                                    <a style="font-size: 14px!important;"
                                                                        *ngFor="let data1 of data.dimension; index as k"
                                                                        (click)="appliedDeeraDimension(data1)"
                                                                        (mouseout)="mouseOutDimension()">{{data1}}</a>
                                                                    <!-- <a href="#">120*120</a> -->
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>



                                <li class="header-cart account-popup1" id="shop"
                                    style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                    <a href="javascript:;" class="nav-link menu-text" style="display: flex;"
                                        href="/sizechart">
                                        <p
                                            style="font-size: 16px; font-weight: 580; color: #504d4d;white-space: nowrap;">
                                            Size CHART</p>
                                    </a>

                                </li>
                                <li class="header-cart account-popup1" id="shop"
                                    style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                    <a href="javascript:;" class="nav-link menu-text" style="display: flex;"
                                        href="/contact-us">
                                        <p
                                            style="font-size: 16px; font-weight: 580; color: #504d4d;white-space: nowrap;">
                                            CONTACT US</p>
                                    </a>

                                </li>
                                <!-- <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                                    <a href="javascript:;" class="nav-link menu-text"href="#/about-us" ><p style="font-weight: 580;color:#504d4d;font-size: 14px;white-space: nowrap;">ABOUT US</p></a>
                                                    </li>
                                            <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'Roboto'!important;margin-left: 0.1rem !important">
                                                <a href="#/contact-us" class="nav-link menu-text"><p style="font-weight: 580;color:#504d4d;white-space: nowrap;font-size: 14px;">CONTACT US</p></a>
                                                </li>      -->
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="col-3 header-special"
                style="margin-left: 16%;margin-top: 2.7%;margin-right:inherit;max-width: 12%;">

                <ul class="d-flex">
                    <li class="d-flex header-cart account-popup1" style="margin-right: 20%;
                        margin-left: 20%;">
                        <a href="javascript:;" style="text-decoration: none !important;color: black !important;"
                            (click)="search()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </a>
                        <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y: visible !important;min-width: 30%;
                            width: 181%;">
                            <div class="row" style="margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="dropdown-menu autocomplete-items"
                                                style="width: 100%! important;">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"
                                                        style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="text" placeholder="Search here."
                                            class="form-control search-form-input" formControlName="search_term"
                                            (keyup)="getPreList()">
                                        <div *ngIf="preList.length != 0">
                                            <div class="autocomplete-items2 custom-scrollbar">
                                                <div *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
            </div>
        </div>
</div>
<!-- <div class="input-group-btn">
                                                    <button class="btn btn-solid" >
                                                        <i class="fa fa-search" (click)="savePreList1()"></i>
                                                    </button>
                                                </div> -->
</div>
</form>

</div>


</div>
</li>

<li class="d-flex" style="margin-right: 25%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
    <a href="javascript:;"
        style="text-shadow: none;font-family: 'Roboto'!important;color: #1d1b1a!important;text-align: center; font-size: 13px;font-weight: bold;">
        <span class="fa fa-user" style="font-size:20px;margin-right: 6px;color: #0c377b;">
        </span>
    </a>
</li>

<li class="d-flex" style="margin-right:12%;" *ngIf="appService.userLoggedIn">
    <div class="dropdown">
        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style="text-decoration: none !important;color: #1d1b1a !important;font-family: 'Roboto'!important;"><span
                class="lnr lnr-user">
                {{ appService.userName.substring(0,6)}}...
            </span>
        </a>
        <div id="dropdown" class="dropdown-menu" style="background-color: white!important;margin-left: -50%!important;"
            aria-labelledby="dropdownMenuButton">
            <a class="logout-cutom dropdown-item" routerLink="my-account"
                style="text-shadow: none;font-family: 'Roboto'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;    text-align: left;">My
                Account</a>
            <a class="logout-cutom dropdown-item" routerLink="my-orders"
                style="text-shadow: none;font-family: 'Roboto'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;    text-align: left;">My
                Order</a>

            <a class="logout-cutom dropdown-item" (click)="logOut()"
                style="text-shadow: none;font-family: 'Roboto'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;text-align: left;cursor: pointer;">Logout</a>
        </div>
    </div>
</li>
<!-- <li class="header-cart account-popup d-flex" style="position: relative;">  
                            <a id="openbagschift" href="javascript:;"  data-toggle="modal" data-target="#bagModal" style="text-decoration: none !important;color: #0a0a0a!important;margin-left: 10px;">
                                <span class="fa fa-shopping-cart" style="font-size:20px;color: #0c377b;">
                                    <span style="position: absolute;
                                    background: #0c377b;
                                    width: 16px;
                                    height: 18px;
                                    border-radius: 9px;
                                    top: -10px;
                                    left: 67%;" >
                                   
                                        <span style="  color: #fff;
                                        position: relative;
                                        left: 0.2rem;
                                        font-size: 13px;
                                        font-weight: bold;">{{appService.totalCartItems}}</span>
                                    </span>
                                </span>
                            </a>
                        </li> -->
<li class="header-cart account-popup d-flex" style="position: relative;">
    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #0c377b !important;">
        <span class="fa fa-shopping-cart" style="font-size:20px">
            <span style="position: absolute;
                                    background: #0c377b;
                                    width: 16px;
                                    height: 18px;
                                    border-radius: 9px;
                                    top: -10px;
                                    left: 67%;">

                <span style="  color: #fff;
                                        position: relative;
                                        left: 0.2rem;
                                        font-size: 13px;
                                        font-weight: bold;">{{appService.totalCartItems}}</span>
            </span>
        </span>
    </a>
    <app-mini-cart></app-mini-cart>
</li>
</ul>

</div>
</div>



</header>
</div>



<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;
                            padding: 2vw;" *ngIf="configs.title === 'Deera'">
    <header>
        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black"
                        (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <img src="../../../../../assets/images/deera/logo.png" alt="Deera shop" style="width: 34vw;
                                        height: 13vw;
                                        ;margin-left: 19vw;margin-right:6vw;object-fit: contain;cursor: pointer;"
                        class="logo-imagekub custom-pointer" [routerLink]="['/']">
                </li>
                <li class="d-flex header-cart account-popup1">
                    <a href="javascript:;" (click)="search()">
                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                                        margin-left: 1vw;
                                                        margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                    </a>
                    <div class="min-cart-product-wrapper popup-content2"
                        style="height:auto;overflow-y: visible !important;">
                        <div class="row" style="margin: 5px;">
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                            <div *ngFor="let category of allCategories">
                                                <a class="dropdown-item" (click)="navigateToList(category)"
                                                    style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li class="d-flex" style="    margin-left: 2vw;
                                                margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn"
                    (click)="logIn()">
                    <a href="javascript:;" style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-user" style="font-size:6vw">
                        </span>
                    </a>
                </li>

                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                    <div class="dropdown">
                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:1vw"><span
                                class="lnr lnr-user">
                            </span>
                            {{ appService.userName.substring(0,4) }}...
                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                        </a>
                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;"
                            aria-labelledby="dropdownMenuButton">
                            <div id="dropdown" class="dropdown-menu show"
                                style=" transform: translate3d(-47px, -20px, 0px) !important;margin-left: -20%!important;">
                                <a class="logout-cutom dropdown-item" routerLink="my-account"
                                    style="color: black !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders"
                                    style="color: black !important;">My Order</a>

                                <a class="logout-cutom dropdown-item" (click)="logOut()"
                                    style="color: black !important;">Logout</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="header-cart account-popup d-flex" style="position: relative;">
                    <a href="javascript:;" routerLink="/cart"
                        style="text-decoration: none !important;color: black !important;">
                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                                        margin-top: 2vw;
                                                        margin-left: 1vw;">
                            <span style="position: absolute;
                                                            background: #103672;
                                                            width: 5vw;
                                                            height: 4.5vw;
                                                            top: 0vw;
                                                            border-radius: 2vw;">

                                <span
                                    style="color: #fff;
                                                                position: absolute;
                                                                margin-top: 2px;
                                                                left: 0.3rem;
                                                                font-size: 3vw;
                                                                font-weight: bold;">{{appService.totalCartItems}}</span>
                            </span>
                        </span>
                    </a>
                    <app-mini-cart></app-mini-cart>
                </li>
            </ul>

        </div>

        <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>

            <app-new-navbarkub [category]="fruitsres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;SHOP </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>

            <app-new-navbarkub [category]="vegetablesres"></app-new-navbarkub>

        </div>
        <div id="searchnav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text"
                (click)="closesearchnavkub()">
                <p>&#8592;</p>
            </a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                <input type="text" placeholder="Search here." class="form-control search-form-input"
                    formControlName="search_term" (keyup)="getPreList()">
                <ul *ngFor="let preItem of preList">
                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">
                        {{preItem.name}}</li>
                </ul>
            </form>


        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text"
                (click)="closeNavkub1()">
                <p>&#8592;</p>
            </a>
            <a class="nav-link menu-text" (click)="home()">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    HOME</p>
            </a>

            <!-- <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">PRODUCTS &#8594;</p></a> -->
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Baking')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    BAKING</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Disposable Cutlery')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    DISPOSABLE CUTLERY</p>
            </a>
            <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Packaging')"
                style="width: max-content;">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    PACKAGING</p>
            </a>
            <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsListLush('Exotic Plants')" style="width: max-content;"><p style="text-shadow: none;font-size: 14px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">CONTACT US</p></a> -->
            <a class="nav-link menu-text" style="width: max-content;" href="/sizechart">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    SIZE CHART</p>
            </a>

            <a style="color: black;" class="nav-link menu-text" href="/contact-us">
                <p
                    style="text-shadow: none;font-size: 16px;font-family: 'Roboto'!important; font-weight: 580;color:#504d4d">
                    CONTACT US </p>
            </a>


        </div>
        <div id="myfruits" class="sidenav" style="background-color:#fff">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>

            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">SHOP </span>

            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->

        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
        </div>
        <div id="myvegetables" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
        </div>
    </header>
</div>